
/* eslint eqeqeq: 0 */

export function uploadImage(data, data_user, api) {
    const url = process.env.NODE_ENV === "production" ? data_user.current_server.trim().replace("/api", "/images") : "http://192.168.1.149/api/images/";
    let respuesta;

    return new Promise(async (resolve, reject) => {

        if (data !== null) {
            respuesta = await api.fetchFormData(data, "upload-image");

            if (respuesta.response == 1) {
                let _new_file = Object.assign(data.get("image"), {
                    url: url + "/" + data.get("destino") + "/" + respuesta.img,
                    preview: url + "/" + data.get("destino") + "/" + respuesta.img,
                    nameinserver: respuesta.img
                });

                resolve(_new_file);
            } else {
                reject(respuesta.msg);
            }
        } else {
            reject("No hay datos para subir!");
        }
    });
}

export { };