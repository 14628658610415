import * as React from "react";
import { RequirePermissions } from "shared/AlertMessages";
import AutorenewIcon from "mdi-react/AutorenewIcon";
import EntregaCaja from "./components/EntregaCaja";
import Movimientos from "./components/Movimientos";
import Pendientes from "./components/Pendientes";
import { TitleSticky } from "shared/Components";
import AbonoCaja from "./components/AbonoCaja";
import Historico from "./components/Historico";
import withRouter from "services/withRouter";
import { Container, Row } from "reactstrap";
import funciones from "services/funciones";
import { Alert } from "shared/Alert";
import { Api } from "services/api";
import * as Iconos from "images";
import { useMedia } from "hooks";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const CajaMensajeros = ({ navigate }) => {
    const { setTitulo, data_user, setDataUser } = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [valores, setValores] = React.useState({ transferencias: 0, efectivo: 0 });
    const [showLoading, setShowLoading] = React.useState(true);
    const [showModal, setShowModal] = React.useState(false);
    const [modalView, setModalView] = React.useState(null);


    const handleModal = (e, _option) => {
        e.preventDefault();
        e.stopPropagation();
        let _window;

        switch (_option) {
            case "entrega":
                _window = <EntregaCaja
                    parent={{ ...e.target.getBoundingClientRect().toJSON(), width: 230 }}
                    close={setShowModal}
                    reload={getInfo} />;
                break;
            case "abono":
                _window = <AbonoCaja
                    parent={{ ...e.target.getBoundingClientRect().toJSON(), width: 230 }}
                    close={setShowModal}
                    reload={getInfo} />;
                break;
            default:
                _window = null;
                break;
        }

        setModalView(_window);
        setShowModal(true);
    }

    const getInfo = async () => {
        setShowLoading(true);
        /**
         * caja
         *
         * 1 caja mensajeros
         * 2 caja clientes
         * 3 caja bts
        */
        let _data = await api.current.fetchJson({
            url: "info-caja",
            data: {
                task: "registro_msg_saldo",
                caja: 1
            }
        });

        if (_data.response == 1) {
            let _vals = _data.data[0];
            let _list = { transferencias: 0, efectivo: 0 };

            _list.efectivo = _vals.efectivo ?? 0;
            _list.transferencias = _vals.transferencias ?? 0;

            setValores(_list);
            setShowLoading(false);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }
    }

    React.useEffect(() => {
        setTitulo("Caja de mensajeros");
        document.body.style.overflow = "auto";
        if (funciones.validaPermisos("caja_msj") && ![6, 7].includes(parseInt(data_user.nivel))) {
            getInfo();
        }
    }, []);

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("caja_msj") && ![6, 7].includes(parseInt(data_user.nivel))}>

            <TitleSticky className="_in_page">Caja de mensajeros</TitleSticky>

            <Container className="dashboard _caja">
                <Row className="_en_caja">

                    <div className="panel__btns _en_caja">

                        <button
                            className="panel__btn"
                            type="button"
                            onClick={getInfo}>
                            <AutorenewIcon className={showLoading ? "on_refresh" : ""} />
                        </button>

                    </div>

                    <h4 className="_precio"><span>Disponible</span><br />
                        <span>Efectivo</span> ${funciones.formatPrice(valores.efectivo).format}
                        <br />
                        <span>Transferencias</span> ${funciones.formatPrice(valores.transferencias).format}
                    </h4>

                    <div className="cont_btns_caja">
                        <button className="cash-button" onClick={e => handleModal(e, "abono")}><Iconos.CashRefund />Abono a caja principal</button>
                        <button className="cash-button" onClick={e => handleModal(e, "entrega")}><Iconos.CashClose />Entregar a caja principal</button>
                    </div>
                </Row>

                <Row>

                    <Pendientes
                        parent={{
                            inload: showLoading,
                            update: () => getInfo()
                        }}
                        tipo_caja="2" />

                    <Movimientos
                        parent={{
                            inload: showLoading,
                            update: () => getInfo()
                        }}
                        tipo_caja="1" />

                    <Historico
                        parent={{
                            inload: showLoading,
                            update: () => getInfo()
                        }}
                        tipo_caja="1" />

                </Row>

                {showModal && modalView}

            </Container>
        </RequirePermissions>
    )
}

export default withRouter(CajaMensajeros);
