import * as React from "react";
import { RequirePermissions } from "shared/AlertMessages";
import AutorenewIcon from "mdi-react/AutorenewIcon";
import Movimientos from "./components/Movimientos";
import EntregaCaja from "./components/EntregaCaja";
import Pendientes from "./components/Pendientes";
import { TitleSticky } from "shared/Components";
import Historico from "./components/Historico";
import { useNavigate } from "react-router-dom";
import AbonoCaja from "./components/AbonoCaja";
import { Container, Row } from "reactstrap";
import Entrada from "./components/Entrada";
import funciones from "services/funciones";
import Abono from "./components/Abono";
import { Alert } from "shared/Alert";
import { Api } from "services/api";
import * as Iconos from "images";
import { useMedia } from "hooks";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-extend-native: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const CajaBodega = () => {
    const navigate = useNavigate();
    const { setTitulo, setDataUser } = useMedia();
    const [valores, setValores] = React.useState({ transferencias: 0, efectivo: 0 });
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [showLoading, setShowLoading] = React.useState(true);
    const [showModal, setShowModal] = React.useState(false);
    const [modalView, setModalView] = React.useState(null);


    const handleModal = (e, _option) => {
        e.preventDefault();
        e.stopPropagation();
        let _window;

        switch (_option) {
            case "entrega_caja":
                _window = <EntregaCaja
                    parent={{ ...e.target.getBoundingClientRect().toJSON(), width: 230 }}
                    close={setShowModal}
                    reload={getInfo} />;
                break;
            case "abono_caja":
                _window = <AbonoCaja
                    parent={{ ...e.target.getBoundingClientRect().toJSON(), width: 230 }}
                    close={setShowModal}
                    reload={getInfo} />;
                break;
            case "entrada_cli":
                _window = <Entrada
                    parent={{ ...e.target.getBoundingClientRect().toJSON(), width: 250 }}
                    close={setShowModal}
                    reload={getInfo} />;
                break;
            case "abono_msj":
                _window = <Abono
                    parent={{ ...e.target.getBoundingClientRect().toJSON(), width: 230 }}
                    close={setShowModal}
                    reload={getInfo} />;
                break;
            default:
                _window = null;
                break;
        }

        setModalView(_window);
        setShowModal(true);
    }

    const getInfo = async () => {
        setShowLoading(true);
        /**
         * caja
         *
         * 1 caja mensajeros
         * 2 caja clientes
         * 3 caja bts
        */
        let _data = await api.current.fetchJson({
            url: "info-caja",
            data: {
                task: "saldo_caja_bdg"
            }
        });

        if (_data.response == 1) {
            let _list = { transferencias: 0, efectivo: 0 };

            _data.data.map(item => {
                if (item.metodo == 1) {
                    _list.efectivo = item.monto;
                }
                if (item.metodo == 2) {
                    _list.transferencias = item.monto;
                }
            });

            setValores(_list);
            setShowLoading(false);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }
    }

    React.useEffect(() => {
        setTitulo("Caja de bodega");
        document.body.style.overflow = "auto";
        if (funciones.validaPermisos("caja_bodega")) {
            getInfo();
        }
    }, []);

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("caja_bodega")}>

            <TitleSticky className="_in_page">Caja de bodega</TitleSticky>

            <Container className="dashboard _caja">
                <Row className="_en_caja">

                    <div className="panel__btns _en_caja">

                        <button
                            className="panel__btn"
                            type="button"
                            onClick={getInfo}>
                            <AutorenewIcon className={showLoading ? "on_refresh" : ""} />
                        </button>

                    </div>

                    <h4 className="_precio"><span>Disponible</span><br />
                        <span>Efectivo</span> ${funciones.formatPrice(valores.efectivo).format}
                        <br />
                        <span>Transferencias</span> ${funciones.formatPrice(valores.transferencias).format}
                    </h4>

                    <div className="cont_btns_caja">
                        <button className="cash-button" onClick={e => handleModal(e, "entrega_caja")}><Iconos.CashClose />Entregar a caja principal</button>
                        <button className="cash-button" onClick={e => handleModal(e, "abono_caja")}><Iconos.CashRefund />Abono a caja principal</button>
                        <button className="cash-button" onClick={e => handleModal(e, "abono_msj")}><Iconos.CashOk />Abono de mensajero</button>
                        <button className="cash-button" onClick={e => handleModal(e, "entrada_cli")}><Iconos.CashIn />Entrada de cliente</button>
                    </div>
                </Row>

                <Row>
                    <Pendientes
                        parent={{
                            inload: showLoading,
                            update: () => getInfo()
                        }} />

                    <Movimientos
                        parent={{
                            inload: showLoading,
                            update: () => getInfo()
                        }} />

                    <Historico
                        parent={{
                            inload: showLoading,
                            update: () => getInfo()
                        }} />
                </Row>

                {showModal && modalView}

            </Container>
        </RequirePermissions>
    )
}

export default CajaBodega;
