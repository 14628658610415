import * as React from "react";
import Information from "mdi-react/InformationOutlineIcon";
import { FileUploadImg } from "shared/FileUpload";
import { useNavigate } from "react-router-dom";
import { MovePopUp } from "shared/MovePopUp";
import funciones from "services/funciones";
import { Alert } from "shared/Alert";
import { Api } from "services/api";
import { useMedia } from "hooks";
import * as Icon from "images";
import log from "services/log";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const ReturnPack = ({ data, close, reload }) => {
    const navigate = useNavigate();
    const {setDataUser} = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [objImg, setObjImg] = React.useState(process.env.NODE_ENV === 'production' ? [] : [
        {
            url: process.env.REACT_APP_IMAGE_EVIDENCE,
            name: "pruebas.jpeg",
            realname: "pruebas.jpeg"
        }]);
    const [showLoading, setShowLoading] = React.useState(false);
    const [notes, setNotes] = React.useState("");
    const [error, setError] = React.useState("");
    const estadoSpan = React.useRef();


    const handleError = (_msg) => {
        setError(_msg);

        if (_msg.length > 0) {
            try {
                navigator.vibrate(250);
            } catch (e) {
                log.e('No se puede ejecutar el vibrador:', e);
            }
        }
    }

    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close();
    }

    const validateFields = (e) => {
        e.preventDefault();

        handleError("");

        if (notes.length < 1) {
            handleError(`¡Debes agregar un comentario!`);
            return;
        }

        if (objImg.length < 1) {
            handleError("¡Debes tomar una foto!");
            return;
        }

        devolverPack();
    }

    const devolverPack = async () => {
        setShowLoading(true);
        let attachment = objImg.map(i => i.url);
        let _data = await api.current.fetchJson({
            url: "update-package-return",
            data: {
                id: data.id,
                note: notes,
                file: attachment.toString()
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => {
                reload();
                document.body.style.overflow = "auto";
            });
            close();
        } else if (_data.response === "-3") {
            Alert(_data.msg, "warning", () => {
                reload();
                document.body.style.overflow = "auto";
            });
            close();
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        let _element = estadoSpan.current.firstChild;
        _element.innerText = _element.textContent.replaceAll("+", "+ \n");
    }, [data]);

    return (
        <MovePopUp
            parent={data.parent}
            close={handleClose}
            className="win_modal _overflow _delivery"
            title="Devolver a cliente">

            <h5>Paquete: {data.codigo}</h5>
            <h5 ref={estadoSpan}>{funciones.getStateSpan(data)}</h5>

            <div className="_in_column">

                <div>
                    <p className="__titulo">Comentario</p>
                    <textarea name="comment" rows="4"
                        value={notes} onChange={({ target: { value } }) => setNotes(value)} />
                </div>

            </div>

            {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}

            <FileUploadImg
                img={objImg}
                codigo={data.codigo}
                loading={showLoading}
                onStart={() => setShowLoading(true)}
                onUpload={obj => {
                    let _new_imgs = objImg;
                    _new_imgs.push(obj);

                    handleError("");
                    setObjImg(_new_imgs);
                    setShowLoading(false);
                }}
                onError={error => {
                    handleError(error);
                    setShowLoading(false);
                }} />

            {showLoading ?
                <button className="btn btn-common width-static"><Icon.Loading /></button>
                :
                <button className="btn btn-common width-static" onClick={validateFields}>Devolver</button>
            }

        </MovePopUp>
    );
}

export default ReturnPack;