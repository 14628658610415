import * as React from 'react';
import LogInForm from './components/LogInForm';


const LogIn = (params) => {
    const { changeTheme } = params;


    const clearSesion = () => {
        localStorage.removeItem('usuario');
    }

    React.useEffect(() => {
        document.title = "Iniciar sesión";
        let theme = localStorage.getItem("theme");
        changeTheme(theme ? theme : "theme-light");
        clearSesion();

        let _footer = document.getElementsByClassName("footer")[0];
        _footer.style.display = "none";
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="account">
            <div className="account_background">
                <div className="account__wrapper">

                    <div className="account__card">
                        <LogInForm />
                        <p>© 2021 - {new Date().getFullYear()} <b>Best Transport Service</b> todos los derechos reservados.
                            <br />
                            <i style={{ fontSize: "0.7rem", opacity: 0.5 }}>Desarrollado por <strong>Sarguero</strong><br />{process.env.REACT_APP_BUILD_VERSION}</i>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LogIn;