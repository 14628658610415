import * as React from "react";
import { Alert, alerta, alertaRemove, alertDown } from "shared/Alert";
import PlusCircleOutlineIcon from "mdi-react/PlusCircleOutlineIcon";
import { RequirePermissions } from "shared/AlertMessages";
import { TitleSticky } from "shared/Components";
import { useNavigate } from "react-router-dom";
import funciones from "services/funciones";
import NewContainer from "./NewContainer";
import Loading from "shared/Loading";
import { Field } from "shared/Input";
import { Api } from "services/api";
import { useMedia } from "hooks";


/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const DEFAULT_DOMICILIO_CLIENTE = "8000";
const DEFAULT_DOMICILIO_MENSAJERO = "5000";

const NewReception = () => {
    const navigate = useNavigate();
    const [origin] = React.useState(1);
    const { data_user, setTitulo, setDataUser, clientsList, setClientsList } = useMedia();
    const [domicilioMensajero, setDomicilioMensajero] = React.useState(DEFAULT_DOMICILIO_MENSAJERO);
    const [domicilioCliente, setDomicilioCliente] = React.useState(DEFAULT_DOMICILIO_CLIENTE);
    const [addressConsignee, setAddressConsignee] = React.useState("");
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [showLoading, setShowLoading] = React.useState(true);
    const [allPaquages, setAllPaquages] = React.useState([]);
    const [consigneeId, setConsigneeId] = React.useState("");
    const [consignee, setConsignee] = React.useState("");
    const [fechaIngreso] = React.useState(new Date());
    const [packages, setPackages] = React.useState({});
    const [notes, setNotes] = React.useState("");
    const ref_form = React.useRef();


    const getAllList = async () => {
        setShowLoading(true);
        let _data = await api.current.fetchJson({
            url: "get-users",
            data: {
                roll: "clients",
                list_ids: clientsList.map(item => item.id_user).join(",")
            }
        });
        let _users = clientsList.filter(item => item.id_user != 0);

        if (_data.response == 1) {
            _data.data.forEach(item => {
                item.tarifas_sistema = JSON.parse(item.tarifas_sistema);
                item.tarifas_cliente = JSON.parse(item.tarifas_cliente);
                item.tarifas = item.tarifas_sistema.map(t => {
                    let _city = item.tarifas_cliente.find(c => c.id == t.id);

                    if (_city?.id === t.id) {
                        return _city;
                    } else {
                        return t;
                    }
                });
                item.nivel > "2" && _users.push(item);
                if (item.tarifas_sistema[0]?.id === item.tarifas_cliente[0]?.id) {
                    console.log("id:", item.id_user);
                    console.log("tarifas_sistema", item.tarifas_sistema[0]);
                    console.log("tarifas_cliente", item.tarifas_cliente[0]);
                    console.log("personalizado", item.tarifas[0]);
                }
            });

            setClientsList(_users);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
        return _users;
    }

    const format = (_num) => {
        return _num < 10 ? "0" + _num : _num;
    }

    const saveOrder = async () => {
        setShowLoading(true);
        let _info = {
            estado: 4,
            date_ingress: fechaIngreso.getFullYear() + "-" + format(fechaIngreso.getMonth() + 1) + "-" + format(fechaIngreso.getDate()),
            origin: origin,
            consignee: consigneeId,
            notes: notes,
            packages: packages
        };

        let _data = await api.current.saveReceptionOrder(_info);

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => navigate(-1));
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validateFields = (e) => {
        e.preventDefault();
        let _is_empty = false;
        let _message = "";

        alertaRemove(ref_form.current.origen);
        alertaRemove(ref_form.current.consignee);
        alertaRemove(ref_form.current.other);

        if (origin == 0) {
            alerta(ref_form.current.origen, "Debes ingresar el origen del paquete!");
            ref_form.current.origen.focus();
            window.scrollTo({
                top: 60,
                behavior: 'smooth'
            });
            return;
        }

        if (consignee.length < 1) {
            alerta(ref_form.current.consignee, "Debes ingresar un Cliente bts!");
            ref_form.current.consignee.focus();
            window.scrollTo({
                top: 190,
                behavior: 'smooth'
            });
            return;
        }

        if (consigneeId.length < 1) {
            alerta(ref_form.current.consignee, "Este usuario no se encuentra registrado! \nAgregalo desde el icono ( + )");
            ref_form.current.consignee.focus();
            window.scrollTo({
                top: 250,
                behavior: 'smooth'
            });
            return;
        }

        if (typeof packages !== "object" || Object.entries(packages).length < 1) {
            alertDown(ref_form.current.other, "Debes agregar al menos un paquete!");
            return;
        }

        for (let i in packages) {

            if (packages[i].codigo.length < 1) {
                _is_empty = true;
                _message = `Debes ingresar un "código" para el paquete ${i}`;
                break;
            }

            let codigo = Object.entries(packages).filter(item => item[1].codigo === packages[i].codigo);

            if (codigo.length > 1) {
                _is_empty = true;
                _message = `¡El código "${codigo[0][1].codigo}" en el paquete ${i} se encuentra repetido en esta orden!`;
                break;
            }

            if (packages[i].tiene_dev) {
                if (packages[i].devolucion.length < 1) {
                    _is_empty = true;
                    _message = `Debes indicar el tipo de devolución en el paquete ${i} "código ${packages[i].codigo}"`;
                    break;
                }
            }

            if (!packages[i].tiene_dev) {
                packages[i].devolucion = "";
            }

            if (packages[i]["valorp"].length < 1 || parseInt(packages[i]["valorp"]) < 0) {
                _is_empty = true;
                _message = `Debes ingresar el "Valor del producto" para el paquete ${i} "código ${packages[i].codigo}"`;
                break;
            }

            if (packages[i]["valord"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar el "Valor del domicilio" para el paquete ${i} "código ${packages[i].codigo}"`;
                break;
            }

            if (packages[i]["valorm"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar el "Valor del mensajero" para el paquete ${i} "código ${packages[i].codigo}"`;
                break;
            }

            if (packages[i]["attachments"].length < 1) {
                _is_empty = true;
                _message = `El paquete ${i} "código ${packages[i].codigo}" no tiene imagen`;
                break;
            }
        }

        if (_is_empty) {
            Alert(_message, "warning");
            return;
        }

        saveOrder();
    }

    const addPackage = (e) => {
        e.preventDefault();

        if (consigneeId !== "") {
            let _index = allPaquages.length + 1;
            let _pack = {
                "index": _index,
                "id": 0,
                "codigo": "",
                "valorp": "0",
                "valord": domicilioCliente,
                "valorm": domicilioMensajero,
                "devolucion": "",
                "tiene_dev": false,
                "descripcion": "",
                "attachments": process.env.REACT_APP_IMAGE_PACK,
                "estado": 4,
            };

            setPackages({ ...packages, [_index]: _pack });

            alertaRemove(ref_form.current.other);

            setAllPaquages([...allPaquages, <Container key={_index} pack={_pack} />]);
        }
    }

    const removePackage = (_index) => {
        let _pack_list = [];

        setPackages(p => {
            let _new_list;

            for (let i in p) {
                if (p[i].index !== _index) {
                    _new_list = { ..._new_list, [i]: p[i] };
                    _pack_list.push(<Container key={p[i].index} pack={p[i]} />);
                }
            }

            return _new_list;
        });

        setAllPaquages(_pack_list);
    }

    const Container = ({ pack }) => {

        return (
            <NewContainer
                item={pack}
                list={clientsList}
                remove={removePackage}
                update={item => setPackages(p => ({ ...p, [pack.index]: item }))} />
        );
    }

    const addConsignee = async (val) => {
        setConsignee(val);
        alertaRemove(ref_form.current.consignee);

        let lista = await getAllList();

        let item = lista.filter(({ nombre, apellido }) => nombre + " " + apellido === val);
        item = item[0];


        if (typeof item === "object") {
            let _info = JSON.parse(item.info);
            console.log(_info);
            setAddressConsignee(item.address);
            setConsigneeId(item.id_user);
            setDomicilioCliente(_info?.company?.domicilio?.cliente ?? DEFAULT_DOMICILIO_CLIENTE);
            setDomicilioMensajero(_info?.company?.domicilio?.mensajero ?? DEFAULT_DOMICILIO_MENSAJERO);
        } else {
            setAddressConsignee("");
            setConsigneeId("");
            setDomicilioCliente(DEFAULT_DOMICILIO_CLIENTE);
            setDomicilioMensajero(DEFAULT_DOMICILIO_MENSAJERO);
        }
    }

    const changeConsignee = (val) => {
        setConsignee(val);
        alertaRemove(ref_form.current.consignee);

        let item = clientsList.filter(({ nombre, apellido }) => nombre + " " + apellido === val);
        item = item[0];

        if (typeof item === "object") {
            let _info = JSON.parse(item.info);
            console.log(_info);
            setAddressConsignee(item.address);
            setConsigneeId(item.id_user);
            setDomicilioCliente(_info?.company?.domicilio?.cliente ?? DEFAULT_DOMICILIO_CLIENTE);
            setDomicilioMensajero(_info?.company?.domicilio?.mensajero ?? DEFAULT_DOMICILIO_MENSAJERO);
        } else {
            setAddressConsignee("");
            setConsigneeId("");
            setDomicilioCliente(DEFAULT_DOMICILIO_CLIENTE);
            setDomicilioMensajero(DEFAULT_DOMICILIO_MENSAJERO);
        }
    }

    React.useEffect(() => {
        setTitulo("Nueva orden de recepción");
        document.body.style.overflow = "auto";
        if (data_user.nivel == 1 ||
            data_user.nivel == 2 ||
            data_user.nivel == 3 ||
            data_user.nivel == 5) {
            getAllList();
        } else {
            navigate("/dashboard");
        }
    }, []);

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("pack_edicion")}>

            <form className="pickup" ref={ref_form} onSubmit={e => e.preventDefault()}>
                <TitleSticky>Nueva orden de recepción</TitleSticky>

                <div className="pickup_container" style={{ marginBottom: 28 }}>

                    <div className="pickup_row">

                        <div className="pickup_column static">

                            <div className="pickup_column m_less" style={{ height: 54 }}>

                            </div>

                            <div className="pickup_column m_less">
                                <label style={{ marginTop: 12 }}>Nota</label>
                                <textarea name="notes" rows="2" placeholder="Ingresa una observación"
                                    value={notes} onChange={({ target: { value } }) => setNotes(value)} />
                            </div>

                        </div>

                        <div className="pickup_column static">
                            <Field
                                rol="6"
                                name="consignee"
                                titulo="Cliente bts"
                                list={clientsList}
                                placeholder="Ingresa Cliente bts"
                                value={consignee}
                                onChange={changeConsignee}
                                onAdd={addConsignee}
                            />

                            <div className="pickup_column m_less">

                                <label style={{ marginTop: 12 }}>Dirección</label>
                                <textarea name="address_delivery_location" rows="2" placeholder="Selecciona un Cliente bts para agregar su dirección."
                                    value={addressConsignee} onChange={({ target: { value } }) => setAddressConsignee(value)} readOnly={true} />
                            </div>
                        </div>

                    </div>

                    {allPaquages}
                </div>

                <div className="boton_agregar">
                    <div className="for_alert">
                        <button className={consigneeId !== "" ? "btn btn-common" : "btn btn-common _deshabilitado"}
                            type="button" name="other"
                            onClick={addPackage}>
                            <PlusCircleOutlineIcon /> Agregar paquete
                        </button>
                    </div>


                    <button type="button" className="btn btn-common width-static" onClick={validateFields}>Guardar</button>
                    <button type="button" className="btn btn-common width-static" onClick={() => navigate(-1)}>Cancelar</button>

                </div>

                {showLoading && <Loading />}
            </form >
        </RequirePermissions>
    )
}

export default NewReception;