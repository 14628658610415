import * as React from "react";
import Information from "mdi-react/InformationOutlineIcon";
import { useNavigate } from "react-router-dom";
import { MovePopUp } from "shared/MovePopUp";
import funciones from "services/funciones";
import { Alert } from "shared/Alert";
import { Input } from "shared/Input";
import { Api } from "services/api";
import { useMedia } from "hooks";
import * as Icon from "images";
import log from "services/log";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Abono = ({ parent, close, reload }) => {
    const navigate = useNavigate();
    const { setDataUser } = useMedia();
    const [showLoading, setShowLoading] = React.useState(false);
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [monto, setMonto] = React.useState("");
    const [notes, setNotes] = React.useState("");
    const [error, setError] = React.useState("");
    const [userList, setUserList] = React.useState([]);
    const [courierId, setCourierId] = React.useState("");
    const [courier, setCourier] = React.useState("");

    const getAllCourier = async () => {
        setShowLoading(true);
        let _data = await api.current.fetchJson({
            url: "get-only-carriers",
            data: {}
        });
        let _users = [];


        if (_data.response == 1) {
            setUserList(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
        return _users;
    }

    const changeCourier = (val) => {
        setCourier(val);
        setError("");

        let item = userList.filter(({ nombre, apellido }) => nombre + " " + apellido === val);
        item = item[0];

        if (typeof item === "object") {
            setCourierId(item.id_user);
        } else {
            setCourierId("");
        }
    }

    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close(false);
    }

    const validateFields = (e) => {
        e.preventDefault();
        let _error = false;

        setError("");

        if (courierId.length < 1) {
            setError("¡Debes ingresar un mensajero!");
            return;
        }

        if (monto.length < 1) {
            setError("¡Debes ingresar un monto!");
            return;
        }

        if (isNaN(monto)) {
            setError("¡Debes ingresar solo numeros!");
            return;
        }

        if (parseInt(monto) < 1) {
            setError("¡El monto no puede ser cero o menor!");
            return;
        }

        if (notes.length < 1) {
            setError(`¡Debes ingresar una descripción!`);
            return;
        }


        if (_error) {
            log.e("Tiene errores");
        } else {
            guardaCaja();
        }
    }

    const guardaCaja = async () => {
        setShowLoading(true);

        let _data = await api.current.fetchJson({
            url: "save-caja",
            data: {
                task: "abono_a_mensajero",
                destinatario: courierId,
                caja: 4,
                metodo: 1,
                movimiento: 13,
                monto: funciones.formatPrice(monto).int,
                concepto: `Abono de "${courier}" \n\nNota: ${notes}`
            }
        });

        if (_data.response == 1) {
            reload();
            document.body.style.overflow = "auto";
            close(false);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        getAllCourier();
    }, []);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            className="win_modal _overflow _delivery"
            title="Nuevo abono">

            <div className="_in_column">

                <div>
                    <label className="__titulo">Mensajero</label>
                    <Input
                        type="text"
                        list={userList.map(({ nombre, apellido }) => (nombre + " " + apellido))}
                        name="courier"
                        placeholder="Nombre del mensajero"
                        value={courier}
                        onChange={changeCourier} />
                </div>

                <div>
                    <label className="__titulo">Monto</label>
                    <Input type="tel" name="monto" placeholder="ingresa un valor"
                        value={funciones.formatPrice(monto).format} onChange={val => { setError(""); setMonto(val); }} />
                </div>

                <div>
                    <p className="__titulo">Descripción</p>
                    <textarea name="comment" rows="3" placeholder="ingresa una descripción"
                        value={notes} onChange={e => { setError(""); setNotes(e.target.value); }} />
                </div>

            </div>

            {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}

            {showLoading ?
                <button className="btn btn-common width-static"><Icon.Loading /></button>
                :
                <button className="btn btn-common width-static" onClick={validateFields}>Guardar</button>
            }
        </MovePopUp>
    )
}

export default Abono;