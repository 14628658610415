import * as React from 'react';
import FortgotForm from './components/FortgotForm';


const ForgotPassword = () => {

  React.useEffect(() => {
    document.title = "Restablecer contraseña";
  }, []);

  return (
    <div className="account">
      <div className="account_background">
        <div className="account__wrapper">

          <div className="account__card">

            <div className="account__head">
              <div className="logo"></div>
              <h4 className="account__subhead subhead">Bienvenido, inicia tu sesión para poder ingresar al sistema.</h4>
            </div>

            <FortgotForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;