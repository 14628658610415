import * as React from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SidebarCategory = ({ title, icon, children }) => {
    const [isCollapsed, setIsCollapsed] = React.useState(false);
    const categoryClass = classNames({
        'sidebar__category-wrap': true,
        'sidebar__category-wrap--open': isCollapsed,
    });

    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className={categoryClass}>
            <button type="button" className="sidebar__link sidebar__category" onClick={handleToggleCollapse}>
                {icon ?? ''}
                <p className="sidebar__link-title">{title}</p>
                <span className="sidebar__category-icon lnr lnr-chevron-right" />
            </button>
            {isCollapsed && <div className="sidebar__submenu-wrap">
                <ul className="sidebar__submenu">
                    <div>
                        {children}
                    </div>
                </ul>
            </div>}
        </div>
    );
};

SidebarCategory.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.element
};

export default SidebarCategory;
