import * as React from "react";
import Information from "mdi-react/InformationOutlineIcon";
import { useNavigate } from "react-router-dom";
import funciones from "services/funciones";
import { MovePopUp } from "shared/MovePopUp";
import { Alert } from "shared/Alert";
import { Input } from "shared/Input";
import { Api } from "services/api";
import { useMedia } from "hooks";
import * as Icon from "images";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Opciones = ({ parent, data, close, reload }) => {
    const navigate = useNavigate();
    const { data_user, setDataUser } = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [showLoading, setShowLoading] = React.useState(false);
    const [monto, setMonto] = React.useState("");
    const [notes, setNotes] = React.useState("");
    const [error, setError] = React.useState("");


    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close(false);
    }

    const validaAcepta = (e) => {
        e.preventDefault();

        setError("");

        if (isNaN(monto)) {
            setError("¡Debes ingresar solo numeros!");
            return;
        }

        if (parseInt(monto) === 0) {
            setError("¡El monto no puede ser cero o menor!");
            return;
        }

        aceptaAbono();
    }

    const aceptaAbono = async () => {
        setShowLoading(true);

        let _data = await api.current.fetchJson({
            url: "save-caja",
            data: {
                task: "acepta_pendiente",
                id_caja: data.id,
                monto: monto.toString().replaceAll(".", ""),
                concepto: notes.length < 1 ? `${data.concepto} \n\nRecibido por "${data_user.nombre}".` : `${data.concepto} \n\nRecibido por "${data_user.nombre}". \nNota: ${notes}`
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => {
                reload();
                document.body.style.overflow = "auto";
                close(false);
            });
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validaRechaza = (e) => {
        e.preventDefault();

        setError("");

        if (notes.length < 1) {
            setError(`¡Debes indicar el motivo del rechazo!`);
            return;
        }

        rechazaAbono();
    }

    const rechazaAbono = async () => {
        setShowLoading(true);
        let _temp = data.concepto.replaceAll(`"${data.gestor_name}"`, "");

        let _data = await api.current.fetchJson({
            url: "save-caja",
            data: {
                task: "rechaza_pendiente",
                id_caja: data.id,
                monto: funciones.formatPrice(monto).int,
                concepto: `"${data_user.nombre}" rechazó${_temp}. \n\nMotivo del rechazo: ${notes}`
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => {
                reload();
                document.body.style.overflow = "auto";
                close(false);
            });
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
    }, []);

    React.useEffect(() => {
        setMonto(data.monto);
    }, [data]);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            className="win_modal _overflow _delivery"
            title={data.concepto}>

            <div className="_in_column">

                <div>
                    <label className="__titulo">Monto</label>
                    <Input type="tel" name="monto" placeholder="ingresa un valor"
                        value={funciones.formatPrice(monto).format} onChange={val => { setError(""); setMonto(val); }} />
                </div>

                <div>
                    <p className="__titulo">Nota</p>
                    <textarea name="comment" rows="3" placeholder="ingresa una nota"
                        value={notes} onChange={e => { setError(""); setNotes(e.target.value); }} />
                </div>

            </div>

            {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}

            {showLoading ?
                <div className="cont_btns_caja">
                    <button className="btn btn-common width-static" style={{ width: "90px", paddingLeft: 12 }}><Icon.Loading /></button>
                    <button className="btn btn-common width-static" style={{ width: "90px", paddingLeft: 12 }}><Icon.Loading /></button>
                </div>
                :
                <div className="cont_btns_caja">
                    <button className="btn btn-common" style={{ width: "90px", paddingLeft: 12 }} onClick={validaAcepta}>Confirmar</button>
                    <button className="btn btn-common" style={{ width: "90px", paddingLeft: 12 }} onClick={validaRechaza}>Rechazar</button>
                </div>
            }
        </MovePopUp >
    )
}

export default Opciones;