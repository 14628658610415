import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import Router from './Router';
import '../styles/app.css';
import log from './log';


const App = () => {

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        log.d(`x: ${e.pageX}px y: ${e.pageY}px`);
    }

    React.useEffect(() => {
        if (typeof android !== "undefined") {
            document.onselectstart = () => false;
        } else {
            if (process.env.NODE_ENV === 'production') {
                document.addEventListener("contextmenu", handleContextMenu);

                return () => {
                    document.removeEventListener("contextmenu", handleContextMenu);
                }
            }
        }
    }, []);

    return (
        <BrowserRouter>
            <ScrollToTop>
                <Router />
            </ScrollToTop>
        </BrowserRouter>
    );
};

export default App;
