import * as React from "react";
import { Alert, Confirm, ResumenEntregaCarrier } from "shared/Alert";
import { RequirePermissions } from "shared/AlertMessages";
import ListPaquage from "./components/ListPaquage";
import { TitleSticky } from "shared/Components";
import Recogidas from "./components/Recogidas";
import { SearchInReport } from "shared/Input";
import Entregas from "./components/Entregas";
import withRouter from "services/withRouter";
import { Container, Row } from "reactstrap";
import Resumen from "./components/Resumen";
import funciones from "services/funciones";
import Cortes from "./components/Cortes";
import { Api } from "services/api";
import { useMedia } from "hooks";
import * as Icon from "images";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-extend-native: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

Date.prototype.getWeekNumber = function () {
    var d = new Date(+this);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));

    return Math.ceil((((d - new Date(d.getFullYear(), 0, 1)) / 8.64e7) + 1) / 7);
}

const ReportCarrier = (router) => {
    const { navigate, location } = router;
    const { setTitulo, data_user } = useMedia();
    const api = React.useRef(new Api());
    const [listCountDelivery, setListCountDelivery] = React.useState([]);
    const [showLoading, setShowLoading] = React.useState(true);
    const [devoluciones, setDevoluciones] = React.useState([]);
    const [userNameList, setUserNameList] = React.useState([]);
    const [listPreCorte, setListPreCorte] = React.useState([]);
    const [listPickups, setListPickups] = React.useState([]);
    const [resumenPago, setResumenPago] = React.useState([]);
    const [userToClose, setUserToClose] = React.useState();
    const [paraExcel, setParaExcel] = React.useState([]);
    const [criterio, setCriterio] = React.useState("");
    const [userList, setUserList] = React.useState([]);
    const [conteo, setConteo] = React.useState(0);


    const handleSelectUser = (_nombre) => {
        setCriterio(_nombre);

        let _item = userList.filter((item) => item.nombre + " " + item.apellido === _nombre);

        if (_item.length >= 1) {
            if (_item[0].id_user === "all") {
                Confirm("Ten en cuenta que la consulta de 'TODOS' los registros puede ser muy pesada y tardar mucho tiempo. \n\n¿Desea continuar con la consulta?", continuar => {
                    if (continuar) {
                        setUserToClose(_item[0]);
                    } else {
                        setCriterio(userToClose.nombre + " " + userToClose.apellido);
                    }
                });
            } else {
                setUserToClose(_item[0]);
            }
        }
    }

    const handlePasedUser = (_lista_usuarios, _nombre) => {
        setCriterio(_nombre);

        let _item = _lista_usuarios.filter((item) => item.nombre + " " + item.apellido === _nombre);

        if (_item.length >= 1) {
            setUserToClose(_item[0]);
        }
    }

    const getUserList = async () => {
        setShowLoading(true);

        let _users = [];
        let _all_users = [];
        let _data = await api.current.fetchJson({
            url: "get-users",
            data: {
                roll: "users",
                nivel: "4"
            }
        });

        if (_data.response == 1) {
            _data.data.map(item => {
                _users.push(item.nombre + " " + item.apellido);
                _all_users.push(item);
            });

            setUserList(_all_users);
            setUserNameList(_users);

            if (location.state && location.state.nombre) {
                handlePasedUser(_data.data, location.state.nombre);
            } else {
                setShowLoading(false);
            }
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
            setShowLoading(false);
        } else {
            Alert(_data.msg, "warning");
            setShowLoading(false);
        }

        return _users;
    }

    const armaMensajero = (_resumen) => {
        // 304 378 3429
        let _sales = [
            {
                id: 0,
                title: 'Totales',
                abonos: _resumen.abonos,
                entregas: _resumen.valor_entregado,
                recogidas: _resumen.pagos_recogidas,
                v_mensajero: _resumen.domicilios_entregas,
                v_mensajero_r: _resumen.domicilios_recogidas,
                subtotal: _resumen.total,
                efectivo: _resumen.efectivo,
                transferencia: _resumen.transferencia,
                domicilio_ko: _resumen.ko_dom_pago,
            },
        ];

        setResumenPago(_sales);
    }

    const getSales = async () => {
        if (userToClose && userToClose.id_user) {
            setShowLoading(true);
            let _obj = {
                nivel: "user"
            };

            switch (parseInt(data_user.nivel)) {
                case 1:
                case 2:
                case 3:
                    _obj = {
                        nivel: "admin",
                        user_close: userToClose ? userToClose.id_user : "",
                        nivel_close: userToClose ? userToClose.nivel : ""
                    };
                    break;
                case 4:
                    _obj = {
                        nivel: "carrier"
                    };
                    break;
                case 8:
                    _obj = {
                        nivel: "recepcion",
                        user_close: userToClose ? userToClose.id_user : "",
                        nivel_close: userToClose ? userToClose.nivel : ""
                    };
                    break;
                case 6:
                    _obj = {
                        nivel: "cliente"
                    };
                    break;
            }

            let _data = await api.current.fetchJson({
                url: "cuentas-mensajero",
                data: {
                    ..._obj,
                    resumen: true,
                    entregas: true,
                    recogidas: true,
                    pre_corte: true,
                    devoluciones: true
                }
            });

            if (_data.response == 1) {
                setDevoluciones(_data.devoluciones);
                setParaExcel(_data.data);
                setConteo(parseInt(_data.conteo));

                if (userToClose.nivel == 4) {
                    armaMensajero(_data.resumen);
                }

                if (_data.entregas.length > 0) {
                    let _delivery = _data.entregas.map(item => {
                        item.counter = parseInt(item.counter) || 0;
                        return item;
                    });

                    setListCountDelivery(_delivery);
                }

                if (_data.recogidas.length > 0) {
                    _data.recogidas.map(item => {
                        item.collect = parseInt(item.collect_product) || 0;
                        return item;
                    });

                    setListPickups(_data.recogidas);
                }

                if (_data.pre_corte.length > 0) {
                    let _lista = [];
                    _data.pre_corte.map(item => {
                        _lista.push(item.info);
                    });

                    setListPreCorte(_lista);
                } else {
                    setListPreCorte([]);
                }

            } else if (_data.response === -2) {
                Alert(_data.msg, "warning", () => {
                    document.body.style.overflow = "auto";
                    navigate("/sign-in");
                });
            } else {
                Alert(_data.msg, "warning");
            }
        }

        setShowLoading(false);
    }

    const cerrarCaja = async (_lista_devoluciones, _lista_cancelados) => {

        if (userToClose && userToClose.id_user) {
            setShowLoading(true);
            let _nivel = "user";

            switch (parseInt(data_user.nivel)) {
                case 1:
                case 2:
                case 3:
                    _nivel = "admin";
                    break;
                case 4:
                    _nivel = "carrier";
                    break;
                case 6:
                    _nivel = "cliente";
                    break;
                default:
                    _nivel = "user";
                    break;
            }

            let _data = await api.current.fetchJson({
                url: "cerrar-caja",
                data: {
                    task: "corte_mensajero",
                    nivel: _nivel,
                    lista_devoluciones: _lista_devoluciones,
                    lista_cancelados: _lista_cancelados,
                    user_close: userToClose ? userToClose.id_user : "",
                    nivel_close: userToClose ? userToClose.nivel : ""
                }
            });

            if (_data.response == 1) {
                Alert("Caja recibida y reabierta correctamente.", "ok", () => getSales());
            } else if (_data.response === -2) {
                Alert(_data.msg, "warning", () => {
                    document.body.style.overflow = "auto";
                    navigate("/sign-in");
                });
            } else {
                Alert(_data.msg, "warning");
            }

            setShowLoading(false);
        }
    }

    const preCierreCaja = async (e) => {
        e.preventDefault();

        if (userToClose && userToClose.id_user) {
            setShowLoading(true);
            let _nivel = "user";

            switch (parseInt(data_user.nivel)) {
                case 1:
                case 2:
                case 3:
                    _nivel = "admin";
                    break;
                case 4:
                    _nivel = "carrier";
                    break;
                case 6:
                    _nivel = "cliente";
                    break;
                default:
                    _nivel = "user";
                    break;
            }

            let _data = await api.current.fetchJson({
                url: "cerrar-caja",
                data: {
                    task: "pre_cierre",
                    nivel: _nivel,
                    user_close: userToClose ? userToClose.id_user : "",
                    nivel_close: userToClose ? userToClose.nivel : ""
                }
            });

            if (_data.response == 1) {
                let _no_have = userToClose.nivel == 4 ? "¡No devolverá paquetes!" : "¡No tiene devociones!";
                let _entregada = _data.data.cantidad_entregada > 0 ?
                    <>
                        Valor entregado: <span>${funciones.formatPrice(_data.data.valor_entregado).format}</span>
                        <br />
                        {_data.data.ko_dom_pago_length > 0 && <>
                            KO.DOM.PAGO: <span>${funciones.formatPrice(_data.data.ko_dom_pago).format}</span>
                            <br />
                        </>}
                        {_data.data.cantidad_entregada} entrega{_data.data.cantidad_entregada < 2 ? "" : "s"}: <span>${funciones.formatPrice(_data.data.total_entregado).format}</span>
                    </>
                    :
                    <>¡No tiene entregas!</>;
                let _devuelto = _data.data.candidad_devuelta > 0 ? `Devolverá ${_data.data.candidad_devuelta} paquete${_data.data.candidad_devuelta < 2 ? "" : "s"} a bodega` : _no_have;
                let _efectivo = _data.data.efectivo > 0 ? <>Efectivo: <span>${funciones.formatPrice(_data.data.efectivo).format}</span></> : <>¡Sin pagos en efectivo!</>;
                let _transferencias = _data.data.transferencia > 0 ? <>Transferencias: <span>${funciones.formatPrice(_data.data.transferencia).format}</span></> : <>¡Sin transferencias!</>;

                let _ok_dv_cambio = JSON.parse(_data.data.resumen_ok_dv_cambio);
                let _ok_dv_sobrante = JSON.parse(_data.data.resumen_ok_dv_sobrante);
                let _res_dev = JSON.parse(_data.data.resumen_devoluciones);

                /**
                 * Llamo el popup pasandole toda la información 
                 * obtenida desde precorte, la organizo y formateo antes de pasarla
                 */
                ResumenEntregaCarrier("Resumen de entrega", {
                    _entregada,
                    _efectivo,
                    _transferencias,
                    cantidad_ok_dv: _data.data.cantidad_ok_dv,
                    ok_dv_cambio: _data.data.ok_dv_cambio,
                    _ok_dv_cambio,
                    ok_dv_sobrante: _data.data.ok_dv_sobrante,
                    _ok_dv_sobrante,
                    _devuelto,
                    _res_dev
                }, (_lista_devoluciones, _lista_cancelados) => {
                    cerrarCaja(_lista_devoluciones, _lista_cancelados);
                });

            } else if (_data.response === -2) {
                Alert(_data.msg, "warning", () => {
                    document.body.style.overflow = "auto";
                    navigate("/sign-in");
                });
            } else {
                Alert(_data.msg, "warning");
            }

            setShowLoading(false);
        }
    }

    const BtnClose = () => {
        let _btn = <button className="close-button _deshabilitado"><Icon.CajaMsj />Recibir caja</button>;

        if (userToClose) {

            if (showLoading) {
                _btn = <button className="close-button"><Icon.Loading />Cargando...</button>;
            } else {
                _btn = <button className="close-button" onClick={preCierreCaja}><Icon.CajaMsj />Recibir caja</button>;
            }

        }

        return (
            <div className="cont_btns_caja">
                {_btn}
            </div>)
    }

    React.useEffect(() => {
        setTitulo("Reporte de mensajeros");
        document.body.style.overflow = "auto";
        if (funciones.validaPermisos("reporte_msj")) {
            getUserList();
        }
    }, []);

    React.useEffect(() => {
        if (funciones.validaPermisos("reporte_msj")) {
            getSales();
        }
    }, [userToClose]);


    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("reporte_msj")}>

            <TitleSticky className="_in_page">Reporte de mensajeros <i>{(userToClose?.nombre ? "\"" + userToClose?.nombre + " " + userToClose?.apellido + "\"" : "").toLowerCase()}</i></TitleSticky>

            <Container className="dashboard">
                <Row>
                    <SearchInReport
                        name="Buscar"
                        placeholder="Buscar mensajero"
                        value={criterio}
                        list={userNameList}
                        onChange={handleSelectUser} />

                    <ListPaquage
                        userToClose={userToClose}
                        sales={paraExcel}
                        conteo={conteo}
                        devoluciones={devoluciones}
                        preCorte={listPreCorte}
                        loading={showLoading}
                        onRefresh={() => getSales()}>
                        {funciones.validaGrupo(["caja_msj", "caja_msj_aux"]) && <BtnClose />}

                        {userToClose && <Resumen
                            titulo="Total a entregar"
                            userToClose={userToClose}
                            data={resumenPago}
                            loading={showLoading}
                            onRefresh={() => getSales()} />}

                    </ListPaquage>
                </Row>

                <Row>

                    <Entregas
                        userToClose={userToClose}
                        list={listCountDelivery}
                        loading={showLoading}
                        onRefresh={() => getSales()} />

                    <Recogidas
                        userToClose={userToClose}
                        list={listPickups}
                        loading={showLoading}
                        onRefresh={() => getSales()} />

                </Row>

                <Row>
                    {(userToClose && userToClose.id_user !== "all") && <Cortes data_user={userToClose} />}
                </Row>
            </Container>
        </RequirePermissions>
    )
}

export default withRouter(ReportCarrier);
