import * as React from "react";
import Information from "mdi-react/InformationOutlineIcon";
import { useNavigate } from "react-router-dom";
import { MovePopUp } from "shared/MovePopUp";
import funciones from "services/funciones";
import { Alert } from "shared/Alert";
import { Input } from "shared/Input";
import { Api } from "services/api";
import { useMedia } from "hooks";
import * as Icon from "images";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */

const CierreCaja = ({ data, parent, close, reload }) => {
    const navigate = useNavigate();
    const { setDataUser } = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [showLoading, setShowLoading] = React.useState(false);
    const [transactions, setTransactions] = React.useState([]);
    const [efectivo, setEfectivo] = React.useState("");
    const [error, setError] = React.useState("");
    const [msg, setMsg] = React.useState("");


    const handleClose = () => {
        document.body.style.overflow = "auto";
        if (isLoaded) {
            reload();
        }
        close();
    }

    const validateFields = (e) => {
        e.preventDefault();
        let _error = false;

        setError("");

        if (parseInt(data.subtotal || 0) == 0) {
            setError("¡No hay contenido para realizar un corte!");
            return;
        }

        if (efectivo == "") {
            setError("¡El valor en efectivo no puede estar vacío!");
            return;
        }

        if (isNaN(efectivo)) {
            setError("¡El valor en efectivo solo admite numeros!");
            return;
        }

        if (efectivo < 0) {
            setError("¡El monto en efectivo no puede ser menor que cero!");
            return;
        }

        if (!_error) {
            pagaCaja();
        }
    }

    const pagaCaja = async () => {
        setShowLoading(true);

        let _data = await api.current.fetchJson({
            url: "cerrar-caja",
            data: {
                task: "corte_cliente",
                caja_aux: funciones.validaPermisos("caja_cli_aux"),
                id_user: data.id_user,
                efectivo: efectivo == "" ? 0 : funciones.formatPrice(efectivo).int
            }
        });

        if (_data.response == 1) {
            let _respuesta = _data.data;

            if (_respuesta.response == 1) {
                let _entregada = _respuesta.cantidad_entregada > 0 ?
                    <>
                        Valor total: <span>${funciones.formatPrice(_respuesta.valor_entregado).format}</span>
                        <br />
                        {_respuesta.cantidad_entregada} entrega{_respuesta.cantidad_entregada < 2 ? "" : "s"}: <span>${funciones.formatPrice(_respuesta.total).format}</span>
                    </>
                    :
                    <>¡No tiene entregas!</>;
                let _devuelto = parseInt(_respuesta.candidad_devuelta) > 0 ? `${_respuesta.candidad_devuelta} paquete${_respuesta.candidad_devuelta < 2 ? "" : "s"} para devolver` : "¡Sin devociones!";
                let _efectivo = parseInt(_respuesta.efectivo) > 0 ? `En efectivo = $${funciones.formatPrice(_respuesta.efectivo).format}` : null;
                let _saldo = _respuesta.saldo == 0 ? `Sin saldo pendiente.` : `Saldo = $${funciones.formatPrice(_respuesta.saldo).format}`;
                let _transferencias = parseInt(_respuesta.transferencia) > 0 ? `En transferencia = $${funciones.formatPrice(_respuesta.transferencia).format}` : null;

                let _dev_ko = parseInt(_respuesta.cantidad_ok_dv) > 0 ?
                    <>
                        <b>{_respuesta.ok_dv_cambio}</b> {_respuesta.resumen_ok_dv_cambio && <i><br />{_respuesta.resumen_ok_dv_cambio}</i>}
                        <br />
                        <b>{_respuesta.ok_dv_sobrante}</b> {_respuesta.resumen_ok_dv_sobrante && <i><br />{_respuesta.resumen_ok_dv_sobrante}</i>}
                        <br />
                    </> : <>¡Sin entregas OK.DV!</>;

                let _mensaje = <p className="_msg_p">
                    <b>Corte realizado con:</b>
                    <br />
                    <br />{_entregada}
                    {_efectivo && <><br />{_efectivo}</>}
                    {_transferencias && <><br />{_transferencias}</>}
                    <br />{_saldo}
                    <br />
                    <br />{_dev_ko}
                    <br /><b>{_devuelto}</b>
                    {_respuesta.resumen_devoluciones && <><br /><i>{_respuesta.resumen_devoluciones}</i></>}</p>;

                setMsg(_mensaje);
                setIsLoaded(true);
            } else {
                setError(_respuesta.msg);
            }
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            setError(_data.msg);
        }

        setShowLoading(false);
    }

    const BtnAceptar = () => {
        if (isLoaded) {
            return <button className="btn btn-common width-static" onClick={handleClose}>Aceptar</button>;
        } else {
            if (showLoading) {
                return <button className="btn btn-common width-static"><Icon.Loading /></button>;
            } else {
                return <button className="btn btn-common width-static" onClick={validateFields}>Pagar</button>;
            }
        }
    }

    React.useEffect(() => {
        let _efec = parseInt(data.subtotal || 0) - parseInt(data.transferencia || 0);
        setEfectivo(_efec + parseInt(data.transferencia || 0));

        if (data.transactions !== null) {
            let _tran = data.transactions.split("|");
            let _pagos = [];

            _tran.map(item => {
                let _arr = item.split(": ");
                _pagos.push({ entity: _arr[0], number: _arr[1] });
            });

            setTransactions(_pagos);
        }
    }, [data]);

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
    }, []);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            className="win_modal _overflow _delivery"
            title={"Pago para \n\"" + data.nombre + " " + data.apellido + "\""}>

            <div className="_in_column _over_margin">
                {isLoaded ?
                    <>
                        {msg}
                    </>
                    :
                    <>
                        {transactions.map((item, index) => <p key={index} className="_metodo_pago"><b>{item.entity}:</b><br /> {item.number}</p>)}

                        <h4 className="_precio">Total: $ {funciones.formatPrice(data.subtotal || 0).format}</h4>

                        <div>
                            <label className="__titulo">Efectivo</label>
                            <Input type="tel" name="efectivo" placeholder="ingresa el valor"
                                value={funciones.formatPrice(efectivo).format} onChange={val => { setError(""); setEfectivo(val); }} />
                        </div>
                    </>
                }
            </div>

            {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}

            <BtnAceptar />
        </MovePopUp>
    )
}

export default CierreCaja;