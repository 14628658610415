import * as React from "react";
import DeleteIcon from "mdi-react/DeleteOutlineIcon";
import EditIcon from "mdi-react/EditOutlineIcon";
import HistoryIcon from "mdi-react/HistoryIcon";
import OpenIcon from "mdi-react/OpenInNewIcon";
import { Alert, Confirm } from "shared/Alert";
import funciones from "services/funciones";
import History from "shared/History";
import { Api } from "services/api";
import { useMedia } from "hooks";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const MenuRow = (params) => {
    const { navigate, data, blue, children, onSel, reload, current } = params;
    const { media_movil, data_user, setDataUser } = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [clases, setClases] = React.useState(blue == 0 ? "table_row bkg_blue" : "table_row");
    const [_pos, set_pos] = React.useState({ top: "0", left: "0" });
    const [showHistory, setShowHistory] = React.useState(false);
    const [modalView, setModalView] = React.useState(null);
    const [showMenu, setShowMenu] = React.useState(false);
    const [_e, set_e] = React.useState();
    const menuRef = React.useRef();


    const goOpen = (e) => {
        e.preventDefault()
        e.stopPropagation();

        navigate("/dashboard/orden-de-servicio", { state: { data: data } });
    }

    const goEdit = (e) => {
        e.preventDefault()
        e.stopPropagation();

        navigate("/dashboard/editar-orden-de-servicio",
            {
                state: {
                    data: { ...data, date_received: data.date_received == null ? data.date_creation : data.date_received }
                }
            });
    }

    const goDelete = (e) => {
        hiddenMenu(e);

        Confirm(`Seguro de borrar la orden Nº ${data.consecutivo}?`, async (response) => {
            if (response) {
                let _data = await api.current.deleteOrder({
                    id_order: data.id,
                    tipo: "orden de recepción"
                });

                if (_data.response == 1) {
                    reload();
                } else if (_data.response === -2) {
                    Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
                } else {
                    Alert(_data.msg, "warning");
                }
            }
        });

    }

    const callHistory = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setModalView(
            <History
                data={{
                    ...data,
                    tipo: 1,
                    parent: {
                        ...e.target.getBoundingClientRect().toJSON(),
                        top: parseFloat(_pos.top),
                        left: parseFloat(_pos.left)
                    }
                }}
                close={setShowHistory} />);

        setShowMenu(false);
        setShowHistory(true);
    }

    const onSelect = (e) => {
        e.preventDefault()
        e.stopPropagation();

        onSel(data);
    }

    const stopClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const hiddenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setShowMenu(false);
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        set_e(e);
        document.body.style.overflow = "hidden";
        setShowMenu(true);
    }

    const BtnDelete = () => {
        let _btn = <li className="unable"><DeleteIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Borrar</span></li>;

        switch (parseInt(data.estado)) {
            case 0:
            case 1:
            case 2:
            case 4:
            case 5:
            case 7:
            case 8:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
                if (data_user.nivel < 4) {
                    _btn = <li onClick={goDelete}><DeleteIcon /> <span>Borrar</span></li>;
                }
                break;
        }

        return (_btn);
    }

    const BtnEdit = () => {
        let _btn = <li className="unable"><EditIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Editar</span></li>;

        switch (parseInt(data.estado)) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 7:
            case 8:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
                if (data_user.nivel == 1 ||
                    data_user.nivel == 2 ||
                    data_user.nivel == 3 ||
                    data_user.nivel == 5) {
                    _btn = <li onClick={goEdit}><EditIcon /> <span>Editar</span></li>;
                }

                if (data_user.nivel == 6) {
                    _btn = <></>;
                }
                break;
        }

        return (_btn);
    }

    React.useEffect(() => {
        if (showMenu) {
            set_pos(funciones.getPositonDisplay(_e, menuRef.current));
        }
    }, [showMenu]);

    React.useEffect(() => {
        current === data.id && setClases(c => c + " bkg_select");
    }, [current]);

    return (
        <tr className={clases}
            onDoubleClick={goOpen}
            onClick={onSelect}
            onContextMenu={handleContextMenu}>

            {children}
            <td className="rowMenu" onClick={stopClick} onDoubleClick={stopClick} onContextMenu={stopClick}>
                {showMenu &&
                    <>
                        <div className="back_form _bkg_black" onClick={hiddenMenu} onContextMenu={hiddenMenu}></div>
                        <nav className="menu" style={{ ..._pos, width: media_movil ? 170 : 130 }} ref={menuRef}>
                            <ul>
                                <li onClick={goOpen}><OpenIcon /> <span><strong>Abrir</strong></span></li>
                                <BtnEdit />
                                <hr />
                                <BtnDelete />

                                {data_user.nivel < 4 && <li onClick={callHistory}><HistoryIcon /> <span>Historial</span></li>}

                            </ul>
                        </nav>
                    </>}

                {showHistory && modalView}
            </td>
        </tr>
    )
}

export default MenuRow;