import * as React from "react";
import { Alert, alerta, alertaRemove, alertDown, Confirm } from "shared/Alert";
import PlusCircleOutlineIcon from "mdi-react/PlusCircleOutlineIcon";
import ClipboardIcon from "mdi-react/ClipboardArrowDownOutlineIcon";
import CalendarMonthIcon from "mdi-react/CalendarMonthIcon";
import { RequirePermissions } from "shared/AlertMessages";
import { TitleSticky } from "shared/Components";
import { Field, InputNum } from "shared/Input";
import { useNavigate } from "react-router-dom";
import withRouter from "services/withRouter";
import EditContainer from "./EditContainer";
import funciones from "services/funciones";
import NewContainer from "./NewContainer";
import DatePicker from "react-datepicker";
import Loading from "shared/Loading";
import { useMedia } from "hooks";
import { Api } from "services/api";


/* eslint eqeqeq: 0 */
/* eslint no-unused-vars: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
const DEFAULT_DOMICILIO_CLIENTE = "8000";
const DEFAULT_DOMICILIO_MENSAJERO = "5000";

const EditPickUp = ({ location }) => {
    const navigate = useNavigate();
    const data = location.state.data;
    const { setTitulo, setDataUser, clientsList, setClientsList } = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [carrier, setCarrier] = React.useState("");
    const [carrierId, setCarrierId] = React.useState("");
    const [consignee, setConsignee] = React.useState("");
    const [consigneeId, setConsigneeId] = React.useState("");
    const [domicilioCliente, setDomicilioCliente] = React.useState(DEFAULT_DOMICILIO_CLIENTE);
    const [domicilioMensajero, setDomicilioMensajero] = React.useState(DEFAULT_DOMICILIO_MENSAJERO);
    const [collect, setCollect] = React.useState(false);
    const [payForBts, setPayForBts] = React.useState(false);
    const [showLoading, setShowLoading] = React.useState(false);
    const [collectProduct, setCollectProduct] = React.useState("");
    const [collectDomic, setCollectDomic] = React.useState("");
    const [collectCarrier, setCollectCarrier] = React.useState("");
    const [namePickupLocation, setNamePickupLocation] = React.useState("");
    const [addressPickupLocation, setAddressPickupLocation] = React.useState("");
    const [notes, setNotes] = React.useState("");
    const [fechaIngreso, setFechaIngreso] = React.useState(new Date());
    const [carrierList, setCarrierList] = React.useState([{ id_user: 0, nombre: "Cargando..." }]);

    const [packages, setPackages] = React.useState({});
    const [allPaquages, setAllPaquages] = React.useState([]);
    const ref_form = React.useRef();
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const _titulo = data_user.nivel == 4 ? "Agregar paquetes" : "Editar orden de recogida";


    const getPackagesList = async (_id) => {
        let _data = await api.current.getPackages(_id);

        if (_data.response == 1) {
            configPackages(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const configPackages = (_data) => {
        let _pack = {};
        let _allPack = [];
        let _index;

        _data.map((item, index) => {
            _index = index + 1;
            item.index = _index;
            item.valorp = item.price;
            item.valord = item.v_domicilio;
            item.valorm = item.v_mensajero;

            _pack = { ..._pack, [_index]: item };
            _allPack.push(<ContainerEdit key={_index} pack={item} />);
        });

        setPackages(_pack);
        setAllPaquages(_allPack);
    }

    const updatePackage = (pack, index) => {
        setPackages(p => ({ ...p, [index]: pack }))
    }

    const addPackage = (e) => {
        e.preventDefault();
        alertaRemove(ref_form.current.other);

        if (consigneeId !== "") {

            let _index = allPaquages.length + 1;
            let _pack = {
                "index": _index,
                "id": 0,
                "type": "N/A",
                "pieces": 1,
                "codigo": "",
                "valorp": "0",
                "valord": domicilioCliente,
                "valorm": domicilioMensajero,
                "devolucion": null,
                "descripcion": "",
                "attachments": process.env.REACT_APP_IMAGE_PACK,
                "estado": data_user.nivel == 4 ? 2 : data.estado
            };

            setPackages({ ...packages, [_index]: _pack });

            setAllPaquages([...allPaquages, <Container key={_index} pack={_pack} />]);
        }
    }

    const removePackage = (_index, _id) => {
        let _pack_list = [];
        let _action = () => {
            setPackages(p => {
                let _new_list;

                for (let i in p) {
                    if (p[i].index !== _index) {
                        _new_list = { ..._new_list, [i]: p[i] };
                        _pack_list.push(<ContainerEdit key={p[i].index} pack={p[i]} />);
                    }
                }

                return _new_list;
            });

            setAllPaquages(_pack_list);
        }

        if (_id != 0) {
            Confirm("Seguro de borrar este paquete", (response) => {
                if (response) {
                    _action();
                }
            });
        } else {
            _action();
        }

    }

    const Container = ({ pack }) => {

        return (
            <NewContainer
                item={pack}
                remove={removePackage}
                update={item => setPackages(p => ({ ...p, [pack.index]: item }))} />
        );
    }

    const ContainerEdit = ({ pack }) => {

        return (
            <EditContainer
                item={pack}
                remove={removePackage}
                update={(p, i) => updatePackage(p, i)} />
        );
    }

    const getClients = async () => {
        let _data = await api.current.fetchJson({
            url: "get-users",
            data: {
                roll: "clients",
                list_ids: clientsList.map(item => item.id_user).join(",")
            }
        });
        let _users = clientsList.filter(item => item.id_user != 0);

        if (_data.response == 1) {
            _data.data.map(item => {
                item.tarifas_sistema = JSON.parse(item.tarifas_sistema);
                item.tarifas_cliente = JSON.parse(item.tarifas_cliente);
                item.tarifas = item.tarifas_sistema.map(t => {
                    let _city = item.tarifas_cliente.find(c => c.id == t.id);

                    if (_city?.id === t.id) {
                        return _city;
                    } else {
                        return t;
                    }
                });
                item.nivel > "2" && _users.push(item);
            });

            setClientsList(_users);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        return _users;
    }

    const getCarrier = async () => {
        let _data = await api.current.fetchJson({
            url: "get-only-carriers",
            data: {}
        });
        let _users = [];


        if (_data.response == 1) {
            let _lista = _data.data;

            _lista.map(item => {
                item.nivel > "2" && _users.push(item);
            });

            if (data.recogida.carrier) {
                let item = _lista.filter(({ id_user }) => id_user == data.recogida.carrier);
                item = item[0];

                setCarrier(item.nombre + " " + item.apellido);
                setCarrierId(data.recogida.carrier);
            }

            setCarrierList(_users);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        return _users;
    }

    const format = (_num) => {
        return _num < 10 ? "0" + _num : _num;
    }

    const saveOrder = async (asPickedUp) => {
        setShowLoading(true);
        let _info = {
            order: data.id,
            estado: data_user.nivel == 4 ? asPickedUp : data.estado,
            date_pickup: fechaIngreso.getFullYear() + "-" + format(fechaIngreso.getMonth() + 1) + "-" + format(fechaIngreso.getDate()),
            consignee: consigneeId,
            carrier: carrierId,
            pickup_name: namePickupLocation,
            pickup_address: addressPickupLocation,
            notes: notes,
            packages: packages
        };

        if (collect) {
            _info = {
                ..._info,
                collect_product: collectProduct.replaceAll(".", ""),
                collect_domic: collectDomic.replaceAll(".", ""),
                collect_carrier: collectCarrier.replaceAll(".", ""),
                collect_pay: payForBts ? 1 : 0,
            }
        } else {
            _info = {
                ..._info,
                collect_product: "",
                collect_domic: "",
                collect_carrier: "",
                collect_pay: 0,
            }
        }

        let _data = await api.current.editPickUpOrder(_info);

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => navigate(-1));
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validateFields = (e) => {
        e.preventDefault();
        let _is_empty = false;
        let _message = "";

        alertaRemove(ref_form.current.consignee);
        collect && alertaRemove(ref_form.current.collect_product);
        collect && alertaRemove(ref_form.current.collect_domic);
        collect && alertaRemove(ref_form.current.collect_carrier);
        alertaRemove(ref_form.current.other);

        if (consignee.length < 1) {
            alerta(ref_form.current.consignee, "Debes ingresar un Cliente bts!");
            ref_form.current.consignee.focus();
            window.scrollTo({
                top: 80,
                behavior: 'smooth'
            });
            return;
        }

        alertaRemove(ref_form.current.name_pickup_location);

        if (namePickupLocation.length < 1) {
            alerta(ref_form.current.name_pickup_location, "Debes ingresar el nombre de la persona \ndonde se recogerá el paquete!");
            ref_form.current.name_pickup_location.focus();
            window.scrollTo({
                top: 180,
                behavior: 'smooth'
            });
            return;
        }

        if (collect) {
            if (collectProduct.length < 1 && collectDomic.length < 1 && collectCarrier.length < 1) {
                alerta(ref_form.current.collect_product, "Debes indicar el valor del producto", "warning");
                alerta(ref_form.current.collect_domic, "o el valor del domicilio", "warning");
                alerta(ref_form.current.collect_carrier, "o el valor del mensajero", "warning");
                ref_form.current.collect_product.focus();
                window.scrollTo({
                    top: 480,
                    behavior: 'smooth'
                });
                return;
            }

            if (!payForBts && (collectDomic.length < 1 || parseInt(collectDomic) < 0)) {
                alerta(ref_form.current.collect_domic, "Debes indicar el valor del domicilio", "warning");
                ref_form.current.collect_domic.focus();
                window.scrollTo({
                    top: 500,
                    behavior: 'smooth'
                });
                return;
            }

            if (collectCarrier.length < 1 || parseInt(collectCarrier) < 0) {
                alerta(ref_form.current.collect_carrier, "Debes indicar el valor del mensajero", "warning");
                ref_form.current.collect_carrier.focus();
                window.scrollTo({
                    top: 500,
                    behavior: 'smooth'
                });
                return;
            }
        }

        if (data_user.nivel == 4 && Object.entries(packages).length < 1) {
            alertDown(ref_form.current.other, "Debes agreag por lo menos un paquete!");
            return;
        }

        if (Object.entries(packages).length >= 1) {

            for (let i in packages) {

                if (packages[i]["pieces"] < 1) {
                    _is_empty = true;
                    _message = `La cantidad de "piezas" no puede ser menor a uno!`;
                    break
                }

                if (packages[i]["attachments"].length < 1) {
                    _is_empty = true;
                    _message = `Debes ingresar al menos una imagen para cada paquete`;
                    break
                }
            }

        }

        if (_is_empty) {
            Alert(_message);
            return;
        }

        if (data_user.nivel == 4 && data.estado == 1) {
            Confirm("¿Marcar esta orden como recogida?", (resp) => {
                if (resp) {
                    saveOrder(2);
                } else {
                    saveOrder(1);
                }
            });
        } else {
            saveOrder(data.estado);
        }
    }

    const addCarrier = async (val) => {
        setCarrier(val);
        alertaRemove(ref_form.current.carrier);

        let lista = await getCarrier();

        let item = lista.filter(({ nombre, apellido }) => nombre + " " + apellido === val);
        item = item[0];

        if (typeof item === "object") {
            setCarrierId(item.id_user);
        } else {
            setCarrierId("");
        }
    }

    const addConsignee = async (val) => {
        setConsignee(val);
        alertaRemove(ref_form.current.consignee);

        let lista = await getClients();

        let item = lista.filter(({ nombre, apellido }) => nombre + " " + apellido === val);
        item = item[0];

        if (typeof item === "object") {
            let _info = JSON.parse(item.info);
            setConsigneeId(item.id_user);
            setDomicilioCliente(_info?.company?.domicilio?.cliente ?? DEFAULT_DOMICILIO_CLIENTE);
            setDomicilioMensajero(_info?.company?.domicilio?.mensajero ?? DEFAULT_DOMICILIO_MENSAJERO);
        } else {
            setConsigneeId("");
            setDomicilioCliente(DEFAULT_DOMICILIO_CLIENTE);
            setDomicilioMensajero(DEFAULT_DOMICILIO_MENSAJERO);
        }
    }

    const copyConsignee = () => {

        if (consignee.length > 1) {
            let item = clientsList.filter(({ nombre, apellido }) => nombre + " " + apellido === consignee);
            item = item[0];

            alertaRemove(ref_form.current.name_pickup_location);

            if (typeof item === "object") {
                setNamePickupLocation(item.nombre + " " + item.apellido);
                setAddressPickupLocation(item.address);
            } else {
                setNamePickupLocation("");
                setAddressPickupLocation("");
            }
        }
    }

    const changeCarrier = (val) => {
        setCarrier(val);
        alertaRemove(ref_form.current.carrier);

        let item = carrierList.filter(({ nombre, apellido }) => nombre + " " + apellido === val);
        item = item[0];

        if (typeof item === "object") {
            setCarrierId(item.id_user);
        } else {
            setCarrierId("");
        }
    }

    const changeConsignee = (val) => {
        setConsignee(val);
        alertaRemove(ref_form.current.consignee);

        let item = clientsList.filter(({ nombre, apellido }) => nombre + " " + apellido === val);
        item = item[0];

        if (typeof item === "object") {
            let _info = JSON.parse(item.info);
            setConsigneeId(item.id_user);
            setDomicilioCliente(_info?.company?.domicilio?.cliente ?? DEFAULT_DOMICILIO_CLIENTE);
            setDomicilioMensajero(_info?.company?.domicilio?.mensajero ?? DEFAULT_DOMICILIO_MENSAJERO);
        } else {
            setConsigneeId("");
            setDomicilioCliente(DEFAULT_DOMICILIO_CLIENTE);
            setDomicilioMensajero(DEFAULT_DOMICILIO_MENSAJERO);
        }
    }

    React.useEffect(() => {
        let isMounted = true;
        setTitulo(_titulo + " " + data.consecutivo);
        document.body.style.overflow = "auto";

        getPackagesList(data.id);

        setNotes(data.nota == null ? "" : data.nota);

        setDomicilioCliente(data.consignee_domicilio ?? DEFAULT_DOMICILIO_CLIENTE);
        setDomicilioMensajero(data.carrier_domicilio ?? DEFAULT_DOMICILIO_MENSAJERO);

        setConsignee(data.consignee_name == null ? "" : data.consignee_name);
        setConsigneeId(data.consignee == null ? "" : data.consignee);

        setNamePickupLocation(data.recogida.contacto);
        setAddressPickupLocation(data.recogida.direccion ?? "...");
        setFechaIngreso(new Date(data.recogida.fecha + "T01:00:00"));

        if (data.recogida.valor_producto || data.recogida.valor_domicilio || data.recogida.valor_mensajero) {
            setCollect(true);
            data.recogida.valor_producto && setCollectProduct(funciones.formatPrice(data.recogida.valor_producto).format);
            data.recogida.valor_domicilio && setCollectDomic(funciones.formatPrice(data.recogida.valor_domicilio).format);
            data.recogida.valor_mensajero && setCollectCarrier(funciones.formatPrice(data.recogida.valor_mensajero).format);

            setPayForBts(data.recogida.paga_bts == 0 ? false : true);
        }

        if (isMounted) {
            if (data_user.nivel != 4) {
                getClients();
                getCarrier();
            } else {
                setCarrierId(parseInt(data.recogida.carrier));
            }
        }

        return () => { isMounted = false };
    }, [data]);

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaGrupo(["pack_edicion", "pack_recogidas"]) && ![6, 7].includes(parseInt(data_user.nivel))}>

            <form className="pickup" ref={ref_form} onSubmit={e => e.preventDefault()}>
                <TitleSticky>{_titulo}</TitleSticky>

                <div className="pickup_container" style={{ marginBottom: allPaquages.length >= 1 ? 28 : 110 }}>
                    <h5>Número: <b>{data.consecutivo}</b></h5>

                    {data_user.nivel == 4 ?
                        <>
                            <h6 className="_title"> <span style={{ fontWeight: "normal" }}>Cliente bts: </span>{consignee}</h6>

                            <div className={consignee === "" ? "pickup_row" : "pickup_row _to_bottom"}>
                                <div className="pickup_row">

                                    <div className="_column">
                                        <label>Fecha de recogida</label>
                                        <label className="_marco">{format(fechaIngreso.getDate())}/{meses[fechaIngreso.getMonth()]}/{fechaIngreso.getFullYear()}</label>
                                    </div>

                                    <div className="_column">
                                        <label>Nombre de contacto</label>
                                        <label className="_marco">{namePickupLocation}</label>
                                    </div>

                                    <div className="_column">
                                        <label>Dirección de recogida</label>
                                        <label className="_marco">{addressPickupLocation}</label>
                                    </div>
                                </div>
                            </div>

                            <h6 style={{ marginTop: 16, marginBottom: 64 }}><b>Observaciones</b> <br />{data.nota}</h6>
                        </>
                        :
                        <div className={consignee === "" ? "pickup_row" : "pickup_row _to_bottom"}>

                            <div className="pickup_column static">
                                <Field
                                    rol="6"
                                    name="consignee"
                                    titulo="Cliente bts"
                                    list={clientsList}
                                    placeholder="Ingresa Cliente bts"
                                    value={consignee}
                                    onChange={changeConsignee}
                                    onAdd={addConsignee}
                                />

                                {consignee != "" &&
                                    <div className="pickup_column m_less">
                                        <h3 className="text_clipboard">Lugar de recogida <ClipboardIcon onClick={copyConsignee} /></h3>

                                        <label style={{ height: 24 }}>Nombre de contacto</label>
                                        <div className="input">
                                            <input type="text" name="name_pickup_location" autoComplete="off" placeholder="Ingresa nombre de contacto"
                                                value={namePickupLocation} onChange={e => setNamePickupLocation(e.target.value)} />
                                        </div>

                                        <label style={{ marginTop: 12 }}>Dirección</label>
                                        <textarea name="address_pickup_location" rows="2" placeholder="Ingresa la dirección de recogida."
                                            value={addressPickupLocation} onChange={e => setAddressPickupLocation(e.target.value)} />
                                    </div>}
                            </div>

                            <div className="pickup_column static">
                                <Field
                                    rol="4"
                                    name="carrier"
                                    titulo="Mensajero"
                                    list={carrierList}
                                    placeholder="Ingresa el mensajero"
                                    value={carrier}
                                    onChange={changeCarrier}
                                    onAdd={addCarrier}
                                />

                                <hr style={{ height: 30, backgroundColor: "transparent" }} />

                                <div className="pickup_column m_less">
                                    <label style={{ height: 24 }}>Fecha de recogida</label>

                                    <div className="field_icon">
                                        <DatePicker dateFormat="yyyy/MM/dd" name="pickup_date" selected={fechaIngreso} onChange={(date) => setFechaIngreso(date)} />
                                        <CalendarMonthIcon />
                                    </div>
                                </div>

                                <div className="pickup_column m_less">
                                    <label style={{ marginTop: 12 }}>Observaciones</label>
                                    <textarea name="notes" rows="2" placeholder="Ingresa una observación"
                                        value={notes} onChange={({ target: { value } }) => setNotes(value)} />
                                </div>

                            </div>

                        </div>
                    }

                    {data_user.nivel != 4 && <fieldset className={collect ? "new_features" : "new_features _no_border"} style={{ marginTop: 32 }}>
                        <label className="check_collect _legend">
                            <input type="checkbox" name="collect" checked={collect} onChange={e => {
                                if (e.target.checked) {
                                    setCollectProduct("");
                                    setCollectDomic("");
                                    setCollectCarrier("");
                                }
                                setCollect(e.target.checked);
                            }} />
                            Pago por recogida
                        </label>

                        {collect && <>
                            <label className="label_collect">Valor producto</label>
                            <InputNum
                                name="collect_product"
                                value={collectProduct}
                                placeholder="Valor producto"
                                onChange={val => {
                                    alertaRemove(ref_form.current.collect_product);
                                    alertaRemove(ref_form.current.collect_domic);
                                    alertaRemove(ref_form.current.collect_carrier);
                                    setCollectProduct(val);
                                }} />

                            <label className="label_collect">Valor domicilio</label>
                            <InputNum
                                name="collect_domic"
                                value={collectDomic}
                                disabled={payForBts}
                                placeholder="Valor domicilio"
                                onChange={val => {
                                    alertaRemove(ref_form.current.collect_product);
                                    alertaRemove(ref_form.current.collect_domic);
                                    alertaRemove(ref_form.current.collect_carrier);
                                    setCollectDomic(val);
                                }} />

                            <label className="label_collect">Valor mensajero</label>
                            <InputNum
                                name="collect_carrier"
                                value={collectCarrier}
                                placeholder="Valor mensajero"
                                onChange={val => {
                                    alertaRemove(ref_form.current.collect_product);
                                    alertaRemove(ref_form.current.collect_domic);
                                    alertaRemove(ref_form.current.collect_carrier);
                                    setCollectCarrier(val);
                                }} />
                            <label className="check_collect">
                                <input type="checkbox" name="pay_for_bts" checked={payForBts} onChange={e => {
                                    alertaRemove(ref_form.current.collect_product);
                                    alertaRemove(ref_form.current.collect_domic);
                                    alertaRemove(ref_form.current.collect_carrier);
                                    if (e.target.checked) {
                                        setCollectDomic("");
                                    }
                                    setPayForBts(e.target.checked);
                                }} />
                                Pagado por BTS
                            </label>
                        </>}
                    </fieldset>}

                    {allPaquages}
                </div>

                <div className="boton_agregar">
                    <div className="for_alert">
                        <button className={consigneeId !== "" ? "btn btn-common" : "btn btn-common _deshabilitado"}
                            type="button" name="other"
                            onClick={addPackage}>
                            <PlusCircleOutlineIcon /> Agregar paquete
                        </button>
                    </div>

                    <button type="button" className="btn btn-common width-static" onClick={validateFields}>Guardar</button>
                    <button type="button" className="btn btn-common width-static" onClick={() => navigate(-1)}>Cancelar</button>

                </div>

                {showLoading && <Loading />}
            </form >
        </RequirePermissions>
    )
}

export default withRouter(EditPickUp);