import * as React from "react";
import { RequirePermissions } from "shared/AlertMessages";
import { TitleSticky } from "shared/Components";
import withRouter from "services/withRouter";
import funciones from "services/funciones";
import ToolBar from "shared/tool_bar";
import { Alert } from "shared/Alert";
import Menu from "./components/Menu";
import { Api } from "services/api";
import { useMedia } from "hooks";
import * as Icon from "images";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const MisDevolicionesEntregadas = (router) => {
    const { navigate } = router;
    const { media_movil, setTitulo, setDataUser, currentQuery, setCurrentQuery } = useMedia();
    const [loadResult, setLoadResult] = React.useState("Cargando devoluciones...");
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [showLoading, setShowLoading] = React.useState(false);
    const [selected, setSelected] = React.useState({ id: 0 });
    const [showLazy, setShowLazy] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);


    const Row = ({ data, blue }) => {
        let date_return = new Date(data.date_return);

        return (
            <Menu
                inRow={true}
                data={data}
                blue={blue}
                navigate={navigate}
                current={selected.id}
                onSel={setSelected}
                reload={() => getList(currentQuery.dev_entregadas)}>

                {media_movil ?
                    <td style={{ maxWidth: "calc(100vw - 32px)", paddingBottom: 0 }}>
                        <div className="_states">
                            {funciones.getStateSpan(data)}
                            <b>{data.codigo}</b>
                        </div>

                        <p className="_states">{data.nota}</p>
                        <i className="_states">Devuelto: {format(date_return.getDate())}/{meses[date_return.getMonth()]}/{date_return.getFullYear()} - {format(date_return.getHours())}:{format(date_return.getMinutes())}</i>
                    </td>
                    :
                    <>
                        {funciones.getStateTd(data)}
                        <td>{data.codigo}</td>
                        {!media_movil && <td>{data.nota}</td>}
                        <td className="_to_right">{format(date_return.getDate())}/{meses[date_return.getMonth()]}/{date_return.getFullYear()} - {format(date_return.getHours())}:{format(date_return.getMinutes())}</td>
                    </>}

            </Menu>
        );
    }

    const AllRow = () => {
        let _data = dataList.map((item, index) => {
            return <Row key={item.id} blue={index % 2} data={item} />;
        });

        if (_data.length < 1) {
            return <h4 className="_titulo">{loadResult}</h4>;
        } else {
            return <>
                <table className="table">
                    <tbody>

                        {media_movil ?
                            <tr className="table_title">
                            </tr>
                            :
                            <tr className="table_title">
                                <th>Estado</th>
                                <th>Código</th>
                                <th>Nota devolución</th>
                                <th className="_to_right">Fecha devuelto</th>
                            </tr>
                        }

                        {_data}
                    </tbody>
                </table>

                {showLoading ?
                    <button className="btn_lazy_load"><Icon.Loading /></button>
                    :
                    <>
                        {showLazy ?
                            <button className="btn_lazy_load" onClick={() => getListLazy(currentQuery.dev_entregadas)}>Cargar más</button>
                            :
                            <h4 className="h4_lazy_load">{loadResult}</h4>
                        }
                    </>
                }
            </>;
        }
    }

    const getListLazy = async (_filter) => {
        setLoadResult("Cargando devoluciones...");
        setShowLoading(true);

        let _data = await api.current.fetchJson({
            url: "get-returns",
            data: { ..._filter, task: "dev_entregadas", offset: dataList.length }
        });

        if (_data.response == 1) {
            let _packs = [];

            dataList.map(item => {
                _packs.push(item);
            });

            _data.data.map(item => {
                _packs.push(item);
            });

            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_packs);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        if (_filter?.search == 1) {
            let _msg = _filter.estadoName ? " " + _filter.estadoName : "";
            setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No tienes devoluciones" + _msg + "!" : "¡No hay más devoluciones" + _msg + "!");
        } else {
            setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No tienes devoluciones!" : "¡No hay más devoluciones!");
        }
        setShowLoading(false);
    }

    const getList = async (_filter) => {
        setLoadResult("Cargando devoluciones...");
        setShowLoading(true);

        let _data = await api.current.fetchJson({
            url: "get-returns",
            data: { ..._filter, task: "dev_entregadas", offset: 0 }
        });

        if (_data.response == 1) {
            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_data.data);
            setCurrentQuery(v => ({ ...v, dev_entregadas: { ..._filter } }));
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        if (_filter?.search == 1) {
            let _msg = _filter.estadoName ? " " + _filter.estadoName : "";
            setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No tienes devoluciones" + _msg + "!" : "¡No hay más devoluciones" + _msg + "!");
        } else {
            setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No tienes devoluciones!" : "¡No hay más devoluciones!");
        }

        setShowLoading(false);
    }

    // Historico de devoluciones del cliente
    React.useEffect(() => {
        setTitulo("Devoluciones entregadas");
        document.body.style.overflow = "auto";
    }, []);

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("company_dev_entregadas")}>

            <div className="cont_principal">
                <TitleSticky className="_in_filters">Devoluciones entregadas</TitleSticky>
                <ToolBar {...router}
                    type="dev_entregadas"
                    showLoading={showLoading}
                    reload={getList}
                    currentFilterOptions={currentQuery.dev_entregadas}
                    filter={{
                        showStatus: true,
                        statusList: [
                            "Todos",
                            "OK.DV",
                            "KO.DOM",
                            "KO.DOM.PAGO",
                            "KO.CANCELADO",
                            "KO.REPROGRAMADO"
                        ]
                    }} />

                <AllRow />
            </div>
        </RequirePermissions>
    )
}

export default withRouter(MisDevolicionesEntregadas);