import * as React from "react";
import ChartTimelineIcon from "mdi-react/ChartTimelineIcon";
import AutorenewIcon from "mdi-react/AutorenewIcon";
import { Card, CardBody, Col } from "reactstrap";
import funciones from "services/funciones";
import { useNavigate } from "react-router";
import { Alert } from "shared/Alert";
import { Api } from "services/api";
import * as Icon from "images";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const Cortes = ({ data_user }) => {
    const navigate = useNavigate();
    const api = React.useRef(new Api());
    const [showLoading, setShowLoading] = React.useState(true);
    const [showLazy, setShowLazy] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);
    const limit = 10;

    const handlerRefresh = (e) => {
        e.preventDefault();
        if (data_user) {
            getList();
        }
    }

    const getListLazy = async () => {
        setShowLoading(true);

        let _data = await api.current.fetchJson({
            url: "cortes",
            data: {
                propietario: data_user.id_user,
                limit,
                offset: dataList.length
            }
        });

        if (_data.response == 1) {
            let _cortes = [];

            dataList.forEach(item => {
                _cortes.push(item);
            });

            _data.data.forEach(item => {
                _cortes.push(item);
            });

            setDataList(_cortes);
            setShowLazy(_data.data.length < limit ? false : true);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getList = async () => {
        setShowLoading(true);

        let _data = await api.current.fetchJson({
            url: "cortes",
            data: {
                propietario: data_user.id_user,
                limit,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
            setShowLazy(_data.data.length < limit ? false : true);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const History = () => {
        if (data_user) {
            switch (parseInt(data_user.nivel)) {
                case 1:
                case 2:
                case 3:
                    return <div className="dashboard__sales-table">
                        <h4 style={{ color: "#32c800" }}>No hay cortes en el historial</h4>
                    </div>;
                case 4:
                    return <>
                        <div className="dashboard__sales-table">
                            {dataList.length < 1 ?
                                <h4 style={{ color: "#32c800" }}>No hay cortes en el historial</h4>
                                :
                                <table className="table">
                                    <tbody>

                                        <tr className="table_title">
                                            <th>Fecha Corte</th>
                                            <th>Cantidad</th>
                                            <th>Total</th>
                                            <th>Ganancia</th>
                                            <th>Subtotal</th>
                                            <th>Devuelto a bodega</th>
                                        </tr>

                                        {dataList.map((item, index) => {
                                            let fecha_creado = new Date(item.fecha);

                                            return <tr className="_tr_row" key={index} onClick={() => navigate("/dashboard/reporte-historial", { state: { propietario: { id_user: data_user.id_user, nivel: data_user.nivel, fecha: item.fecha, nombre: data_user.nombre + " " + data_user.apellido } } })}>
                                                <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()} {format(fecha_creado.getHours())}:{format(fecha_creado.getMinutes())}</td>
                                                <td>{funciones.formatPrice(item.cantidad_entregada).format}</td>
                                                <td>{funciones.formatPrice(item.valor_total).format}</td>
                                                <td>{funciones.formatPrice(item.domicilios).format}</td>
                                                <td>{funciones.formatPrice(item.valor_subtotal).format}</td>
                                                <td>{funciones.formatPrice(item.candidad_devuelta).format}</td>
                                            </tr>
                                        })}

                                        <tr className="_no_margin">
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            }
                        </div>
                        {dataList.length > 0 && <>
                            {showLoading ?
                                <button className="btn_lazy_load"><Icon.Loading /></button>
                                :
                                <>
                                    {showLazy ?
                                        <button className="btn_lazy_load" onClick={() => getListLazy()}>Cargar más</button>
                                        :
                                        <h4 className="h4_lazy_load _margin_top">No hay más cortes</h4>
                                    }
                                </>
                            }
                        </>}
                    </>;
                case 6:
                    return <>
                        <div className="dashboard__sales-table">
                            {dataList.length < 1 ?
                                <h4 style={{ color: "#32c800" }}>No hay cortes en el historial</h4>
                                :
                                <table className="table">
                                    <tbody>

                                        <tr className="table_title">
                                            <th>Fecha Corte</th>
                                            <th>Cantidad</th>
                                            <th>Total</th>
                                            <th>Domicilios</th>
                                            <th>Subtotal</th>
                                            <th>Efectivo</th>
                                            <th>Transferencia</th>
                                            <th>Devuelto</th>
                                        </tr>

                                        {dataList.map((item, index) => {
                                            let fecha_creado = new Date(item.fecha);

                                            return <tr className="_tr_row" key={index} onClick={() => navigate("/dashboard/reporte-historial", { state: { propietario: { id_user: data_user.id_user, nivel: data_user.nivel, fecha: item.fecha, nombre: data_user.nombre + " " + data_user.apellido } } })}>
                                                <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()} {format(fecha_creado.getHours())}:{format(fecha_creado.getMinutes())}</td>
                                                <td>{funciones.formatPrice(item.cantidad_entregada).format}</td>
                                                <td>{funciones.formatPrice(item.valor_total).format}</td>
                                                <td>{funciones.formatPrice(item.domicilios).format}</td>
                                                <td>{funciones.formatPrice(item.valor_subtotal).format}</td>
                                                <td>{funciones.formatPrice(item.efectivo || 0).format}</td>
                                                <td>{funciones.formatPrice(item.transferencia || 0).format}</td>
                                                <td>{funciones.formatPrice(item.candidad_devuelta).format}</td>
                                            </tr>;
                                        })}

                                        <tr className="_no_margin">
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            }
                        </div>
                        {dataList.length > 0 && <>
                            {showLoading ?
                                <button className="btn_lazy_load"><Icon.Loading /></button>
                                :
                                <>
                                    {showLazy ?
                                        <button className="btn_lazy_load" onClick={() => getListLazy()}>Cargar más</button>
                                        :
                                        <h4 className="h4_lazy_load _margin_top">No hay más cortes</h4>
                                    }
                                </>
                            }
                        </>}
                    </>;
                default:
                    return <> </>;
            }
        } else {
            return <div className="dashboard__sales-table">
                <h4>Seleciona un vendedor o mensajero.</h4>
            </div>;
        }
    }

    React.useEffect(() => {
        if (data_user) {
            getList();
        } else {
            setShowLoading(false);
        }
    }, [data_user]);

    return (
        <div className="content_reportes">
            <Col className="col_table">
                <Card style={{ overflow: "hidden" }}>
                    <CardBody className="dashboard__card-widget">
                        <div className="card__title">
                            <h5 className="bold-text" style={{ color: "#32c800" }}><ChartTimelineIcon className="dashboard__money-icon" /> Historial de cortes</h5>
                        </div>

                        <div className="panel__btns">

                            <button
                                className="panel__btn"
                                aria-label="panel__btn"
                                type="button"
                                onClick={handlerRefresh}>
                                <AutorenewIcon className={showLoading ? "on_refresh" : ""} />
                            </button>

                        </div>

                        <History />
                    </CardBody>
                </Card>
            </Col>
        </div>
    );
}

export default Cortes;