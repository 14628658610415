import * as React from "react";
import { ContextMedia } from "../Contexts";

export function useMedia() {
    const {
        media_movil,
        data_user,
        setDataUser,
        setTitulo,
        currentQuery,
        setCurrentQuery,
        modalView,
        setModalView,
        clientsList,
        setClientsList
    } = React.useContext(ContextMedia);

    return {
        media_movil,
        data_user,
        setDataUser,
        setTitulo,
        currentQuery,
        setCurrentQuery,
        modalView,
        setModalView,
        clientsList,
        setClientsList
    };
}