import * as React from "react";
import { Alert, alerta, alertaRemove } from "shared/Alert";
import { RequirePermissions } from "shared/AlertMessages";
import { TitleSticky } from "shared/Components";
import { useNavigate } from "react-router-dom";
import withRouter from "services/withRouter";
import EditContainer from "./EditContainer";
import funciones from "services/funciones";
import Loading from "shared/Loading";
import { Api } from "services/api";
import { useMedia } from "hooks";

/* eslint eqeqeq: 0 */

const EditPackage = ({ location }) => {
    const data = location.state.data;
    const navigate = useNavigate();
    const { setTitulo, data_user, setDataUser } = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [showLoading, setShowLoading] = React.useState(true);
    const [allPaquages, setAllPaquages] = React.useState([]);
    const [packages, setPackages] = React.useState({});
    const [permission, setPermission] = React.useState(false);
    const formRef = React.useRef();


    const configPackages = async (_data) => {
        _data = _data[0]
        _data.extra = JSON.parse(_data.extra);
        let _pack = {
            "index": 0,
            "id": _data.id,
            "valorp": _data.price,
            "valord": data_user.info.company.domicilio.cliente,
            "valorm": data_user.info.company.domicilio.mensajero,
            "devolucion": _data.devolucion ?? "",
            "tiene_dev": _data.devolucion ? true : false,
            "descripcion": _data.descripcion,
            "estado": _data.estado,
            "estado_real": _data.estado,

            "destinatario": _data.extra.destinatario,
            "telefono": _data.extra.telefono,
            "direccion": _data.extra.direccion,
            "localidad": _data.extra.localidad,
            "ciudad": _data.extra.ciudad,
            "volumen": _data.extra.volumen,
            "peso": _data.extra.peso,
        };

        setShowLoading(false);
        setPackages({ 0: _pack });
        setAllPaquages(<EditContainer
            key={1}
            item={_pack}
            remove={removePackage}
            update={p => {
                alertaRemove(formRef.current.destinatario);
                alertaRemove(formRef.current.telefono);
                alertaRemove(formRef.current.direccion);
                alertaRemove(formRef.current.tipo_devolucion);
                alertaRemove(formRef.current.valorp);
                alertaRemove(formRef.current.valord);
                alertaRemove(formRef.current.valorm);
                setPackages({ 0: p });
            }} />);
    }

    const saveOrder = async () => {
        setShowLoading(true);

        let _data = await api.current.fetchJson({
            url: "update-package-tienda",
            data: {
                tienda: data_user.info.company.id,
                ...packages[0]
            }
        });

        if (_data.response == 1) {
            if ([6, 61].includes(parseInt(data_user.nivel))) {
                navigate("/dashboard/tienda/rotulo", { state: { data: { ..._data.package, info_tienda: data_user.info } } });
            } else {
                Alert(_data.msg, "ok", () => navigate(-1));
            }
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validateFields = (e) => {
        e.preventDefault();
        let _is_empty = false;
        let _message = "";

        alertaRemove(formRef.current.destinatario);
        alertaRemove(formRef.current.telefono);
        alertaRemove(formRef.current.direccion);
        alertaRemove(formRef.current.tipo_devolucion);
        alertaRemove(formRef.current.valorp);
        alertaRemove(formRef.current.valord);
        alertaRemove(formRef.current.valorm);


        if (typeof packages !== "object" || Object.entries(packages).length < 1) {
            Alert("Debes agregar al menos un paquete!");
            return;
        }

        for (let i in packages) {
            if (parseInt(packages[i]["estado"]) < 1) {
                _is_empty = true;
                _message = `¡Debes seleccionar un estado!`;
                break
            }

            if (packages[i]["destinatario"] == null || packages[i]["destinatario"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar el nombre del destinatario!`;
                alerta(formRef.current.destinatario, _message);
                formRef.current.destinatario.focus();
                return;
            }

            if (packages[i]["telefono"] == null || packages[i]["telefono"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar el teléfono del destinatario!`;
                alerta(formRef.current.telefono, _message);
                formRef.current.telefono.focus();
                return;
            }

            if (packages[i]["direccion"] == null || packages[i]["direccion"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar la dirección del destinatario!`;
                alerta(formRef.current.direccion, _message);
                formRef.current.direccion.focus();
                return;
            }

            if (packages[i].tiene_dev) {
                if (packages[i].devolucion.length < 1) {
                    _is_empty = true;
                    _message = `Debes indicar el tipo de devolución`;
                    alerta(formRef.current.tipo_devolucion, _message);
                    formRef.current.tipo_devolucion.focus();
                    return;
                }
            } else {
                packages[i].devolucion = "";
            }

            if (packages[i]["valorp"] == null) {
                _is_empty = true;
                _message = `¡No hay cambios para guardar!`;
                break
            }

            if (packages[i]["valorp"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar el valor del producto!`;
                alerta(formRef.current.valorp, _message);
                formRef.current.valorp.focus();
                return;
            }

            if (packages[i]["valord"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar el valor del domicilio!`;
                alerta(formRef.current.valord, _message);
                formRef.current.valord.focus();
                return;
            }

            if (packages[i]["valorm"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar el valor del mensajero!`;
                alerta(formRef.current.valorm, _message);
                formRef.current.valorm.focus();
                return;
            }
        }

        if (_is_empty) {
            Alert(_message);
            return;
        }

        saveOrder();
    }

    const removePackage = (_index, _id) => {
        navigate(-1);
    }

    React.useEffect(() => {
        setTitulo("Editar paquete " + data.codigo);
        document.body.style.overflow = "auto";
        let _permission = [6, 61].includes(parseInt(data_user.nivel)) ?
            funciones.validaPermisos("company_gestion_paquete") :
            funciones.validaPermisos("recolectas_edita");

        if (_permission) {
            setPermission(_permission);

            if (location.state) {
                configPackages([{ ...data, index: data.codigo }]);
            } else {
                Alert("¡No se pudo cargar la información debido a un mal ingreso!",
                    "warning",
                    () => navigate("/dashboard/mis-recolectas"));
                setShowLoading(false);
            }
        }
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <RequirePermissions navigate={navigate} permission={permission}>

            <form className="pickup" ref={formRef} onSubmit={e => e.preventDefault()}>
                <TitleSticky>Editar paquete {data.codigo}</TitleSticky>

                <div className="pickup_container" style={{ marginBottom: 28 }}>
                    {allPaquages}
                </div>

                <div className="boton_agregar">
                    <button type="button" className="btn btn-common width-static" onClick={validateFields}>Guardar</button>
                    <button type="button" className="btn btn-common width-static" onClick={() => navigate(-1)}>Cancelar</button>
                </div>

                {showLoading && <Loading />}
            </form>
        </RequirePermissions>
    )
}

export default withRouter(EditPackage);