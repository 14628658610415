import * as React from "react";
import { useMedia } from "hooks";
import { Api } from "services/api";
import { useNavigate } from "react-router-dom";
import MailIcon from "mdi-react/MailOutlineIcon";
import { Alert, alerta, alertaRemove } from "shared/Alert";

/* eslint eqeqeq: 0 */
/* eslint no-useless-escape: 0 */

const FortgotForm = () => {
  const [email, setEmail] = React.useState(null);
  const inputMail = React.useRef(null);
  const navigate = useNavigate();
  const { setDataUser } = useMedia();
  const api = React.useRef(new Api({ navigate, setDataUser }));


  const validateFormatEmail = () => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regex.test(String(email).toLowerCase())) {
      alerta(inputMail, "¡El formato del correo es inválido!");

      return false;
    } else {
      return true;
    }
  }

  const validaMail = () => {
    alertaRemove(inputMail);

    if (email == null || email.length < 1) {
      alerta(inputMail, "¡El correo no puede estar vacío!");

      return false;
    } else {
      return true;
    }
  }

  const validate = async () => {
    if (validaMail() && validateFormatEmail()) {
      let _data = await api.current.resetPassword(email);

      if (_data.response == "1") {
        Alert(_data.msg, "ok", () => navigate(-1));
      } else {
        Alert(_data.msg, "warning");
      }
    }
  }

  return (
    <form className="form">

      <div className="form__form-group">
        <span className="form__form-group-label">Correo</span>
        <div className="form__form-group-field input_radius">
          <div className="form__form-group-icon">
            <MailIcon />
          </div>
          <input type="email" name="email" ref={inputMail} placeholder="Correo" className="radius_right"
            onChange={({ target: { value } }) => setEmail(value)} />
        </div>
      </div>


      <button type="button" className="btn btn-primary account__btn account__btn-login" onClick={validate}>Enviar</button>
    </form>
  );
};

export default FortgotForm;
