import * as React from "react";
import { useNavigate } from 'react-router-dom';
import { MovePopUp } from "shared/MovePopUp";
import { Alert } from "shared/Alert";
import { Api } from "services/api";
import * as Icon from "images";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const PackageList = (params) => {
    const { data, close, reload } = params;
    const navigate = useNavigate();
    const api = React.useRef(new Api());
    const [txtMensaje, setTxtMensaje] = React.useState("Cargando...");
    const [showLoading, setShowLoading] = React.useState(true);
    const [showLazy, setShowLazy] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);


    const callPackageLazy = async () => {
        setShowLoading(true);

        let _data = await api.current.fetchJson({
            url: "get-all-packages",
            data: {
                id_carrier: data.id_user,
                toassign: "toassign",
                offset: dataList.length
            }
        });

        if (_data.response == 1) {
            let _packs = [];

            dataList.map(item => {
                _packs.push(item);
            });

            _data.data.map((item, index) => {
                item.index = index;

                if (item.carrier == data.id_user) {
                    item.select = true;
                } else {
                    item.select = false;
                }

                _packs.push(item);
            });

            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_packs);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setTxtMensaje("¡No hay entregas!");
        setShowLoading(false);
    }

    const callPackages = async () => {
        let _data = await api.current.fetchJson({
            url: "get-all-packages",
            data: { id_carrier: data.id_user, toassign: "toassign", offset: 0 }
        });

        if (_data.response == 1) {

            let _lista = _data.data.map((item, index) => {
                item.index = index;

                if (item.carrier == data.id_user) {
                    item.select = true;
                } else {
                    item.select = false;
                }

                return item;
            });

            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_lista);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setTxtMensaje("¡No hay entregas!");
        setShowLoading(false);
    }

    const saveAssign = async (_to_assign, _to_clean) => {
        setShowLoading(true);
        let _data = await api.current.saveAssignPackages({
            id_carrier: data.id_user,
            list_assign: _to_assign,
            list_clean: _to_clean
        });

        if (_data.response == 1) {
            document.body.style.overflow = "auto";
            reload();
            close();
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const AllRow = () => {
        if (dataList.length < 1) {
            return <h3 key="01">{txtMensaje}</h3>;
        } else {
            return <>
                {dataList.map(item => {
                    return <div key={item.id}>
                        <p style={{ marginTop: 6, marginBottom: 8 }} onClick={() => setDataList(_d => {
                            let _lista = _d.filter(_i => {
                                if (item.id === _i.id) {
                                    _i.select = !_i.select;
                                }
                                return _i;
                            });

                            return _lista;
                        })}>
                            <input type="checkbox" checked={item.select} readOnly />
                            <i>{item.codigo}</i> <b>{item.client_name}</b>
                        </p>
                        {/* <p style={{ marginLeft: 22, marginTop: 0, marginBottom: 12 }}>&nbsp;{item.pickup_address}</p> */}
                    </div>;
                })}

                {showLoading ?
                    <button className="btn_lazy_load"><Icon.Loading /></button>
                    :
                    <>
                        {showLazy ?
                            <button className="btn_lazy_load" onClick={() => callPackageLazy()}>Cargar más</button>
                            :
                            <h4 className="h4_lazy_load">¡No hay más paquetes!</h4>
                        }
                    </>
                }
            </>;
        }

    }

    const validate = () => {
        let _lista = "";
        let _to_clean = "";

        dataList.map(_pack => {

            if (_pack.select) {

                _lista += _pack.id + ",";
            } else if (_pack.carrier == data.id_user) {
                _to_clean += _pack.id + ","
            }

        });

        if (_lista.length < 1) {
            Alert("Debes agregar al menos un paquete", "warning");
            return;
        }

        saveAssign(_lista, _to_clean);
    }

    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close();
    }

    React.useEffect(() => {
        callPackages();
    }, []);

    return (
        <MovePopUp
            parent={data.parent}
            close={handleClose}
            className="win_modal"
            title="Entregas sin asignar">

            <div className="_scroll">
                <AllRow />
            </div>
            <hr className="_line" />

            <div className="_in_row">
                {showLoading ?
                    <button type="button" className="btn btn-common width-static"><Icon.Loading /></button>
                    :
                    <button type="button" className="btn btn-common width-static" onClick={validate}>Asignar</button>
                }
            </div>
        </MovePopUp>
    )
}

export default PackageList;