import * as React from "react";
import { FileUploadClip, ViewImage } from "shared/FileUpload";
import ArrowBack from "mdi-react/ArrowBackIcon";
import UserIcon from "mdi-react/AccountTieIcon";
import LoadingIcon from "mdi-react/LoadingIcon";
import { useNavigate } from "react-router-dom";
import funciones from "services/funciones";
import SendIcon from "mdi-react/SendIcon";
import * as Iconos from "../../../images"
import { Alert } from "shared/Alert";
import { Api } from "services/api";
import { useMedia } from "hooks";
import log from "services/log";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const VistaChat = (params) => {
    const { guest } = params;
    const navigate = useNavigate();
    const {data_user, setDataUser} = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [placeholder, setPlaceholder] = React.useState("No hay conexión al servidor");
    const [websocket, setWebsocket] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [chatList, setChatList] = React.useState([]);
    const [mensaje, setMensaje] = React.useState("");
    const [allMsg, setAllMsg] = React.useState([]);
    const listRef = React.useRef(null);


    const Bocadillo = ({ data }) => {

        return (
            <div className={data.owner == window.atob(data_user.auth) ? "contain_right" : "contain_left"}>
                <div className={data.owner == window.atob(data_user.auth) ? "globo derecha" : "globo izquierda"}>
                    {data.imagen !== null && <ViewImage data={data} />}
                    {data.message !== null && <p>{data.message}</p>}
                    <h6>{funciones.getTimeAgo(data.date)}</h6>

                    <div className="contains_tails">
                        {data.owner == window.atob(data_user.auth) ?
                            <Iconos.MessageTailRight />
                            :
                            <Iconos.MessageTailLeft />
                        }
                    </div>
                </div>
            </div>
        );
    }

    const buildChatList = (all_msg) => {
        let _group = [];
        let _sub_group = [];
        let _key = 0;


        all_msg.map((data, index) => {
            data.date_float = funciones.getCompareDates(all_msg, index);

            if (index == 0) {
                _sub_group.push(funciones.getDayMonth(data.date_float));
            }

            if (data.date_float != null && index != 0) {
                _key++;
                _group.push(<div key={_key}>{_sub_group}</div>);
                _sub_group = [];
                _sub_group.push(funciones.getDayMonth(data.date_float));
            }

            _sub_group.push(<Bocadillo key={index} data={data} />);
        });

        if (_sub_group.length > 0) {
            _key++;
            _group.push(<div key={_key}>{_sub_group}</div>);
        }

        setChatList(_group);
    }

    const newMessage = async (_data) => {
        let _existe = false;

        log.d("snapshot", _data);

        if (_data.response == 1) {
            if (listRef.current != null) {

                allMsg.map(item => {
                    if (item.id_chat == _data.data.id_chat) {
                        _existe = true;
                    }
                });

                if (_existe) {
                    log.e("Ya esta el id");
                    return;
                }

                setAllMsg(d => [...d, _data.data]);
            }
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const getData = async () => {
        let _data = await api.current.fetchJson({
            url: "/chats",
            data: {
                task: "chat",
                mi_id: window.atob(data_user.auth),
                guest: guest.id_user
            }
        });

        if (_data.response == 1) {
            setAllMsg(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const sendMessage = async (e) => {
        e.preventDefault();

        if (placeholder !== "Aa") {
            Alert("¡No hay conexion con el servidor!", "warning");
            return;
        }

        if (!loading) {

            if (mensaje.trim() === "") {
                Alert("¡No puedes enviar un mensaje vacío!", "warning");
                return;
            }

            setLoading(true);

            if (websocket.readyState === WebSocket.OPEN) {
                websocket.send(JSON.stringify({
                    task: "message",
                    mi_id: window.atob(data_user.auth),
                    owner_name: data_user.nombre,
                    guest_token: guest.token,
                    guest: guest.id_user,
                    message: mensaje.trim()
                }));

                setMensaje("");
            } else {
                Alert("¡La conexion no esta activa!", "warning");
            }

            setLoading(false);
        }
    }

    const sendImage = async (img) => {

        setLoading(true);

        if (websocket.readyState === WebSocket.OPEN) {
            websocket.send(JSON.stringify({
                task: "image",
                mi_id: window.atob(data_user.auth),
                owner_name: data_user.nombre,
                guest_token: guest.token,
                guest: guest.id_user,
                image: img.name,
                image_url: img.url
            }));

            setMensaje("");
        } else {
            Alert("¡La conexion no esta activa!", "warning");
        }

        setLoading(false);
    }

    const handleKeyUp = (e) => {
        if (e.keyCode === 13) {
            sendMessage(e);
        }
    }

    const conect = () => {
        if (websocket == null) {
            log.w("Conectando realtime");
            let _ws = new WebSocket(process.env.REACT_APP_REAL_TIME);

            _ws.onopen = (e) => {
                log.i("conectado al servidor");

                if (_ws.readyState === WebSocket.OPEN) {
                    _ws.send(JSON.stringify({
                        task: "room",
                        mi_id: window.atob(data_user.auth),
                        guest: guest.id_user
                    }));

                    setPlaceholder("Aa");
                }
            };

            _ws.onmessage = (e) => {
                newMessage(JSON.parse(e.data));
            };

            _ws.onerror = (e) => {
                log.e("¡Ocurrio un error y no se pudo conectar al servidor!");

                Alert("¡Ocurrio un error y no se pudo conectar al servidor!", "warning");
            };

            _ws.onclose = (e) => {
                log.e("¡Conexión cerrada!");
                setPlaceholder("¡Conexión cerrada!");
            };

            return _ws;
        }
    }

    React.useEffect(() => {
        if (listRef.current) {
            listRef.current.scrollTo({
                top: listRef.current.scrollHeight,
                behavior: "smooth"
            });
        }

    }, [chatList]);

    React.useEffect(() => {
        buildChatList(allMsg);

    }, [allMsg]);

    React.useEffect(() => {
        if (guest != null) {
            // log.i("guest", guest);
            getData();
            setWebsocket(conect());

        } else {
            log.e("No hay guest");
        }
    }, [guest]);

    return (
        <div className="vista_chat">
            {guest != null ?
                <>
                    <div className="barra_chat">

                        <ArrowBack onClick={() => navigate(-1)} />
                        <UserIcon className="icono_usuario" />{/* <img src={imagen_usuario} alt="" /> */}
                        <h4>{guest != null ? guest.nombre + " " + guest.apellido : "No hay usuario seleccionado!"}</h4>

                    </div>

                    <div className="conversacion_chat" ref={listRef}>
                        {chatList.map((bubles, index) => bubles)}
                    </div>

                    <div className="envio_chat">
                        {placeholder === "Aa" ?
                            <FileUploadClip loading={loading} onStart={() => setLoading(true)} onUpload={sendImage} />
                            :
                            <label className="_inactive">
                                <Iconos.ClipOff />
                            </label>
                        }
                        <input type="text" placeholder={placeholder} value={mensaje} onChange={({ target: { value } }) => setMensaje(value)} onKeyUp={handleKeyUp} />
                        <button onClick={sendMessage} className={placeholder === "Aa" ? "" : "_inactive"}>
                            {loading ?
                                <LoadingIcon className="on_refresh" />
                                :
                                <SendIcon />
                            }
                        </button>
                    </div>
                </>
                :
                <div className="vista_chat_vacio">
                    <h3>¡Selecciona con quién quieres chatear!</h3>
                </div>
            }

        </div>
    );
}

export default VistaChat;