import * as React from "react";
import { Alert, alerta, alertaRemove } from "shared/Alert";
import { RequirePermissions } from "shared/AlertMessages";
import { company_permissions } from "shared/permissions";
import { Input, InputNum } from "shared/Input";
import EyeOffIcon from "mdi-react/EyeOffIcon";
import withRouter from "services/withRouter";
import funciones from "services/funciones";
import EyeIcon from "mdi-react/EyeIcon";
import { Api } from "services/api";
import CryptoJS from "crypto-js";
import { useMedia } from "hooks";
import * as Icon from "images";
import log from "services/log";

/* eslint eqeqeq: 0 */
/* eslint  jsx-a11y/anchor-is-valid: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const DEFAULT_DOMICILIO_CLIENTE = "8000";
const DEFAULT_DOMICILIO_MENSAJERO = "5000";
const role = 6;

const AddClient = (router) => {
    const { navigate } = router;
    const { media_movil, data_user, setDataUser } = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [nombre, setNombre] = React.useState("");
    const [nombreTienda, setNombreTienda] = React.useState("");
    const [lastname, setLastname] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [idtipe, setIdtipe] = React.useState("Cédula de Ciudadanía");
    const [identification, setIdentification] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPass] = React.useState("");
    const [city, setCity] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [cityList, setCityList] = React.useState([]);
    const [showPass, setShowPass] = React.useState(false);
    const [task, setTask] = React.useState();
    const [pagos, setPagos] = React.useState([]);
    const [domicilioCliente, setDomicilioCliente] = React.useState(DEFAULT_DOMICILIO_CLIENTE);
    const [domicilioMensajero, setDomicilioMensajero] = React.useState(DEFAULT_DOMICILIO_MENSAJERO);
    const [showLoading, setShowLoading] = React.useState(false);
    const [showPermission, setShowPermission] = React.useState(false);
    const [permission, setPermission] = React.useState({});
    const [permOld, setPermOld] = React.useState({});
    const [listaCajaCli, setListaCajaCli] = React.useState([]);
    const [listaCajaMsjs, setListaCajaMsjs] = React.useState([]);

    const formRef = React.useRef();
    const btnRef = React.useRef();


    const handleAcept = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let _error = false;

        // eslint-disable-next-line
        Object.keys(permission).reduce((obj, key) => {
            log.wm(key, permission[key]);
            if (key === "caja_cli_aux") {
                if (permission[key] == "" || permission[key] == 0) {
                    Alert("Debes asignar una caja de clientes principal", "warning");
                    _error = true;
                }
            }
            if (key === "caja_msj_aux") {
                if (permission[key] == "" || permission[key] == 0) {
                    Alert("Debes asignar una caja de mensajeros principal", "warning");
                    _error = true;
                }
            }
            if (key === "caja_msj") {
                if (permission[key] == 1 || permission[key] == 0) {
                    Alert("Debes ingresar un nombre para la caja de mensajeros principal", "warning");
                    _error = true;
                }
            }
            if (key === "caja_cli_principal") {
                if (permission[key] == 1 || permission[key] == 0) {
                    Alert("Debes ingresar un nombre para la caja de clientes principal", "warning");
                    _error = true;
                }
            }
        }, {});

        if (!_error) {
            setShowPermission(false);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setPermission(permOld);
        setShowPermission(false);
    }

    const changeTask = (value) => {
        let toCreate = "admin. de tienda";
        let _perm = {
            company_users: 1,
            company_reporte: 1,
            company_gestiones: 1,
            company_recogidas: 1,
            company_entregas: 1,
            company_dev_pendientes: 1,
            company_dev_entregadas: 1,
            company_crear_recogidas: 1,
        };

        setPermOld(_perm);
        setPermission(_perm);
        setTask(toCreate);
    }

    const getCityList = async () => {
        let _data = await api.current.fetchJson({
            url: "get-users",
            data: {
                roll: "city"
            }
        });

        if (_data.response == 1) {
            setCityList(_data.data.map(data => (data.city)));
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const getListCajaAux = async () => {
        let _data = await api.current.fetchJson({
            url: "get-users",
            data: {
                roll: "caja_msj_list"
            }
        });

        if (_data.response == 1) {
            setListaCajaMsjs(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const getListCorteAux = async () => {
        let _data = await api.current.fetchJson({
            url: "get-users",
            data: {
                roll: "caja_cli_list"
            }
        });

        if (_data.response == 1) {
            setListaCajaCli(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const saveData = async () => {
        setShowLoading(true);
        let _transactions = "";
        pagos.forEach(data => {
            _transactions += data.entity + ": " + data.number + "|";
        });
        _transactions = _transactions.slice(0, -1);

        let _pass = password.length > 1 ? CryptoJS.SHA256(password).toString() : null;
        let _info = {
            id: 0,
            nombre_tienda: nombreTienda,
            nombre: nombre,
            lastname: lastname,
            idtipe: idtipe,
            identification: identification,
            phone: phone,
            phone_family: "",
            email: email,
            city: city,
            address: address,
            password: _pass,
            zona: "",
            rol: role,
            permisos: permission,
            domicilio_cliente: funciones.formatPrice(domicilioCliente).int,
            domicilio_mensajero: funciones.formatPrice(domicilioMensajero).int,
            transactions: _transactions
        };

        let _data = await api.current.saveAgent(_info);

        if (_data.response == 1) {
            Alert(_data.msg, "ok", () => {
                navigate(-1);
            });
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validateFields = (e) => {
        e.preventDefault();

        alertaRemove(formRef.current.company_name);
        alertaRemove(formRef.current.name);
        alertaRemove(formRef.current.lastname);
        alertaRemove(formRef.current.identification);
        alertaRemove(formRef.current.phone);
        alertaRemove(formRef.current.email);
        alertaRemove(formRef.current.password);
        alertaRemove(formRef.current.city);
        alertaRemove(formRef.current.address);
        alertaRemove(formRef.current.domicilio_cliente);
        alertaRemove(formRef.current.domicilio_mensajero);


        if (nombreTienda.length < 1) {
            alerta(formRef.current.company_name, "¡El nombre de la tienda no puede estar vacío!");
            formRef.current.company_name.focus();
            return;
        }

        if (nombre.length < 1) {
            alerta(formRef.current.name, "¡El nombre del representante no puede estar vacío!");
            formRef.current.name.focus();
            return;
        }

        if (lastname.length < 1) {
            alerta(formRef.current.lastname, "¡El apellido del representante no puede estar vacío!");
            formRef.current.lastname.focus();
            return;
        }

        if (identification.length < 1) {
            alerta(formRef.current.identification, "¡El numero del documento no puede estar vacío!");
            formRef.current.identification.focus();
            return;
        }

        if (phone.length < 1) {
            alerta(formRef.current.phone, "¡El teléfono no puede estar vacío!");
            formRef.current.phone.focus();
            return;
        }

        if (email.length < 1) {
            alerta(formRef.current.email, "¡El correo no puede estar vacío!");
            formRef.current.email.focus();
            return;
        }

        if (city.length < 1) {
            alerta(formRef.current.city, "¡La ciudad no puede estar vacía!");
            formRef.current.city.focus();
            return;
        }

        if (address.length < 1) {
            alerta(formRef.current.address, "¡La direccón no puede estar vacía!");
            formRef.current.address.focus();
            return;
        }

        if (!funciones.validaDireccion(address)) {
            alerta(formRef.current.address, "¡El formato de la direccón no es válido! \nEjemplos: \nCra 94 # 69a - 42 \nCarrera 94a # 69 - 42 sur");
            formRef.current.address.focus();
            return;
        }

        if (Object.entries(permission).length < 1) {
            alerta(btnRef.current, "¡Debes conceder permisos al " + task + "!");
            btnRef.current.focus();
            window.scrollTo({
                top: 10,
                behavior: 'smooth'
            });
            return;
        }

        if (role === 6) {
            let _empty = false;
            pagos.forEach((data, index) => {
                if (data.entity.length < 1) {
                    alerta(formRef.current["entity_" + index], "¡La entidad no puede estar vacía!");
                    formRef.current["entity_" + index].focus();
                    _empty = true;
                }
                if (data.number.length < 1) {
                    alerta(formRef.current["number_" + index], "¡El número no puede estar vacío!");
                    formRef.current["number_" + index].focus();
                    _empty = true;
                }
            });

            if (_empty) {
                return;
            }
        }

        if (domicilioCliente.length < 1) {
            alerta(formRef.current.domicilio_cliente, "¡El domicilio del cliente no puede estar vacío!");
            formRef.current.domicilio_cliente.focus();
            return;
        }

        if (domicilioMensajero.length < 1) {
            alerta(formRef.current.domicilio_mensajero, "¡El domicilio del mensajero no puede estar vacío!");
            formRef.current.domicilio_mensajero.focus();
            return;
        }

        saveData();
    }

    const BtnGuarda = () => {
        if (showLoading) {
            return <button className="btn btn-common width-static"><Icon.Loading /></button>;
        } else {
            return <button className="btn btn-common width-static" onClick={validateFields}>Guardar</button>;
        }
    }

    const BtnCheck = ({ object }) => {
        const [listaCajas, setListaCajas] = React.useState([]);
        const [height, setHeight] = React.useState(100);
        const [c_prim, set_c_prim] = React.useState("");
        const [c_aux, set_c_aux] = React.useState("");

        const cPrimRef = React.useRef();
        const contRef = React.useRef();
        const cAuxRef = React.useRef();
        const z_i = (99 - object.index);


        const handleCancel = (e) => {
            e.preventDefault();
            set_c_prim(permission[object.key]);
            cPrimRef.current.classList.remove("_marco_resalta");
        }

        const handleAcept = (e) => {
            e.preventDefault();
            if (c_prim === undefined || c_prim.length < 1) {
                cPrimRef.current.classList.add("_marco_resalta");
                cPrimRef.current.focus();
            } else {
                setPermission(p => { return { ...p, [object.key]: c_prim } });
            }
        }

        const handleSelectCancel = (e) => {
            e.preventDefault();
            set_c_aux(permission[object.key]);
            cAuxRef.current.classList.remove("_marco_resalta");
        }

        const handleSelectAcept = (e) => {
            e.preventDefault();
            if (c_aux === undefined || c_aux < 1) {
                cAuxRef.current.classList.add("_marco_resalta");
                cAuxRef.current.focus();
            } else {
                setPermission(p => { return { ...p, [object.key]: c_aux } });
            }
        }

        React.useEffect(() => {
            set_c_prim((permission[object.key] !== undefined && isNaN(permission[object.key])) ? permission[object.key] : "");

            if (contRef.current !== undefined) {
                setHeight(contRef.current.parentNode.scrollHeight);
            }

            if (object.key == "caja_msj_aux" && permission[object.key] !== undefined) {
                setListaCajas(listaCajaMsjs);
                set_c_aux(permission[object.key]);
            }

            if (object.key == "caja_cli_aux" && permission[object.key] !== undefined) {
                setListaCajas(listaCajaCli);
                set_c_aux(permission[object.key]);
            }
        }, []);

        return <>
            {((object.key == "caja_msj_aux" && permission["caja_msj"] !== undefined) ||
                (object.key == "caja_msj" && permission["caja_msj_aux"] !== undefined) ||
                (object.key == "caja_cli_aux" && permission["caja_cli_principal"] !== undefined) ||
                (object.key == "caja_cli_principal" && permission["caja_cli_aux"] !== undefined)) ?
                <></>
                :
                <div key={object.index} ref={contRef}>
                    <label
                        className="cont_check"
                        onClick={() => setPermission(p => {
                            let _new = Object.keys(p).reduce((obj, key) => {
                                if (object.key !== key) {
                                    obj[key] = p[key];
                                }
                                return obj;
                            }, {});

                            if (p[object.key] === undefined) {
                                switch (object.key) {
                                    case "caja_cli_aux":
                                    case "caja_msj_aux":
                                        _new[object.key] = "";
                                        break;
                                    case "caja_msj":
                                    case "caja_cli_principal":
                                        _new[object.key] = 0;
                                        break;
                                    default:
                                        _new[object.key] = 1;
                                        break;
                                }
                            }

                            setHeight(contRef.current.parentNode.scrollHeight);
                            return _new;
                        })}>
                        <input
                            type="checkbox"
                            checked={permission[object.key] !== undefined}
                            readOnly />
                        {object.value}
                    </label>

                    {((object.key == "caja_cli_principal" && permission[object.key] !== undefined) ||
                        (object.key == "caja_msj" && permission[object.key] !== undefined)) && <>
                            {permission[object.key] !== c_prim && <div className="_bkg_black" style={{ height: height, zIndex: z_i }}></div>}
                            <input type="text"
                                value={c_prim}
                                ref={cPrimRef}
                                className="_input_in_permission"
                                style={{ zIndex: permission[object.key] !== c_prim ? z_i : 1 }}
                                placeholder="Nombre de caja"
                                onChange={e => {
                                    set_c_prim(e.target.value);
                                    cPrimRef.current.classList.remove("_marco_resalta");
                                }} />
                            {permission[object.key] !== c_prim && <div className="_btns_in_row" style={{ zIndex: z_i }}>
                                <button className="btn" onClick={handleCancel}>Cancelar</button>
                                <button className="btn" onClick={handleAcept}>Aceptar</button>
                            </div>}
                        </>}

                    {((object.key == "caja_cli_aux" && permission[object.key] !== undefined) ||
                        (object.key == "caja_msj_aux" && permission[object.key] !== undefined)) && <>
                            {(permission[object.key] !== c_aux || c_aux == "0") && <div className="_bkg_black" style={{ height: height, zIndex: z_i }}></div>}
                            <select
                                value={c_aux}
                                ref={cAuxRef}
                                className="_input_in_permission"
                                style={{ zIndex: (permission[object.key] !== c_aux || c_aux == "0") ? z_i : 1 }}
                                onChange={e => {
                                    set_c_aux(e.target.value);
                                    cAuxRef.current.classList.remove("_marco_resalta");
                                }}>
                                <option value="0">Selecciona...</option>
                                {listaCajas.map(i => <option key={i.id_user} value={i.id_user}>{i.nombre_caja}</option>)}
                            </select>
                            {(permission[object.key] !== c_aux || c_aux == "0") && <div className="_btns_in_row" style={{ zIndex: z_i }}>
                                <button className="btn" onClick={handleSelectCancel}>Cancelar</button>
                                <button className="btn" onClick={handleSelectAcept}>Aceptar</button>
                            </div>}
                        </>}
                </div>
            }
        </>;
    }

    React.useEffect(() => {
        getCityList();
        getListCajaAux();
        getListCorteAux();

        changeTask(6);
    }, []);

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("user_cliente") && [1, 2].includes(parseInt(data_user.nivel))}>

            <form className="pickup" onSubmit={e => e.preventDefault()} ref={formRef}>
                <div className="pickup_container">
                    {showPermission ?
                        <>
                            <div className="pickup_column _row_permission _not_scroll">
                                {company_permissions.map((perm, i) => {
                                    const [key, value] = Object.entries(perm)[0];

                                    if (key == "breack_line") {
                                        return <h4 key={i} className="_hr"><span>{value}</span></h4>;
                                    } else {
                                        return <BtnCheck key={i} object={{ index: i, key, value }} />;
                                    }
                                })}
                            </div>

                            <div className="boton_agregar center">
                                <button className="btn btn-common width-static" onClick={handleAcept}>Aceptar</button>
                                <button className="btn btn-common width-static" onClick={handleCancel}>Cancelar</button>
                            </div>
                        </>
                        :
                        <>
                            {[1, 217].includes(parseInt(window.atob(data_user.auth))) && <div className="pickup_row _content_button">
                                <a className="_btn_a" ref={btnRef} onClick={() => setShowPermission(s => !s)}>Permisos del {task}</a>
                            </div>}

                            <input type="email" name="fake_email" style={{ display: "none" }} />

                            {media_movil ?
                                <div className="pickup_column form_client">

                                    <label>Nombre de la tienda</label>
                                    <div className="input">
                                        <input type="text" name="company_name" value={nombreTienda}
                                            onChange={e => { alertaRemove(formRef.current.company_name); setNombreTienda(e.target.value); }} />
                                    </div>

                                    <label>Nombre del representante</label>
                                    <div className="input">
                                        <input type="text" name="name" value={nombre}
                                            onChange={e => { alertaRemove(formRef.current.name); setNombre(e.target.value); }} />
                                    </div>

                                    <label>Apellidos del representante</label>
                                    <div className="input">
                                        <input type="text" name="lastname" value={lastname}
                                            onChange={e => { alertaRemove(formRef.current.lastname); setLastname(e.target.value); }} />
                                    </div>

                                    <label>Tipo de identificación</label>
                                    <div className="input">
                                        <select name="role" value={idtipe} onChange={e => { alertaRemove(formRef.current.role); setIdtipe(e.target.value); }}>
                                            <option>Cédula de Ciudadanía</option>
                                            <option>Cédula de extranjería</option>
                                            <option>Pasapote</option>
                                            <option>NIT</option>
                                        </select>
                                    </div>

                                    <label>Número de identificación</label>
                                    <div className="input">
                                        <input type="tel" name="identification" value={identification} maxLength="12"
                                            onChange={e => { alertaRemove(formRef.current.identification); setIdentification(e.target.value); }} />
                                    </div>

                                    <label>Teléfono de contacto</label>
                                    <div className="input">
                                        <input type="text" name="phone" value={phone} maxLength="15"
                                            onChange={e => { alertaRemove(formRef.current.phone); setPhone(e.target.value); }} />
                                    </div>

                                    <label>Correo</label>
                                    <div className="input">
                                        <input type="email" name="email" value={email}
                                            onChange={e => { alertaRemove(formRef.current.email); setEmail(e.target.value); }} />
                                    </div>

                                    <label>Contraseña</label>
                                    <div className="_toglle_pass">
                                        <input type={showPass ? "text" : "password"} name="password" value={password}
                                            onChange={e => { alertaRemove(formRef.current.password); setPass(e.target.value); }} />
                                        {showPass ? <EyeOffIcon onClick={() => setShowPass(false)} /> : <EyeIcon onClick={() => setShowPass(true)} />}
                                    </div>

                                    <label>Ciudad</label>
                                    <Input type="text" name="city" value={city} list={cityList}
                                        onChange={v => { alertaRemove(formRef.current.city); setCity(v); }} />

                                    <label>Dirección</label>
                                    <div className="input">
                                        <textarea name="address" rows="2" value={address} placeholder="Ingresa Dirección"
                                            onChange={e => { alertaRemove(formRef.current.address); setAddress(e.target.value); }} />
                                    </div>

                                    <label>Domicilio Cliente</label>
                                    <InputNum name="domicilio_cliente"
                                        value={funciones.formatPrice(domicilioCliente).format}
                                        onChange={v => {
                                            alertaRemove(formRef.current.domicilio_cliente);
                                            setDomicilioCliente(v);
                                        }} />

                                    <label>Domicilio Mensajero</label>
                                    <InputNum name="domicilio_mensajero"
                                        value={funciones.formatPrice(domicilioMensajero).format}
                                        onChange={v => {
                                            alertaRemove(formRef.current.domicilio_mensajero);
                                            setDomicilioMensajero(v);
                                        }} />

                                    <label></label>
                                    <>
                                        {pagos.map((data, index, array) => {
                                            return <fieldset key={index} className="_metodos_pago">
                                                <legend>Transacción</legend>

                                                <label>Entidad</label>
                                                <div className="input">
                                                    <input type="text" name={"entity_" + index} value={data.entity}
                                                        onChange={e => {
                                                            array[index]['entity'] = e.target.value;
                                                            setPagos([...array]);
                                                            alertaRemove(formRef.current["entity_" + index]);
                                                        }} />
                                                </div>

                                                <label>Número</label>
                                                <div className="input">
                                                    <input type="tel" name={"number_" + index} value={data.number}
                                                        onChange={e => {
                                                            array[index]['number'] = e.target.value;
                                                            setPagos([...array]);
                                                            alertaRemove(formRef.current["number_" + index]);
                                                        }} />
                                                </div>
                                            </fieldset>
                                        })}

                                        <h4 className="_button" onClick={() => {
                                            setPagos([...pagos, { entity: "", number: "" }]);
                                        }}>Agregar transacción<Icon.CashPlus /></h4>
                                    </>
                                </div>
                                :
                                <div className="pickup_row form_client">

                                    <div className="pickup_column static">

                                        <label>Nombre de la tienda</label>
                                        <div className="input">
                                            <input type="text" name="company_name" value={nombreTienda}
                                                onChange={e => { alertaRemove(formRef.current.company_name); setNombreTienda(e.target.value); }} />
                                        </div>

                                        <label>Nombre del representante</label>
                                        <div className="input">
                                            <input type="text" name="name" value={nombre}
                                                onChange={e => { alertaRemove(formRef.current.name); setNombre(e.target.value); }} />
                                        </div>

                                        <label>Apellidos del representante</label>
                                        <div className="input">
                                            <input type="text" name="lastname" value={lastname}
                                                onChange={e => { alertaRemove(formRef.current.lastname); setLastname(e.target.value); }} />
                                        </div>

                                        <label>Teléfono de contacto</label>
                                        <div className="input">
                                            <input type="text" name="phone" value={phone} maxLength="15"
                                                onChange={e => { alertaRemove(formRef.current.phone); setPhone(e.target.value); }} />
                                        </div>

                                        <label>Correo</label>
                                        <div className="input">
                                            <input type="email" name="email" value={email}
                                                onChange={e => { alertaRemove(formRef.current.email); setEmail(e.target.value); }} />
                                        </div>

                                        <label>Contraseña</label>
                                        <div className="_toglle_pass" style={{ marginBottom: "1rem" }}>
                                            <input type={showPass ? "text" : "password"} name="password" value={password}
                                                onChange={e => { alertaRemove(formRef.current.password); setPass(e.target.value); }} />
                                            {showPass ? <EyeOffIcon onClick={() => setShowPass(false)} /> : <EyeIcon onClick={() => setShowPass(true)} />}
                                        </div>

                                        {role === 6 &&
                                            <>
                                                {pagos.map((data, index, array) => {
                                                    return <fieldset key={index} className="_metodos_pago">
                                                        <legend>Transacción</legend>

                                                        <label>Entidad</label>
                                                        <div className="input">
                                                            <input type="text" name={"entity_" + index} value={data.entity}
                                                                onChange={e => {
                                                                    array[index]['entity'] = e.target.value;
                                                                    setPagos([...array]);
                                                                    alertaRemove(formRef.current["entity_" + index]);
                                                                }} />
                                                        </div>

                                                        <label>Número</label>
                                                        <div className="input">
                                                            <input type="tel" name={"number_" + index} value={data.number}
                                                                onChange={e => {
                                                                    array[index]['number'] = e.target.value;
                                                                    setPagos([...array]);
                                                                    alertaRemove(formRef.current["number_" + index]);
                                                                }} />
                                                        </div>
                                                    </fieldset>
                                                })}

                                                <h4 className="_button" onClick={() => {
                                                    setPagos([...pagos, { entity: "", number: "" }]);
                                                }}>Agregar transacción<Icon.CashPlus /></h4>

                                            </>
                                        }
                                    </div>

                                    <div className="pickup_column static">

                                        <label>Tipo de identificación</label>
                                        <div className="input">
                                            <select name="role" value={idtipe} onChange={e => { alertaRemove(formRef.current.role); setIdtipe(e.target.value); }}>
                                                <option>Cédula de Ciudadanía</option>
                                                <option>Cédula de extranjería</option>
                                                <option>Pasapote</option>
                                                <option>NIT</option>
                                            </select>
                                        </div>

                                        <label>Número de identificación</label>
                                        <div className="input">
                                            <input type="tel" name="identification" value={identification} maxLength="12"
                                                onChange={e => { alertaRemove(formRef.current.identification); setIdentification(e.target.value); }} />
                                        </div>

                                        <label>Ciudad</label>
                                        <Input type="text" name="city" value={city} list={cityList}
                                            onChange={setCity} />

                                        <label>Dirección</label>
                                        <div className="input">
                                            <textarea name="address" rows="2" value={address} placeholder="Ingresa Dirección"
                                                onChange={e => { alertaRemove(formRef.current.address); setAddress(e.target.value); }} />
                                        </div>

                                        {role === 6 &&
                                            <>
                                                <label>Domicilio Cliente</label>
                                                <InputNum name="domicilio_cliente"
                                                    value={funciones.formatPrice(domicilioCliente).format}
                                                    onChange={v => {
                                                        alertaRemove(formRef.current.domicilio_cliente);
                                                        setDomicilioCliente(v);
                                                    }} />

                                                <label>Domicilio Mensajero</label>
                                                <InputNum name="domicilio_mensajero"
                                                    value={funciones.formatPrice(domicilioMensajero).format}
                                                    onChange={v => {
                                                        alertaRemove(formRef.current.domicilio_mensajero);
                                                        setDomicilioMensajero(v);
                                                    }} />
                                            </>}
                                    </div>
                                </div>
                            }

                            <div className="boton_agregar center">
                                <BtnGuarda />
                                <button className="btn btn-common width-static" onClick={() => navigate(-1)}>Cancelar</button>
                            </div>
                        </>
                    }
                </div>
            </form>
        </RequirePermissions>
    )
}

export default withRouter(AddClient);
