import * as React from "react";
import DeleteIcon from "mdi-react/DeleteOutlineIcon";
import ChatIcon from "mdi-react/ChatOutlineIcon";
import EditIcon from "mdi-react/EditOutlineIcon";
import HistoryIcon from "mdi-react/HistoryIcon";
import OpenIcon from "mdi-react/OpenInNewIcon";
import { Alert, Confirm } from "shared/Alert";
import funciones from "services/funciones";
import DeliveryView from "./DeliveryView";
import CourierList from "./CourierList";
import ReturnPack from "./ReturnPack";
import Loading from "shared/Loading";
import History from "shared/History";
import { Api } from "services/api";
import { useMedia } from "hooks";
import * as Iconos from "images";
import log from "services/log";

/* eslint eqeqeq: 0 */
/* eslint no-undef: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const MenuPacks = (params) => {
    const { navigate, data, blue, children, onSel, reload, current, inRow = false } = params;
    const { media_movil, data_user, setDataUser } = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [clases, setClases] = React.useState(blue == 0 ? "table_row bkg_blue" : "table_row");
    const [_pos, set_pos] = React.useState({ top: "0", left: "0" });
    const [showLoading, setShowLoading] = React.useState(false);
    const [modalView, setModalView] = React.useState(null);
    const [showMenu, setShowMenu] = React.useState(false);
    const [_e, set_e] = React.useState();
    const menuRef = React.useRef();


    const goChat = (e) => {
        e.preventDefault();
        e.stopPropagation();
        document.body.style.overflow = "auto";
        navigate("/dashboard/chat",
            {
                state: {
                    data: {
                        guest: {
                            id_user: data_user.nivel == 6 ? data.carrier : data.id_client,
                            nombre: data_user.nivel == 6 ? data.carrier_name : data.nombre_cliente,
                            apellido: data_user.nivel == 6 ? "" : data.apellido_cliente
                        },
                        fromOutside: true
                    }
                }
            });
    }

    const goEdit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        document.body.style.overflow = "auto";
        if (parseInt(data.estado) === 3) {
            navigate("/dashboard/tienda/editar-paquete", { state: { data: { ...data } } });
        } else {
            navigate("/dashboard/editar-paquete", { state: { data: { ...data } } });
        }
    }

    const goOpen = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (typeof android !== "undefined") {
            let _version = typeof android.getVersion === "function" ? android.getVersion() : "old_version";
            log.i("App version", _version);
            if (["1.1.0", "1.1.1", "2.0.0"].includes(_version)) {
                android.verWebAuxiliar("/dashboard/android-detalle-paquete/" + data.codigo);
            } else {
                document.body.style.overflow = "auto";
                navigate("/dashboard/detalle-paquete", { state: { data: data } });
            }
        } else {
            window.open("/dashboard/detalle-paquete/" + data.codigo, '_blank');
        }

        document.body.style.overflow = "auto";
        setShowMenu(false);
    }

    const goLabel = (e) => {
        e.preventDefault();
        e.stopPropagation();

        document.body.style.overflow = "auto";
        navigate("/dashboard/tienda/rotulo", { state: { data: data } });

        setShowMenu(false);
    }

    const goDelete = (e) => {
        hiddenMenu(e);

        Confirm("¿Seguro de borrar este paquete?", async (response) => {
            if (response) {
                setShowLoading(true);
                let _data = await api.current.deletePackage({ id_pack: data.id });

                if (_data.response == 1) {
                    // navigate(-1);
                    document.body.style.overflow = "auto";
                    reload();
                } else if (_data.response === "-3") {
                    Alert(_data.msg, "warning", () => reload());
                } else if (_data.response === -2) {
                    Alert(_data.msg, "warning", () => {
                        document.body.style.overflow = "auto";
                        navigate("/sign-in");
                    });
                } else {
                    Alert(_data.msg, "warning");
                }

                setShowMenu(false);
                setShowLoading(false);
            }
        });

    }

    const onSelect = (e) => {
        e.preventDefault();
        e.stopPropagation();

        onSel(data);
    }

    const lastRelevant = (_states) => {
        _states = _states.toString().split(',');
        let _estado = 0;

        for (let i = (_states.length - 1); i >= 0; i--) {
            _estado = parseInt(_states[i]);

            if ([6, 7, 8, 9, 12, 13].includes(_estado)) {
                break;
            }
        }
        return _estado;
    }

    const stopClick = (e) => {
        // e.preventDefault(); Se comenta esta linea para que funcione el input[type="file"]
        e.stopPropagation();
    }

    const hiddenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        document.body.style.overflow = "auto";
        setShowMenu(false);
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        set_e(e);
        document.body.style.overflow = "hidden";
        setShowMenu(true);
    }

    const handleModal = (_option, e) => {
        e.preventDefault();
        e.stopPropagation();
        let _window;

        switch (_option) {
            case "history":
                _window = <History
                    data={{
                        ...data,
                        tipo: 2,
                        parent: { ..._pos, width: media_movil ? (document.getElementById("root").offsetWidth - 16) : 550 }
                    }}
                    close={() => setModalView(null)}
                    title="Historial del paquete" />;
                break;
            case "assign_pickup":
                _window = <CourierList
                    data={{
                        ...data,
                        id_pack: data.id,
                        url: "save-assign-package",
                        parent: { ..._pos, width: 250 }
                    }}
                    close={() => setModalView(null)}
                    reload={reload} />;
                break;
            case "delivery":
                _window = <DeliveryView
                    data={{
                        ...data,
                        parent: { ..._pos, width: media_movil ? 260 : 230 }
                    }}
                    close={() => setModalView(null)}
                    reload={reload} />;
                break;
            case "return_pack":
                _window = <ReturnPack
                    data={{
                        ...data,
                        parent: { ..._pos, width: 230 }
                    }}
                    close={() => setModalView(null)}
                    reload={reload} />;
                break;
            default:
                _window = null;
                break;
        }

        setShowMenu(false);
        setModalView(_window);
    }

    const BtnEdit = () => {
        let _btn_enable = <li onClick={goEdit}><EditIcon /> <span>Editar</span></li>;
        let _btn_disable = <li className="unable"><EditIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Editar</span></li>;

        if ([6, 61].includes(parseInt(data_user.nivel))) {
            if (funciones.validaPermisos("company_gestion_paquete")) {
                if (parseInt(data.estado) === 3) {
                    return _btn_enable;
                } else {
                    return _btn_disable;
                }
            } else {
                return <></>;
            }
        }

        switch (parseInt(data.estado)) {
            case 3:
                if (funciones.validaPermisos("recolectas_edita")) {
                    return _btn_enable;
                } else {
                    return <></>;
                }
            case 31:
            case 32:
            case 33:
                if (funciones.validaPermisos("recolectas_edita")) {
                    return _btn_disable;
                } else {
                    return <></>;
                }
            case 0:
            case 1:
            case 2:
            case 4:
            case 5:
            case 7:
            case 8:
            case 12:
            case 13:
                if (funciones.validaPermisos("pack_edicion")) {
                    return _btn_enable;
                } else {
                    return <></>;
                }
            case 6:
            case 9:
                if (funciones.validaPermisos("pack_edicion")) {
                    if (data.caja_m_a == 0) {
                        return _btn_enable;
                    } else {
                        return _btn_disable;
                    }
                } else {
                    return <></>;
                }
            case 10:
            case 14:
            case 15:
            case 16:
            case 17:
            default:
                if (funciones.validaPermisos("pack_edicion")) {
                    return _btn_disable;
                } else {
                    return <></>;
                }
        }
    }

    const BtnLabel = () => {
        let _btn = <></>;

        if ([6, 61].includes(parseInt(data_user.nivel))) {
            _btn = <li className="unable"><Iconos.PrinterOutline color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Rótulo</span></li>;

            if ([3, 31, 32, 33].includes(parseInt(data.estado)) && data.id_client == data_user.info.company.id) {
                _btn = <li onClick={goLabel}><Iconos.PrinterOutline /> <span>Rótulo</span></li>;
            }
        }

        return (_btn);
    }

    const BtnChat = () => {
        let _btn = <li className="unable"><ChatIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Abrir chat</span></li>;


        if (data_user.nivel < 4) {
            _btn = <li onClick={goChat}><ChatIcon /> <span>Abrir chat</span></li>;
        }

        if (data_user.nivel == 4 && data.carrier == window.atob(data_user.auth)) {
            _btn = <li onClick={goChat}><ChatIcon /> <span>Abrir chat</span></li>;
        }

        if (data_user.nivel == 6 && data.carrier != null) {
            _btn = <li onClick={goChat}><ChatIcon /> <span>Abrir chat</span></li>;
        }

        return (_btn);
    }

    const BtnAssign = () => {
        let _btn = <li className="unable"><Iconos.BoxUser color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Cambiar mensajero</span></li>;

        switch (parseInt(data.estado)) {
            case 4:
            case 5:
            case 7:
            case 8:
            case 12:
            case 13:
                if (data.carrier == null) {
                    _btn = <li onClick={e => handleModal("assign_pickup", e)}><Iconos.BoxUser /> <span>Asignar entrega</span></li>;
                } else {
                    _btn = <li onClick={e => handleModal("assign_pickup", e)}><Iconos.BoxUser /> <span>Cambiar mensajero</span></li>;
                }
                break;
            case 10:
            case 14:
            case 15:
            case 16:
            case 17:
                _btn = <></>;
                break;
        }

        return (_btn);
    }

    const BtnBodega = () => {
        let _btn = <></>;
        let _btn_ko = <></>;
        let _btn_dv = <></>;
        let _show = funciones.validaPermisos("pack_edicion");
        let _estado = lastRelevant(data.all_states || data.estado);


        const handlePreCorte = async (e) => {
            e.preventDefault();
            Confirm("¿Seguro de recibir este pre-corte en bodega?", async (response) => {
                if (response) {
                    setShowLoading(true);
                    let _data = await api.current.fetchJson({
                        url: "update-package-precorte-a-bodega",
                        data: {
                            pre_corte: true,
                            id_pack: data.id,
                            estado: 14
                        }
                    });

                    if (_data.response == 1) {
                        Alert(_data.msg, "ok", () => reload());
                    } else if (_data.response === "-3") {
                        Alert(_data.msg, "warning", () => reload());
                    } else if (_data.response === -2) {
                        Alert(_data.msg, "warning", () => {
                            document.body.style.overflow = "auto";
                            navigate("/sign-in");
                        });
                    } else {
                        Alert(_data.msg, "warning");
                    }

                    setShowMenu(false);
                    setShowLoading(false);
                } else {
                    document.body.style.overflow = "auto";
                    setShowMenu(false);
                }
            });
        }

        const handleKoABodega = async (e, estado) => {
            e.preventDefault();
            Confirm(`¿Seguro de mover como ${estado === 7 ? "KO.CANCELADO" : "KO.REPROGRAMADO"} a bodega?`, async (response) => {
                if (response) {
                    setShowLoading(true);
                    let _data = await api.current.fetchJson({
                        url: "update-package-mueve-a-bodega",
                        data: {
                            id_pack: data.id,
                            estado
                        }
                    });

                    if (_data.response == 1) {
                        Alert(_data.msg, "ok", () => reload());
                    } else if (_data.response === "-3") {
                        Alert(_data.msg, "warning", () => reload());
                    } else if (_data.response === -2) {
                        Alert(_data.msg, "warning", () => {
                            document.body.style.overflow = "auto";
                            navigate("/sign-in");
                        });
                    } else {
                        Alert(_data.msg, "warning");
                    }

                    setShowMenu(false);
                    setShowLoading(false);
                } else {
                    document.body.style.overflow = "auto";
                    setShowMenu(false);
                }
            });
        }

        const handleRecibe = async (e) => {
            e.preventDefault();
            Confirm("¿Seguro de recibir este paquete en bodega?", async (response) => {
                if (response) {
                    setShowLoading(true);
                    let _data = await api.current.fetchJson({
                        url: "update-package-receive-in-warehouse",
                        data: {
                            type: 2,
                            id: data.id
                        }
                    });

                    if (_data.response == 1) {
                        Alert(_data.msg, "ok", () => reload());
                    } else if (_data.response === "-3") {
                        Alert(_data.msg, "warning", () => reload());
                    } else if (_data.response === -2) {
                        Alert(_data.msg, "warning", () => {
                            document.body.style.overflow = "auto";
                            navigate("/sign-in");
                        });
                    } else {
                        Alert(_data.msg, "warning");
                    }

                    setShowMenu(false);
                    setShowLoading(false);
                } else {
                    document.body.style.overflow = "auto";
                    setShowMenu(false);
                }
            });
        }

        const handleSalida = async (e) => {
            e.preventDefault();
            Confirm("¿Seguro de dar salida nuevamente a este paquete?", async (response) => {
                if (response) {
                    setShowLoading(true);
                    let _data = await api.current.fetchJson({
                        url: "update-package-release-again",
                        data: {
                            type: 2,
                            id: data.id
                        }
                    });

                    if (_data.response == 1) {
                        Alert(_data.msg, "ok", () => reload());
                    } else if (_data.response === "-3") {
                        Alert(_data.msg, "warning", () => reload());
                    } else if (_data.response === -2) {
                        Alert(_data.msg, "warning", () => {
                            document.body.style.overflow = "auto";
                            navigate("/sign-in");
                        });
                    } else {
                        Alert(_data.msg, "warning");
                    }

                    setShowMenu(false);
                    setShowLoading(false);
                } else {
                    document.body.style.overflow = "auto";
                    setShowMenu(false);
                }
            });
        }

        switch (parseInt(data.estado)) {
            case 10:
                _btn = _show ? <li onClick={handleRecibe}><Iconos.PackReceive /> <span>Recibir en bodega</span></li> : <></>;
                break;
            case 14:
                _btn = _show ? <>
                    {_estado !== 9 && <li onClick={handleSalida}><Iconos.BoxRefresh /> <span>Vuelve a salir</span></li>}
                    <li onClick={e => handleModal("return_pack", e)}><Iconos.BoxArrowUp /> <span>Devolver a cliente</span></li>
                </> : <></>;
                break;
            case 15:
                _btn = _show ? <li onClick={e => handleModal("return_pack", e)}><Iconos.BoxArrowUp /> <span>Devolver a cliente</span></li> : <></>;
                break;
            case 17:
                let _arr = data.all_states || data.estado;
                _arr = _arr.split(',');
                if (_show && _arr.includes('9') && !_arr.includes('16')) {
                    _btn = <li onClick={e => handleModal("return_pack", e)}><Iconos.BoxArrowUp /> <span>Devolver a cliente</span></li>;
                }
                break;
        }

        if (_show && [4, 5].includes(parseInt(data.estado))) {
            _btn_ko = <>
                <li onClick={e => handleKoABodega(e, 7)}><Iconos.PackReceive /> <span>KO.CAN a bodega</span></li>
                <li onClick={e => handleKoABodega(e, 13)}><Iconos.PackReceive /> <span>KO.REP a bodega</span></li>
            </>;
        }

        if (_show && [7, 8, 9, 12, 13].includes(_estado) && data.caja_m_a == 0 && data.carrier != null) {
            _btn_dv = <li onClick={handlePreCorte}><Iconos.PackReceive /> <span>Pre-corte a bodega</span></li>;
        }

        return (<>
            {_btn}
            {_btn_ko}
            {_btn_dv}
        </>);
    }

    const BtnDelivery = () => {
        let _txt = window.atob(data_user.auth) == data.carrier ? "Cambia estado" : "Tomar paquete";
        let _btn = <li className="unable"><Iconos.PackOk color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>{_txt}</span></li>;

        const handleAsigname = async (e) => {
            e.preventDefault();
            setShowLoading(true);
            let _data = await api.current.saveAssignPackage({
                id_carrier: window.atob(data_user.auth),
                id_pack: data.id
            });

            if (_data.response == 1) {
                Alert("El paquete ha sido asignado a tu lista de entregas", "ok", () => reload());
            } else if (_data.response === "-3") {
                Alert(_data.msg, "warning", () => reload());
            } else if (_data.response === -2) {
                Alert(_data.msg, "warning", () => {
                    document.body.style.overflow = "auto";
                    navigate("/sign-in");
                });
            } else {
                Alert(_data.msg, "warning");
            }

            setShowMenu(false);
            setShowLoading(false);
        }

        if ([5, 7, 13].includes(parseInt(data.estado)) && window.atob(data_user.auth) == data.carrier) {
            _btn = <li onClick={e => handleModal("delivery", e)}><Iconos.PackOk /> <span>Cambia estado</span></li>;
        }

        if ([4].includes(parseInt(data.estado)) && data.carrier == null) {
            _btn = <li onClick={handleAsigname}><Iconos.PackOk /> <span>Tomar paquete</span></li>;
        }

        if ([14, 15].includes(parseInt(data.estado))) {
            _btn = <hr />;
        }

        return (_btn);
    }

    const BtnDelete = () => {
        let _btn = (funciones.validaPermisos("pack_edicion") && data_user.nivel < 4) ?
            <li className="unable"><DeleteIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Borrar</span></li>
            :
            <li className="unable"><Iconos.Pack color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>...</span></li>;


        if ([0, 1, 2, 3, 4].includes(parseInt(data.estado)) && funciones.validaPermisos("pack_edicion") && data_user.nivel < 4) {
            _btn = <li onClick={goDelete}><DeleteIcon /> <span>Borrar</span></li>;
        }

        if ([0, 1, 3, 32].includes(parseInt(data.estado)) && funciones.validaPermisos("company_gestion_paquete") && data.id_client == data_user.info.company.id) {
            _btn = <li onClick={goDelete}><DeleteIcon /> <span>Borrar</span></li>;
        }

        return (_btn);
    }

    const AllButtons = () => {
        return (
            <>
                {showMenu &&
                    <>
                        <div className="back_form _bkg_black" onClick={hiddenMenu} onContextMenu={hiddenMenu}></div>
                        <nav className="menu" style={{ ..._pos, width: media_movil ? 220 : 170 }} ref={menuRef}>
                            <ul>
                                {inRow && <li onClick={goOpen}><OpenIcon /> <span><strong>Detalle</strong></span></li>}

                                <BtnLabel />
                                <BtnEdit />
                                <BtnChat />
                                <BtnBodega />
                                {data_user.nivel < 4 && <BtnAssign />}
                                {data_user.nivel == 4 && <BtnDelivery />}
                                <hr />
                                {data_user.nivel < 4 && <li onClick={e => handleModal("history", e)}><HistoryIcon /> <span>Historial</span></li>}
                                <BtnDelete />
                            </ul>
                            {showLoading && <Loading />}
                        </nav>
                    </>}
            </>)
    }

    React.useEffect(() => {
        if (showMenu) {
            set_pos(funciones.getPositonDisplay(_e, menuRef.current));
        }
    }, [showMenu]);

    React.useEffect(() => {
        if (inRow) {
            current === data.id && setClases(c => c + " bkg_select");
        }
    }, [current]);

    return (
        <>
            {inRow ?
                <tr className={clases}
                    onDoubleClick={goOpen}
                    onClick={onSelect}
                    onContextMenu={handleContextMenu}>

                    {children}

                    <td className="rowMenu" onClick={stopClick} onDoubleClick={stopClick} onContextMenu={stopClick}>
                        <AllButtons />
                        {modalView}
                    </td>
                </tr>
                :
                <>
                    <div className="main_container" onContextMenu={handleContextMenu}>

                        {children}

                        <AllButtons />
                    </div>
                    {modalView}
                </>}
        </>
    )
}

export default MenuPacks;