import * as React from "react";
import HistoryIcon from "mdi-react/HistoryIcon";
import funciones from "services/funciones";
import CourierList from "./CourierList";
import History from "shared/History";
import { useMedia } from "hooks";
import * as Iconos from "images";

/* eslint eqeqeq: 0 */
/* eslint no-undef: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const MenuAdmin = (params) => {
    const { data, blue, children, onSel, reload, current, inRow = false } = params;
    const [clases, setClases] = React.useState(blue == 0 ? "table_row bkg_blue" : "table_row");
    const { media_movil, data_user, setModalView } = useMedia();
    const [_pos, set_pos] = React.useState({ top: "0", left: "0" });
    const [showMenu, setShowMenu] = React.useState(false);
    const [_e, set_e] = React.useState();
    const menuRef = React.useRef();


    const onSelect = (e) => {
        e.preventDefault();
        e.stopPropagation();

        onSel(data);
    }

    const stopClick = (e) => {
        // e.preventDefault(); Se comenta esta linea para que funcione el input[type="file"]
        e.stopPropagation();
    }

    const hiddenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        document.body.style.overflow = "auto";
        setShowMenu(false);
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        set_e(e);
        document.body.style.overflow = "hidden";
        setShowMenu(true);
    }

    const handleModal = (_option, e) => {
        e.preventDefault();
        e.stopPropagation();
        let _window;

        switch (_option) {
            case "history":
                _window = <History
                    data={{
                        id: data.info.collect.id,
                        tipo: 3,
                        parent: { ..._pos, width: media_movil ? (document.getElementById("root").offsetWidth - 16) : 550 }
                    }}
                    close={() => setModalView(null)}
                    title="Historial del paquete" />;
                break;
            case "assign_collect":
                _window = <CourierList
                    data={{
                        type: 'assign',
                        id_tienda: data.id_client,
                        url: "save-assign-collect",
                        parent: { ..._pos, width: 250 }
                    }}
                    close={() => setModalView(null)}
                    reload={reload} />;
                break;
            case "added_collect":
                _window = <CourierList
                    data={{
                        type: 'added',
                        id_tienda: data.id_client,
                        url: "save-assign-collect",
                        parent: { ..._pos, width: 250 }
                    }}
                    close={() => setModalView(null)}
                    reload={reload} />;
                break;
            default:
                _window = null;
                break;
        }

        setShowMenu(false);
        setModalView(_window);
    }

    const BtnAssign = () => {
        let _btn = <li className="unable"><Iconos.BoxUser /> <span>Asignar recolecta</span></li>;

        if (data.info?.collect?.carrier == null) {
            _btn = <li onClick={e => handleModal("assign_collect", e)}><Iconos.BoxUser /> <span>Asignar recolecta</span></li>;
        } else {
            _btn = <>
                <li onClick={e => handleModal("added_collect", e)}><Iconos.BoxUser /> <span>Agregar mensajero</span></li>
                <li onClick={e => handleModal("assign_collect", e)}><Iconos.BoxUser /> <span>Cambiar mensajero</span></li>
            </>;
        }

        return _btn;
    }

    const AllButtons = () => {
        return (
            <>
                {showMenu &&
                    <>
                        <div className="back_form _bkg_black" onClick={hiddenMenu} onContextMenu={hiddenMenu}></div>
                        <nav className="menu" style={{ ..._pos, width: media_movil ? 220 : 170 }} ref={menuRef}>
                            <ul>

                                {data_user.nivel < 4 && <BtnAssign />}
                                <hr />
                                {(data_user.nivel < 4 && data.info?.collect?.id) ?
                                    <li onClick={e => handleModal("history", e)}><HistoryIcon /> <span>Historial</span></li>
                                    :
                                    <li className="unable"><HistoryIcon /> <span>Historial</span></li>
                                }
                            </ul>
                        </nav>
                    </>}
            </>)
    }

    React.useEffect(() => {
        if (showMenu) {
            set_pos(funciones.getPositonDisplay(_e, menuRef.current));
        }
    }, [showMenu]);

    React.useEffect(() => {
        if (inRow) {
            current === data.id_client && setClases(c => c + " bkg_select");
        }
    }, [current]);

    return (
        <>
            {inRow ?
                <tr className={clases}
                    onClick={onSelect}
                    onContextMenu={handleContextMenu}>

                    {children}

                    <td className="rowMenu" onClick={stopClick} onDoubleClick={stopClick} onContextMenu={stopClick}>
                        <AllButtons />
                    </td>
                </tr>
                :
                <>
                    <div className="main_container" onContextMenu={handleContextMenu}>

                        {children}

                        <AllButtons />
                    </div>
                </>}
        </>
    )
}

export default MenuAdmin;