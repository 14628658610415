import * as React from "react";
import Information from "mdi-react/InformationOutlineIcon";
import { useNavigate } from "react-router-dom";
import { MovePopUp } from "shared/MovePopUp";
import funciones from "services/funciones";
import { Alert } from "shared/Alert";
import { Api } from "services/api";
import { useMedia } from "hooks";
import * as Icon from "images";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const EntregaCaja = ({ parent, close, reload }) => {
    const navigate = useNavigate();
    const { setDataUser } = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [valores, setValores] = React.useState({ efectivo: 0, transferencias: 0 });
    const [showLoading, setShowLoading] = React.useState(true);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [error, setError] = React.useState("");
    const [msg, setMsg] = React.useState("");


    const handleClose = () => {
        reload();
        document.body.style.overflow = "auto";
        close(false);
    }

    const entregarCaja = async () => {
        setShowLoading(true);

        let _data = await api.current.fetchJson({
            url: "save-caja",
            data: {
                task: "entrega_caja_bodega"
            }
        });

        if (_data.response == 1) {
            setMsg(_data.msg);
            setIsLoaded(true);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            setError(_data.msg);
        }

        setShowLoading(false);
    }

    const getInfo = async () => {
        setShowLoading(true);

        let _data = await api.current.fetchJson({
            url: "info-caja",
            data: {
                task: "saldo_caja_bdg"
            }
        });

        if (_data.response == 1) {
            let _list = { transferencias: 0, efectivo: 0 };

            _data.data.map(item => {
                if (item.metodo == 1) {
                    _list.efectivo = parseInt(item.monto);
                }
                if (item.metodo == 2) {
                    _list.transferencias = parseInt(item.monto);
                }
            });


            if (_data.data[0].entregas_pendientes > 0) {
                setMsg("¡Tienes una entrega pendiente de validación!");
                setIsLoaded(true);
            }

            if (_list.efectivo < 1) {
                setMsg("¡No tienes saldo en efectivo para entregar!");
                setIsLoaded(true);
            }

            setValores(_list);
            setShowLoading(false);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const BtnAceptar = () => {
        if (isLoaded) {
            return <button className="btn btn-common width-static" onClick={handleClose}>Aceptar</button>;
        } else {
            if (showLoading) {
                return <button className="btn btn-common width-static"><Icon.Loading /></button>;
            } else {
                return <button className="btn btn-common width-static" onClick={entregarCaja}>Entregar</button>;
            }
        }
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        getInfo();
    }, []);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            className="win_modal _overflow _delivery"
            title="Entrega de caja">

            <div className="_in_column _cont_entrega">

                {showLoading ?
                    <div className="cont_loading"><Icon.Loading /></div>
                    :
                    <div>
                        {isLoaded ?
                            <h4 className="_precio _msg">{msg}</h4>
                            :
                            <>
                                <i>Estos son los valores que se entregarán a la caja principal.</i>

                                <h4 className="_precio">
                                    <hr />
                                    <span>Efectivo</span><br /> ${funciones.formatPrice(valores.efectivo).format}
                                    <br />
                                    <hr />
                                    <span>Transferencias</span><br /> ${funciones.formatPrice(valores.transferencias).format}
                                    <br />
                                    <hr />
                                    <br />
                                    <br />
                                    <span>Total a entregar</span><br /> ${funciones.formatPrice(valores.efectivo + valores.transferencias).format}
                                    <hr />
                                </h4>
                            </>
                        }
                    </div>
                }

            </div>

            {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}

            <BtnAceptar />
        </MovePopUp>
    )
}

export default EntregaCaja;