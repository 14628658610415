import * as React from "react";
import SwapVerticalIcon from "mdi-react/SwapVerticalIcon";
import { RequirePermissions } from "shared/AlertMessages";
import PlugIcon from "mdi-react/AccountPlusIcon";
import { TitleSticky } from "shared/Components";
import MenuAdmin from "./components/MenuAdmin";
import Filter from "mdi-react/FilterMenuIcon";
import withRouter from "services/withRouter";
import { MovePopUp } from "shared/MovePopUp";
import { SearchUsers } from "shared/Input";
import funciones from "services/funciones";
import Loading from "shared/Loading";
import { Alert } from "shared/Alert";
import { Api } from "services/api";
import { useMedia } from "hooks";
import * as Icon from "images";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const LIMIT = 20;

const Clientes = (router) => {
    const { navigate, location } = router;
    const { data_user, setDataUser, setTitulo, currentQuery, setCurrentQuery } = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [loadResult, setLoadResult] = React.useState("Cargando tiendas...");
    const [rows, setRows] = React.useState(<h4 className="_titulo">{loadResult}</h4>);
    const [listWithResumen, setListWithResumen] = React.useState([]);
    const [pos, setPos] = React.useState({ top: "0", left: "0" });
    const [showLoading, setShowLoading] = React.useState(true);
    const [showFilter, setShowFilter] = React.useState(false);
    const [showLazy, setShowLazy] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);
    const [asc, setAsc] = React.useState(false);
    const [rol, setRoll] = React.useState(7);
    const [task, setTask] = React.useState();


    /**
     * Niveles de los usuarios 
     * 
     * 1 Super Administrador
     * 2 Administrador
     * 3 Coordinador de zona
     * 4 Mensajero
     * 5 Recepcionista
     * 6 Tiendas
     * 7 Usuario final
     * 
     */

    const changeTask = (_rol) => {
        let toReturn;

        switch (parseInt(_rol)) {
            case 1:
                toReturn = "super administradores";
                break;
            case 2:
                toReturn = "administradores";
                break;
            case 3:
                toReturn = "coordinadores de zona";
                break;
            case 4:
                toReturn = "mensajeros";
                break;
            case 5:
                toReturn = "recepcionistas";
                break;
            case 6:
                toReturn = "tiendas";
                break;
            case 8:
                toReturn = "cajeros";
                break;
            case 7:
            default:
                toReturn = "usuarios finales";
                break;
        }

        setTask(toReturn);
        return toReturn;
    }

    const Row = ({ data, interline }) => {
        const [resumen, setResumen] = React.useState(data.resumen);


        const getCounts = async (_id) => {
            let _resumen;
            let _data = await api.current.fetchJson({
                url: "get-counts",
                data: { id: _id }
            });

            if (_data.response == 1) {
                _resumen = { load: 1, ...JSON.parse(_data.data) };
                setResumen(_resumen);
                setListWithResumen(l => l.map(item => item.id_user == _id ? { ...item, resumen: _resumen } : item));
            } else if (_data.response === -2) {
                _resumen = { ...data.resumen, load: 3 };
                setResumen(_resumen);
                setListWithResumen(l => l.map(item => item.id_user == _id ? { ...item, resumen: _resumen } : item));
                Alert(_data.msg, "warning", () => navigate("/sign-in"));
            } else {
                _resumen = { ...data.resumen, load: 3 };
                setResumen(_resumen);
                setListWithResumen(l => l.map(item => item.id_user == _id ? { ...item, resumen: _resumen } : item));
                Alert(_data.msg, "warning");
            }
        }

        React.useEffect(() => {
            if (resumen.load == 0) {
                getCounts(data.id_user);
            }
        }, [data]);

        return (
            <MenuAdmin data={{ ...data, rol: rol }}
                interline={interline}
                navigate={navigate}
                resumen={resumen}
                reload={() => getList(currentQuery.clientes)}>

                <td>{data.tienda}</td>
                <td>{data.nombre} {data.apellido}</td>
                <td>{resumen.load == 0 ? <Icon.Loading className="_icon_load" /> : resumen.load == 3 ? <Icon.Close color="rgb(255,0,0)" className="svg_icon" /> : funciones.formatPrice(resumen.total).format}</td>
                <td>{data.telefono}</td>
            </MenuAdmin>
        )
    }

    const armaFilas = () => {

        let _data = dataList.map((item, index) => {
            item.resumen = typeof item.resumen == "string" ? JSON.parse(item.resumen) : item.resumen;

            return <Row
                key={index}
                data={item}
                interline={index % 2} />;
        });

        if (_data.length < 1) {
            setRows(<h4 className="_titulo">{loadResult}</h4>);
        } else {
            setRows(<div style={{ overflowX: "scroll" }}>
                <table className="table">
                    <tbody>

                        <tr className="table_title">
                            <th><SwapVerticalIcon onClick={() => sortBy("tienda")} />Tienda</th>
                            <th><SwapVerticalIcon onClick={() => sortBy("nombre")} />Representante</th>
                            <th>Pagos</th>
                            <th>Teléfono</th>
                        </tr>

                        {_data}
                    </tbody>
                </table>
            </div>);
        }
    }

    const sortBy = (_key) => {

        let mapped = dataList.map((item, i) => {
            return { index: i, value: item[_key].toLowerCase() };
        });

        mapped.sort(function (a, b) {
            if (asc) {
                if (a.value > b.value) {
                    return 1;
                }

                if (a.value < b.value) {
                    return -1;
                }
            } else {
                if (a.value > b.value) {
                    return -1;
                }

                if (a.value < b.value) {
                    return 1;
                }
            }

            return 0;
        });

        let _new = mapped.map(item => {
            return dataList[item.index];
        });

        setAsc(!asc);
        setDataList(_new);
    }

    const getListLazy = async () => {
        setShowLoading(true);

        let _data = await api.current.fetchJson({
            url: "get-users",
            data: {
                roll: currentQuery.clientes.task,
                criterio: currentQuery.clientes.criterio,
                filtro: currentQuery.clientes.filtro,
                limit: LIMIT,
                offset: dataList.length,
                last_id: dataList[dataList.length - 1].id_user
            }
        });

        if (_data.response == 1) {
            let _tiendas = listWithResumen.map(item => {
                let _info = JSON.parse(item.info);
                item.tienda = _info?.company?.nombre ?? "";
                return item;
            });

            _data.data.forEach(item => {
                let _info = JSON.parse(item.info);
                item.tienda = _info?.company?.nombre ?? "";
                _tiendas.push(item);
            });

            _tiendas = _tiendas.reduce(
                (prev, curr) => prev.find(i => i.id_user === curr.id_user)
                    ? prev
                    : [...prev, curr]
                , []);

            setDataList(_tiendas);
            setListWithResumen(_tiendas);
            setShowLazy(_data.data.length < LIMIT ? false : true);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay " + task + "!" : "¡No hay más " + task + "!");
        setShowLoading(false);
    }

    const handleOpen = (e) => {
        e.preventDefault()
        e.stopPropagation();

        navigate("/dashboard/tiendas/agregar", { state: { data: { rol: rol } } });
    }

    const handleSearch = async (_val) => {
        _val = _val.toLowerCase();
        setLoadResult("Buscando " + task + " con '" + _val + "'!");

        setShowLoading(true);
        setCurrentQuery(v => ({ ...v, clientes: { task: "search_clients", criterio: _val, filtro: "" } }));
        setDataList([]);
        let _data = await api.current.fetchJson({
            url: "get-users",
            data: {
                roll: "search_clients",
                criterio: _val,
                limit: LIMIT,
                offset: 0,
                last_id: 0
            }
        });

        if (_data.response == 1) {
            let _tiendas = _data.data.map(item => {
                let _info = JSON.parse(item.info);
                item.tienda = _info?.company?.nombre ?? "";
                return item;
            });
            setDataList(_tiendas);
            setListWithResumen(_tiendas);
            setShowLazy(_data.data.length < LIMIT ? false : true);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay " + task + " con '" + _val + "'!" : "¡No hay más " + task + " con '" + _val + "'!");
        setShowLoading(false);
    }

    const getList = async (_query) => {
        setShowLoading(true);
        setShowFilter(false);
        setDataList([]);
        let _data = await api.current.fetchJson({
            url: "get-users",
            data: {
                roll: _query.task,
                criterio: _query.criterio,
                filtro: _query.filtro,
                limit: LIMIT,
                offset: 0,
                last_id: 0
            }
        });

        if (_data.response == 1) {
            let _tiendas = _data.data.map(item => {
                let _info = JSON.parse(item.info);
                item.tienda = _info?.company?.nombre ?? "";
                return item;
            });
            setDataList(_tiendas);
            setListWithResumen(_tiendas);
            setShowLazy(_data.data.length < LIMIT ? false : true);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay tiendas!" : "¡No hay más tiendas!");
        setShowLoading(false);
    }

    const handleClose = (e) => {
        e.preventDefault();
        setShowFilter(false);
    }

    const handleFilter = async (e, _tipo) => {
        e.preventDefault();
        setShowFilter(false);

        setShowLoading(true);
        setCurrentQuery(v => ({ ...v, clientes: { task: "filter_clients", criterio: "", filtro: _tipo } }));
        setDataList([]);
        let _data = await api.current.fetchJson({
            url: "get-users",
            data: {
                roll: "filter_clients",
                filtro: _tipo,
                limit: LIMIT,
                offset: 0,
                last_id: 0
            }
        });

        if (_data.response == 1) {
            let _tiendas = _data.data.map(item => {
                let _info = JSON.parse(item.info);
                item.tienda = _info?.company?.nombre ?? "";
                return item;
            });
            setDataList(_tiendas);
            setListWithResumen(_tiendas);
            setShowLazy(_data.data.length < LIMIT ? false : true);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay " + task + "!" : "¡No hay más " + task + "!");
        setShowLoading(false);
    }

    const handleOpenFilter = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setPos({ ...e.target.getBoundingClientRect().toJSON(), width: 180 });
        setShowFilter(true);
    }

    React.useEffect(() => {
        armaFilas();
    }, [dataList]);

    React.useEffect(() => {
        if (funciones.validaPermisos("user_cliente") && [1, 2].includes(parseInt(data_user.nivel))) {
            if (location.state && location.state.rol) {
                let _rol = location.state.rol;
                let _task = changeTask(_rol);

                setRoll(_rol);
                setTitulo("Lista de " + _task);
                setLoadResult("Cargando " + _task + "...");
                getList(currentQuery.clientes);
            } else {
                Alert("No se pasaron parámetros o la pagina se cargo de forma incorrecta",
                    "warning",
                    () => navigate("/dashboard"));
                setShowLoading(false);
            }
        }
    }, [location.state]);

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("user_cliente") && [1, 2].includes(parseInt(data_user.nivel))}>

            <div className="cont_principal">
                <TitleSticky className="_in_filters">Lista de {task}</TitleSticky>
                <div className="cont_search_bar">
                    <Filter className="fliter_top" onClick={handleOpenFilter} />
                    <div className="search_bar">
                        <SearchUsers type="text" placeholder="Buscar por tienda o representante"
                            value={currentQuery.clientes.criterio}
                            onChange={handleSearch}
                            onClean={() => {
                                setCurrentQuery(v => ({ ...v, clientes: { task: "pay_clients", criterio: "", filtro: "" } }));
                                getList({ task: "pay_clients", criterio: "", filtro: "" });
                            }}
                        />
                    </div>
                </div>

                {rows}

                {showLoading ?
                    <button className="btn_lazy_load"><Icon.Loading /></button>
                    :
                    <>
                        {showLazy ?
                            <button className="btn_lazy_load" onClick={() => getListLazy()}>Cargar más</button>
                            :
                            <h4 className="h4_lazy_load">{loadResult}</h4>
                        }

                        <h4 className="_button" onClick={handleOpen}>Crear Nueva Tienda<PlugIcon /></h4>
                    </>
                }
            </div>

            {showFilter && <MovePopUp
                parent={pos}
                close={handleClose}
                className="win_modal _overflow _for_color"
                title="Filtrar">

                <button onClick={() => {
                    setCurrentQuery(v => ({ ...v, clientes: { task: "pay_clients", criterio: "", filtro: "" } }));
                    getList({ task: "pay_clients", criterio: "", filtro: "" });
                }} type="button"><span style={{ backgroundColor: "#000" }}></span>Todos</button>
                <button onClick={e => handleFilter(e, 0)} type="button"><span></span>Sin entregas</button>
                <button onClick={e => handleFilter(e, 1)} type="button"><span style={{ backgroundColor: "rgba(250, 221, 60, 0.8)" }}></span>Por pagar</button>
                <button onClick={e => handleFilter(e, 2)} type="button"><span style={{ backgroundColor: "#f5320d" }}></span>Por cobrar</button>
            </MovePopUp>}

            {showLoading && <Loading />}
        </RequirePermissions>
    )
}

export default withRouter(Clientes);
