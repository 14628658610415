import * as React from "react";
import ImagePlusIcon from "mdi-react/ImagePlusIcon";
import { DropFiles, DropZone } from "./DropZone";
import { Card, CardBody, Col } from "reactstrap";
import LoadingIcon from "mdi-react/LoadingIcon";
import { uploadImage } from "services/Uploader";
import ClipIcon from "mdi-react/PaperclipIcon";
import { alerta, alertaRemove } from "./Alert";
import VisorImg from "shared/VisorImg";
import { Api } from "services/api";
import { useMedia } from "hooks";
import log from "services/log";
import resize from "./Resize";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const FileUpload = ({ codigo, onChange, attach }) => {
    const [files, setFiles] = React.useState([]);
    const [filesToUpload, setFilesToUpload] = React.useState([]);
    const [currentProgress, setCurrentProgress] = React.useState({ value: 0, file: "" });
    const [loading, setLoading] = React.useState(false);
    const alertRef = React.useRef(null);

    const { data_user } = useMedia();
    const api = React.useRef(new Api());


    const validaEnvio = () => {
        let allFiles = [];
        let _urls = "";
        alertaRemove(alertRef.current);

        if (filesToUpload.length < 1) {
            alerta(alertRef.current, "No hay archivos para subir! ");
        } else {
            setLoading(true);

            filesToUpload.forEach(async file => {
                const data = new FormData();
                const imgName = file.nameinserver;

                setCurrentProgress({
                    file: imgName,
                    value: Math.round(1)
                });

                resize(file, async image => {

                    if (image !== null) {
                        data.append('image', image);
                        data.append('nombre', imgName.trim());
                        data.append('destino', "packs");
                        data.append('size', file.size);
                    }

                    await uploadImage(data, data_user, api.current).then(response => {
                        log.d("size",
                            "\n\nold: " + (file.size / 1024).toFixed(2).replace(".", ",") + " KB" +
                            "\nnew: " + (response.size / 1024).toFixed(2).replace(".", ",") + " KB");
                        allFiles.push(response);

                        if (allFiles.length == filesToUpload.length) {

                            setFiles(allFiles);

                            allFiles.forEach(f => {
                                log.d("file", f);
                                _urls += f.nameinserver + "|" + f.url + ",";
                            });

                            setCurrentProgress({
                                file: imgName,
                                value: Math.round(100)
                            });

                            onChange(_urls.slice(0, -1));
                            setFilesToUpload([]);
                            setLoading(false);

                            if (typeof android !== 'undefined') {

                                setTimeout(() => {
                                    // eslint-disable-next-line no-undef
                                    android.eliminaArchivo(file.name);
                                }, 3000);
                            }
                        }

                    }).catch(error => {
                        alerta(alertRef.current, error);
                        setLoading(false);
                    });
                });
            });
        }
    }

    const handleDeleteFile = (file) => {
        if (file) {

            if (file.isUpdate) {

                files.forEach((f, i) => {
                    let newFiles = files.filter(f => f.nameinserver != file.nameinserver);
                    setFiles(newFiles);
                });

            } else {
                let fileName = file.nameinserver;
                let newFiles = files.filter(f => f.nameinserver != fileName);
                let _urls = "";

                log.i("File deleted", fileName);

                setFiles(newFiles);

                newFiles.forEach(f => {
                    _urls += f.url + ",";
                });

                onChange(_urls.slice(0, -1));
            }
        }
    }

    React.useEffect(() => {
        if (attach) {
            setFiles(attach);
        }
    }, [attach]);

    React.useEffect(() => {
        if (filesToUpload.length > 0) {
            validaEnvio();
            // setFiles(filesToUpload);
        }
    }, [filesToUpload]);

    return (
        <Col style={{ position: "relative" }}>
            <Card className="card--not-full-height">
                <CardBody className="width_static">
                    <div className="form">
                        {loading ?
                            <div className="dropzone dropzone--single">
                                <div className="dropzone__input dropzone__loading">
                                    <i className="progress">{currentProgress.value}%</i>
                                    <i className="file">{currentProgress.file}</i>
                                    <LoadingIcon className="on_refresh" />
                                </div>
                            </div>
                            :
                            <DropFiles
                                codigo={codigo}
                                onChange={setFilesToUpload} onDelete={handleDeleteFile}
                                files={files} />
                        }

                    </div>
                </CardBody>
            </Card>
            <div className="dropzone__alert">
                <input type="hidden" ref={alertRef} />
            </div>
        </Col>
    );
}

export default FileUpload;

export const FileUploadImg = ({ img, codigo, loading, onStart, onUpload, onError }) => {
    const { data_user } = useMedia();
    const api = React.useRef(new Api());

    const handleSelectFile = async (e) => {

        if (e.target.files.length > 0) {
            const data = new FormData();
            const file = e.target.files[0];
            let imgName = file.name;
            let _ext = imgName.split(".");
            _ext = _ext[_ext.length - 1];
            imgName = `EVIDENCE_${codigo}_${(new Date().getTime() / 1000).toFixed(0)}.${_ext}`;

            onStart();

            resize(file, async image => {

                if (image !== null) {
                    data.append('image', image);
                    data.append('nombre', imgName.trim());
                    data.append('destino', "packs");
                    data.append('size', file.size);
                }

                await uploadImage(data, data_user, api.current).then(imagen => {
                    log.d("size",
                        "\n\nold: " + (file.size / 1024).toFixed(2).replace(".", ",") + " KB" +
                        "\nnew: " + (imagen.size / 1024).toFixed(2).replace(".", ",") + " KB");
                    onUpload({
                        url: imagen.url,
                        name: imgName.trim(),
                        realname: imagen.name
                    });
                }).catch(error => {
                    onError("Error: " + error);
                });
            });
        }
    }

    return (
        <>
            {img && img.map((f, i) => <i key={i} className="name_img">{f.realname.length > 30 ? f.realname.substring(0, 15) + "..." + f.realname.substring(f.realname.length - 10) : f.realname}</i>)}

            <label htmlFor="file_img" className="label_icon">{loading ? <LoadingIcon className="on_refresh" /> : <ImagePlusIcon />}</label>
            <input type="file" accept=".jpg,.jpeg,.png,.gif" name="file_img" id="file_img" onChange={handleSelectFile} />
        </>
    );
}

export const FileUploadClip = ({ loading, onStart, onUpload }) => {
    const { data_user } = useMedia();
    const api = React.useRef(new Api());

    const handleSelectFile = async (e) => {
        if (e.target.files.length > 0) {
            const data = new FormData();
            const file = e.target.files[0];
            const imgName = file.name;

            onStart();

            resize(file, async image => {

                if (image !== null) {
                    data.append('image', image);
                    data.append('nombre', imgName.trim());
                    data.append('destino', "chat");
                    data.append('size', file.size);
                }

                await uploadImage(data, data_user, api.current).then(imagen => {
                    // log.i("url", imagen.url);
                    log.d("size",
                        "\n\nold: " + (file.size / 1024).toFixed(2).replace(".", ",") + " KB" +
                        "\nnew: " + (imagen.size / 1024).toFixed(2).replace(".", ",") + " KB");
                    onUpload({ url: imagen.url, name: imgName });
                }).catch(error => {
                    log.e("Error: ", error);
                });
            });
        }
    }

    return (
        <>
            <label htmlFor="file_clip">{loading ? <LoadingIcon className="on_refresh" /> : <ClipIcon />}</label>
            <input type="file" accept=".jpg,.jpeg,.png,.gif,.pdf,.xls,.xlsx" name="file_clip" id="file_clip" onChange={handleSelectFile} />
        </>
    );
}

export const ViewImage = ({ data }) => {
    const [pathImg, setPathImg] = React.useState(data.imagen_url);

    const handleZoom = (e) => {
        e.preventDefault();

        let _new_file = new File([data.imagen_url], data.imagen, { type: "image/" + data.imagen.split('.').pop() });

        _new_file = Object.assign(_new_file, { preview: data.imagen_url });

        VisorImg([_new_file]);
    }

    React.useEffect(() => {
        if (data.imagen_url == null && data.imagen != null) {

            log.i("url", data.imagen_url);
            setPathImg(data.imagen_url);
        }
    }, [data]);

    return (
        <img
            src={pathImg}
            alt="imagen enviada"
            onClick={handleZoom} />
    );
}

export const DropFile = React.forwardRef(({ fileName, onChange, attach }, filesRef) => {
    const [files, setFiles] = React.useState([]);
    const [filesToUpload, setFilesToUpload] = React.useState([]);


    const handleDeleteFile = (file) => {
        if (file) {
            let newFiles = files.filter(f => f.nameinserver != file.nameinserver);
            onChange(newFiles);
        }
    }

    React.useEffect(() => {
        if (attach) {
            setFiles(attach);
        }
    }, [attach]);

    React.useEffect(() => {
        if (filesToUpload.length > 0) {
            setFiles(filesToUpload);
            onChange(filesToUpload);
            alertaRemove(filesRef.current);
        }
    }, [filesToUpload]);

    return (
        <Col style={{ position: "relative" }}>
            <Card className="card--not-full-height">
                <CardBody className="width_static">
                    <div className="form">
                        <DropZone
                            fileName={fileName}
                            onChange={setFilesToUpload}
                            onDelete={handleDeleteFile}
                            files={files} />
                    </div>
                </CardBody>
            </Card>

            <div className="dropzone__alert">
                <input type="hidden" ref={filesRef} />
            </div>
        </Col>
    )
});