import * as React from "react";
import Information from 'mdi-react/InformationOutlineIcon';
import { useNavigate } from 'react-router-dom';
import { MovePopUp } from "./MovePopUp";
import PropTypes from "prop-types";
import { Api } from "services/api";
import { useMedia } from "hooks";
import { Alert } from "./Alert";
import { Input } from "./Input";
import * as Icon from "images";
import log from "services/log";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Filter = (params) => {
    const { parent, close, call, filter, currentFilterOptions } = params;
    const navigate = useNavigate();
    const { data_user, setDataUser } = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [userList, setUserList] = React.useState([{ id_user: 0, nombre: "Cargando..." }]);
    const [showLoading, setShowLoading] = React.useState(true);
    const [_estados] = React.useState(filter.statusList ?? [
        "Todos",
        "OK",
        "OK.DV",
        "KO.DOM",
        "KO.DOM.PAGO",
        "KO.CANCELADO",
        "KO.REPROGRAMADO",
        "Para salir",
        "Por entregar",
        "En bodega",
        "dev.pendiente"
    ]);
    const [courierList, setCourierList] = React.useState([]);
    const [consigneeId, setConsigneeId] = React.useState("");
    const [clientList, setClientList] = React.useState([]);
    const [courierId, setCourierId] = React.useState("");
    const [consignee, setConsignee] = React.useState("");
    const [statusId, setStatusId] = React.useState("0");
    const [courier, setCourier] = React.useState("");
    const [status, setStatus] = React.useState("");
    const [error, setError] = React.useState("");

    const form_ref = React.useRef();
    const _all_states = [
        { int: "-1", nombre: "Todos" },
        { int: "0", nombre: "Sin asignar" },
        { int: "1", nombre: "Por recoger" },
        { int: "2", nombre: "Recogido" },
        { int: "3", nombre: "por.recolectar" },
        { int: "31", nombre: "en.recolecta" },
        { int: "32", nombre: "error.dirección" },
        { int: "33", nombre: "recolecta.cancelada" },
        { int: "4", nombre: "Para salir" },
        { int: "5", nombre: "Por entregar" },
        { int: "6", nombre: "OK" },
        { int: "7", nombre: "KO.CANCELADO" },
        { int: "8", nombre: "KO.DOM" },
        { int: "9", nombre: "OK.DV" },
        { int: "10", nombre: "KO.CF" },
        { int: "11", nombre: "Borrado" },
        { int: "12", nombre: "KO.DOM.PAGO" },
        { int: "13", nombre: "KO.REPROGRAMADO" },
        { int: "14", nombre: "En bodega" },
        { int: "15", nombre: "dev.pendiente" },
        { int: "16", nombre: "Devuelto" },
        { int: "17", nombre: "Pagado" },
        { int: "18", nombre: "OK_Pagado" }
    ];


    const handleClose = (e) => {
        e.preventDefault();
        document.body.style.overflow = "auto";
        close(false);
    }

    const clearFields = (e) => {
        e.preventDefault();

        setError("");

        call({
            estado: "",
            consignee: "",
            carrier: ""
        });

        document.body.style.overflow = "auto";
        close(false);
    }

    const validateFields = (e) => {
        e.preventDefault();

        setError("");
        log.d("datos a enviar", {
            search: "1",
            estadoName: status,
            estado: statusId,
            consigneeName: consignee,
            consignee: consigneeId,
            carrierName: courier,
            carrier: courierId,
        });
        if (status?.length < 1 && courierId?.length < 1 && consigneeId?.length < 1) {
            setError("Debe agregar al menos una opción");
            return;
        }

        call({
            search: "1",
            estadoName: status,
            estado: statusId || "",
            consigneeName: consignee,
            consignee: consigneeId || "",
            carrierName: courier,
            carrier: courierId || "",
        });
        document.body.style.overflow = "auto";
        close(false);
    }

    const setValues = (_tipo, _val) => {
        let item = userList.filter(({ id_user }) => id_user == _val);
        item = item[0];

        switch (_tipo) {
            case "courier":
                setCourierId(_val);

                if (typeof item === "object") {
                    setCourier(item.nombre + " " + item.apellido);
                } else {
                    setCourier("");
                }
                break;
            case "consignee":
                setConsigneeId(_val);

                if (typeof item === "object") {
                    setConsignee(item.nombre + " " + item.apellido);
                } else {
                    setConsignee("");
                }
                break;
            case "status":
                let _state_name = "";

                _all_states.map(obj => {
                    if (_val == obj.int) {
                        _state_name = obj.nombre;
                    }
                });

                setStatus(_state_name);
                setStatusId(_val);

                break;
        }
    }

    const changeUser = (_tipo, _val) => {

        let item = userList.filter(({ nombre, apellido }) => nombre + " " + apellido === _val);
        item = item[0];


        switch (_tipo) {
            case "courier":
                setCourier(_val);

                if (typeof item === "object") {
                    setCourierId(item.id_user);
                } else {
                    setCourierId("");
                }
                break;
            case "consignee":
                setConsignee(_val);

                if (typeof item === "object") {
                    setConsigneeId(item.id_user);
                } else {
                    setConsigneeId("");
                }
                break;
            case "status":
                let _state_int = "0";

                _all_states.map(obj => {
                    if (_val === obj.nombre) {
                        _state_int = obj.int;
                    }
                });

                setStatus(_val == "" ? "Todos" : _val);
                setStatusId(_state_int);

                break;
        }

    }

    const getUsersList = async () => {
        setShowLoading(true);
        let _data = await api.current.fetchJson({
            url: "get-users",
            data: {
                roll: "trio"
            }
        });
        let _users = [];
        let _user_list = [];
        let _courier_list = [];


        if (_data.response == 1) {

            _data.data.map(item => {
                let _nivel = parseInt(item.nivel);

                if (_nivel > 2) {
                    _users.push(item);

                    if (_nivel == 6) {
                        _user_list.push(item.nombre + " " + item.apellido);
                    }

                    if (_nivel == 4) {
                        _courier_list.push(item.nombre + " " + item.apellido);
                    }
                }
            });

            setUserList(_users);
            setClientList(_user_list);
            setCourierList(_courier_list);

        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);

        return _users;
    }

    React.useEffect(() => {
        if (userList.length > 1) {
            setValues("consignee", currentFilterOptions.consignee);
            setValues("courier", currentFilterOptions.carrier);
        }
    }, [userList]);

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        log.d("currentFilterOptions", currentFilterOptions);

        if (filter.loadUsersList) {
            getUsersList();
        } else {
            setShowLoading(false);
        }

        setValues("status", currentFilterOptions.estado);
    }, []);

    return (
        <MovePopUp
            parent={parent}
            close={handleClose}
            className="win_modal _overflow"
            title="Filtro Personalizado">

            <form ref={form_ref} onSubmit={e => e.preventDefault()}>

                <div className="_in_column">

                    {(filter.showClient && data_user.nivel < 6) && <div className="_in_column">
                        <label className="__titulo">Tienda</label>
                        <Input type="text" name="consignee" value={consignee} list={clientList}
                            onChange={(val) => changeUser("consignee", val)} />
                    </div>}

                    {(filter.showCarrier && data_user.nivel < 4) && <div className="_in_column">
                        <label className="__titulo">Mensajero</label>
                        <Input type="text" name="courier" value={courier} list={courierList}
                            onChange={(val) => changeUser("courier", val)} />
                    </div>}

                    {filter.showStatus && <div className="_in_column">
                        <label className="__titulo">Estado</label>
                        <Input type="text" name="status" value={status} list={_estados} placeholder="Todos"
                            onChange={(val) => changeUser("status", val)} />
                    </div>}
                </div>

            </form>
            {error.length > 1 && <h4 className="_error"><Information />{error}</h4>}

            <div className="cont_botones">

                {showLoading ?
                    <>
                        <button className="btn btn-common width-static _all"><Icon.Loading /></button>
                        <button className="btn btn-common width-static"><Icon.Loading /></button>
                    </>
                    :
                    <>
                        {((currentFilterOptions.consignee === undefined &&
                            currentFilterOptions.carrier === undefined &&
                            currentFilterOptions.estado === undefined) ||
                            (currentFilterOptions.consignee == "" &&
                                currentFilterOptions.carrier == "" &&
                                currentFilterOptions.estado == "")) ?
                            <button className="btn btn-common width-static _all _des">Todos</button>
                            :
                            <button className="btn btn-common width-static _all" onClick={clearFields}>Todos</button>
                        }
                        <button className="btn btn-common width-static" onClick={validateFields}>Filtrar</button>
                    </>
                }

            </div>
        </MovePopUp>
    )
}

Filter.propType = {
    parent: PropTypes.shape({
        top: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
    }).isRequired,
    close: PropTypes.func.isRequired,
    call: PropTypes.func.isRequired
}

export default Filter;