import * as React from "react";
import DeleteIcon from "mdi-react/DeleteOutlineIcon";
import ChatIcon from "mdi-react/ChatOutlineIcon";
import OpenIcon from "mdi-react/OpenInNewIcon";
import { Alert, Confirm } from "shared/Alert";
import funciones from "services/funciones";
import { useMedia } from "hooks";
import { Api } from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const MenuRow = (params) => {
    const { navigate, data, blue, children, onSel, reload, current } = params;
    const { media_movil, data_user, setDataUser } = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [clases, setClases] = React.useState(blue == 0 ? "table_row bkg_blue" : "table_row");
    const [_pos, set_pos] = React.useState({ top: "0", left: "0" });
    const [showMenu, setShowMenu] = React.useState(false);
    const [_e, set_e] = React.useState();
    const menuRef = React.useRef();
    const row = React.useRef(null);


    const goOpen = (e) => {
        e.preventDefault()
        e.stopPropagation();
        document.body.style.overflow = "auto";
        navigate("/dashboard/orden-de-recogida", { state: { data: data } });
    }

    const goChat = (e) => {
        e.preventDefault()
        e.stopPropagation();
        document.body.style.overflow = "auto";
        navigate("/dashboard/chat",
            {
                state: {
                    data: {
                        guest: {
                            id_user: data_user.nivel == 6 ? data.carrier : data.consignee,
                            nombre: data_user.nivel == 6 ? data.carrier_name : data.consignee_nombre,
                            apellido: data_user.nivel == 6 ? "" : data.consignee_apellido
                        },
                        fromOutside: true
                    }
                }
            });
    }

    // const goEdit = (e) => {
    //     e.preventDefault()
    //     e.stopPropagation();

    //     history.push({
    //         pathname: "/editar-mi-orden",
    //         state: { data: data }
    //     });
    // }

    const goDelete = (e) => {
        hiddenMenu(e);

        Confirm(`Seguro de borrar la orden Nº ${data.consecutivo}?`, async (response) => {
            if (response) {
                let _data = await api.current.deleteOrder({
                    id_order: data.id,
                    tipo: "orden de recogida"
                });

                if (_data.response == 1) {
                    document.body.style.overflow = "auto";
                    reload();
                } else if (_data.response === -2) {
                    Alert(_data.msg, "warning", () => {
                        document.body.style.overflow = "auto";
                        navigate("/sign-in");
                    });
                } else {
                    Alert(_data.msg, "warning");
                }
            }
        });

    }

    const onSelect = (e) => {
        e.preventDefault()
        e.stopPropagation();

        onSel(data);
    }

    const stopClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const hiddenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        document.body.style.overflow = "auto";
        setShowMenu(false);
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        set_e(e);
        document.body.style.overflow = "hidden";
        setShowMenu(true);
    }

    const BtnDelete = () => {
        let _btn = <li className="unable"><DeleteIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Borrar</span></li>;

        if ([0, 1].includes(parseInt(data.estado))) {
            _btn = <li onClick={goDelete}><DeleteIcon /> <span>Borrar</span></li>;
        }

        return (_btn);
    }

    const BtnChat = () => {
        let _btn = <li className="unable"><ChatIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Abrir chat</span></li>;

        if (data_user.nivel < 4 && data.consignee != null) {
            _btn = <li onClick={goChat}><ChatIcon /> <span>Abrir chat</span></li>;
        }

        if (data_user.nivel == 6 && data.carrier != null) {
            _btn = <li onClick={goChat}><ChatIcon /> <span>Abrir chat</span></li>;
        }

        return (_btn);
    }

    React.useEffect(() => {
        if (showMenu) {
            set_pos(funciones.getPositonDisplay(_e, menuRef.current));
        }
    }, [showMenu]);

    React.useEffect(() => {
        current === data.id && setClases(c => c + " bkg_select");
    }, [current]);

    return (
        <tr className={clases} ref={row}
            onDoubleClick={goOpen}
            onClick={onSelect}
            onContextMenu={handleContextMenu}>

            {children}
            <td className="rowMenu" onClick={stopClick} onDoubleClick={stopClick} onContextMenu={stopClick}>
                {showMenu &&
                    <>
                        <div className="back_form _bkg_black" onClick={hiddenMenu} onContextMenu={hiddenMenu}></div>
                        <nav className="menu" style={{ ..._pos, width: media_movil ? 180 : 170 }} ref={menuRef}>
                            <ul>
                                <li onClick={goOpen}><OpenIcon /> <span><strong>Abrir</strong></span></li>
                                {/* <li onClick={goEdit}><EditIcon /> <span>Editar</span></li> */}
                                <BtnChat />
                                <hr />
                                <BtnDelete />
                            </ul>
                        </nav>
                    </>}

            </td>
        </tr>
    );

}

export default MenuRow;