import * as React from 'react';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import funciones from 'services/funciones';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
/* eslint eqeqeq: 0 */

const Menu = () => {
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };

    return (
        <ul className="_menu">
            {[6, 61].includes(parseInt(data_user.nivel)) ?
                <>
                    {funciones.validaGrupo(["company_recogidas", "company_recolectas", "company_entregas", "company_dev_pendientes", "company_dev_entregadas"]) && <li>
                        <span className="_item_menu">Paquetes
                            <ul className="_sub_menu">
                                {funciones.validaPermisos("company_recogidas") && <li>
                                    <Link className="_item_menu" to="/dashboard/mis-recogidas">Recogidas</Link>
                                </li>}

                                {funciones.validaPermisos("company_recolectas") && <li>
                                    <Link className="_item_menu" to="/dashboard/mis-recolectas">Recolectas</Link>
                                </li>}

                                {funciones.validaPermisos("company_entregas") && <li>
                                    <Link className="_item_menu" to="/dashboard/mis-entregas">Entregas</Link>
                                </li>}

                                {funciones.validaPermisos("company_dev_pendientes") && <li>
                                    <Link className="_item_menu" to="/dashboard/mis-devoluciones-pendientes">Dev. Pendientes</Link>
                                </li>}

                                {funciones.validaPermisos("company_dev_entregadas") && <li>
                                    <Link className="_item_menu" to="/dashboard/mis-devoluciones-entregadas">Dev. Entregadas</Link>
                                </li>}
                            </ul>
                        </span>
                    </li>}

                    {funciones.validaPermisos("company_users") && <li>
                        <Link className="_item_menu" to="/dashboard/tienda/usuarios" state={{ rol: 61 }}>Usuarios</Link>
                    </li>}

                    {funciones.validaPermisos("company_reporte") && <li>
                        <Link className="_item_menu" to="/dashboard/tienda/reportes">Caja y reportes</Link>
                    </li>}

                    {funciones.validaPermisos("company_gestiones") && <li>
                        <Link className="_item_menu" to="/dashboard/chat">Gestiones</Link>
                    </li>}
                </>
                :
                <>
                    {data_user.nivel < 4 ?
                        <>
                            {funciones.validaGrupo(["pack_recogidas", "recolectas_gestion", "pack_edicion", "pack_entregas"]) && <li>
                                <span className="_item_menu">Paquetes
                                    <ul className="_sub_menu">
                                        {funciones.validaPermisos("pack_recogidas") && <li>
                                            <Link className="_item_menu" to="/dashboard/ordenes-de-recogida">Recogidas</Link>
                                        </li>}

                                        {funciones.validaPermisos("recolectas_gestion") && <li>
                                            <Link className="_item_menu" to="/dashboard/todas-las-recolectas">Recolectas</Link>
                                        </li>}

                                        {funciones.validaPermisos("pack_edicion") && <li>
                                            <Link className="_item_menu" to="/dashboard/ordenes-de-recepcion">Recepción</Link>
                                        </li>}

                                        {funciones.validaPermisos("pack_entregas") && <li>
                                            <Link className="_item_menu" to="/dashboard/todos-los-paquetes">Entregas</Link>
                                        </li>}
                                    </ul>
                                </span>
                            </li>}

                            {funciones.validaGrupo(["genera_codigos", "reporte_cli", "reporte_msj", "caja_msj", "caja_cli", "caja_bts"]) && <li>
                                <span className="_item_menu">Administración
                                    <ul className="_sub_menu">

                                        {funciones.validaPermisos("genera_codigos") && <li>
                                            <Link className="_item_menu" to="/dashboard/generar-codigos">Generar Códigos</Link>
                                        </li>}

                                        {funciones.validaPermisos("reporte_cli") && <li>
                                            <Link className="_item_menu" to="/dashboard/reporte-clientes">Reporte Clientes</Link>
                                        </li>}

                                        {funciones.validaPermisos("reporte_msj") && <li>
                                            <Link className="_item_menu" to="/dashboard/reporte-mensajeros">Reporte Mensajeros</Link>
                                        </li>}

                                        {funciones.validaPermisos("caja_msj") && <li>
                                            <Link className="_item_menu" to="/dashboard/caja-mensajeros">Caja mensajeros</Link>
                                        </li>}

                                        {funciones.validaPermisos("caja_msj_aux") && <li>
                                            <Link className="_item_menu" to="/dashboard/caja-mensajeros-aux">Caja mensajeros</Link>
                                        </li>}

                                        {[1, 2].includes(parseInt(data_user.nivel)) && <li>
                                            <Link className="_item_menu" to="/dashboard/movimientos-caja-mensajeros">Caja mensajeros (movimientos)</Link>
                                        </li>}

                                        {funciones.validaPermisos("caja_cli") && <li>
                                            {funciones.validaPermisos("caja_cli_aux") ?
                                                <Link className="_item_menu" to="/dashboard/caja-clientes-aux">Caja clientes</Link>
                                                :
                                                <Link className="_item_menu" to="/dashboard/caja-clientes">Caja clientes</Link>
                                            }
                                        </li>}

                                        {funciones.validaPermisos("caja_bodega") && <li>
                                            <Link className="_item_menu" to="/dashboard/caja-bodega">Caja bodega</Link>
                                        </li>}

                                        {[1, 2].includes(parseInt(data_user.nivel)) && <li>
                                            <Link className="_item_menu" to="/dashboard/movimientos-caja-bodega">Caja bodega (movimientos)</Link>
                                        </li>}

                                        {funciones.validaPermisos("caja_bts") && <li>
                                            <Link className="_item_menu" to="/dashboard/caja-bts">Caja bts</Link>
                                        </li>}

                                        {funciones.validaPermisos("validador") && <li>
                                            <Link className="_item_menu" to="/dashboard/administrar-validador">Validador</Link>
                                        </li>}

                                        {[1, 2].includes(parseInt(data_user.nivel)) && <li>
                                            <Link className="_item_menu" to="/dashboard/validaciones-pendientes">Validaciones pendientes</Link>
                                        </li>}

                                        {funciones.validaPermisos("descargar_informe") && <li>
                                            <Link className="_item_menu" to="/dashboard/informes-cierre">Informes y cierre</Link>
                                        </li>}
                                    </ul>
                                </span>
                            </li>}

                            {funciones.validaGrupo(["user_admin", "user_coordi", "user_mensajero", "user_recepcionista", "user_cajero", "user_cliente"]) && <li>
                                <span className="_item_menu">Usuarios
                                    <ul className="_sub_menu">

                                        {(data_user.auth && window.atob(data_user.auth) === "1") && <li>
                                            <Link className="_item_menu" to="/dashboard/administradores" state={{ rol: 1 }}>Super Admin</Link>
                                        </li>}

                                        {funciones.validaPermisos("user_admin") && <li>
                                            <Link className="_item_menu" to="/dashboard/administradores" state={{ rol: 2 }}>Administradores</Link>
                                        </li>}

                                        {funciones.validaPermisos("user_coordi") && <li>
                                            <Link className="_item_menu" to="/dashboard/coordinadores" state={{ rol: 3 }}>Coordinadores</Link>
                                        </li>}

                                        {funciones.validaPermisos("user_mensajero") && <li>
                                            <Link className="_item_menu" to="/dashboard/mensajeros">Mensajeros</Link>
                                        </li>}

                                        {funciones.validaPermisos("user_recepcionista") && <li>
                                            <Link className="_item_menu" to="/dashboard/recepcionistas" state={{ rol: 5 }}>Recepcionistas</Link>
                                        </li>}

                                        {funciones.validaPermisos("user_cajero") && <li>
                                            <Link className="_item_menu" to="/dashboard/cajeros" state={{ rol: 8 }}>Cajeros</Link>
                                        </li>}

                                        {funciones.validaPermisos("user_cliente") && <li>
                                            <Link className="_item_menu" to="/dashboard/tiendas" state={{ rol: 6 }}>Tiendas</Link>
                                        </li>}
                                    </ul>
                                </span>
                            </li>}
                        </>
                        :
                        <>
                            {funciones.validaGrupo(["pack_recogidas", "recolectas_gestion", "pack_edicion", "pack_entregas"]) && <li>
                                <span className="_item_menu">Paquetes
                                    <ul className="_sub_menu">
                                        {funciones.validaPermisos("pack_recogidas") && <li>
                                            <Link className="_item_menu" to="/dashboard/ordenes-de-recogida">Recogidas</Link>
                                        </li>}

                                        {funciones.validaPermisos("recolectas_gestion") && <li>
                                            <Link className="_item_menu" to="/dashboard/todas-las-recolectas">Recolectas</Link>
                                        </li>}

                                        {funciones.validaPermisos("pack_edicion") && <li>
                                            <Link className="_item_menu" to="/dashboard/ordenes-de-recepcion">Recepción</Link>
                                        </li>}

                                        {funciones.validaPermisos("pack_entregas") && <li>
                                            <Link className="_item_menu" to="/dashboard/todos-los-paquetes">Entregas</Link>
                                        </li>}
                                    </ul>
                                </span>
                            </li>}

                            {funciones.validaGrupo(["reporte_cli", "reporte_msj"]) && <li>
                                <span className="_item_menu"> Reportes
                                    <ul className="_sub_menu">
                                        {funciones.validaPermisos("reporte_cli") && <li>
                                            <Link className="_item_menu" to="/dashboard/reporte-clientes">Reporte Clientes</Link>
                                        </li>}

                                        {funciones.validaPermisos("reporte_msj") && <li>
                                            <Link className="_item_menu" to="/dashboard/reporte-mensajeros">Reporte Mensajeros</Link>
                                        </li>}
                                    </ul>
                                </span>
                            </li>}

                            {funciones.validaGrupo(["ver_mi_caja", "caja_msj_aux", "caja_cli", "caja_msj", "caja_bodega"]) && <li>
                                {(funciones.validaPermisos("ver_mi_caja") && data_user.nivel == 4) ?
                                    <Link className="_item_menu" to="/dashboard/reporte-mi-caja">Mi Caja</Link>
                                    :
                                    <span className="_item_menu"> Cajas
                                        <ul className="_sub_menu">

                                            {funciones.validaPermisos("caja_msj_aux") && <li>
                                                <Link className="_item_menu" to="/dashboard/caja-mensajeros-aux">Caja mensajeros</Link>
                                            </li>}

                                            {funciones.validaPermisos("caja_cli") && <li>
                                                {funciones.validaPermisos("caja_cli_aux") ?
                                                    <Link className="_item_menu" to="/dashboard/caja-clientes-aux">Caja clientes</Link>
                                                    :
                                                    <Link className="_item_menu" to="/dashboard/caja-clientes">Caja clientes</Link>
                                                }
                                            </li>}

                                            {funciones.validaPermisos("caja_msj") && <li>
                                                <Link className="_item_menu" to="/dashboard/caja-mensajeros">Caja mensajeros</Link>
                                            </li>}

                                            {funciones.validaPermisos("caja_bodega") && <li>
                                                <Link className="_item_menu" to="/dashboard/caja-bodega">Caja bodega</Link>
                                            </li>}
                                        </ul>
                                    </span>
                                }
                            </li>}

                            {funciones.validaPermisos("validador") && <li>
                                <Link className="_item_menu" to="/dashboard/validador">Validador</Link>
                            </li>}

                        </>}

                    {funciones.validaPermisos("chat") && <li>
                        <Link className="_item_menu" to="/dashboard/chat">Chat</Link>
                    </li>}
                </>}
        </ul>
    )
}

const Topbar = ({ changeTheme, changeMobileSidebarVisibility }) => (
    <div className="topbar">

        <div className="topbar__wrapper">
            <div className="topbar__left">
                <TopbarSidebarButton changeMobileSidebarVisibility={changeMobileSidebarVisibility} />
                <Link className="topbar__logo" to="/dashboard" />
                <Menu />
            </div>
            <div className="topbar__right">
                <TopbarProfile changeTheme={changeTheme} />
            </div>
        </div>
    </div>
);

Topbar.propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
