import * as React from "react";
import withRouter from "services/withRouter";
import funciones from "services/funciones";
import { Link } from 'react-router-dom';
import BarCode from "shared/BarCode";
import { Alert } from "shared/Alert";
import Loading from "shared/Loading";
import { Api } from "services/api";
import { useMedia } from "hooks";
import Menu from "./Menu";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

const PickUpOrder = ({ navigate, location, params }) => {
    const { media_movil, setTitulo, data_user, setDataUser } = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [fecha_recogida, setFechaRecogida] = React.useState(new Date());
    const [fecha_creado, setFechaCreado] = React.useState(new Date());
    const [mensaje, setMensaje] = React.useState("Cargando...");
    const [showLoading, setShowLoading] = React.useState(true);
    const [collectPrice, setCollectPrice] = React.useState(0);
    const [collect, setCollect] = React.useState(false);
    const [packages, setPackages] = React.useState([]);
    const [piezas, setPiezas] = React.useState(0);
    const [vTotal, setVTotal] = React.useState(0);
    const [data, setData] = React.useState();


    const getPackagesList = async () => {
        let _list = [];
        let _total = 0;
        let _data = await api.current.getPackages(data.id);

        if (_data.response == 1) {

            _data.data.map(item => {
                _total += parseInt(item.price);
                _list.push(
                    <tr key={item.id}>
                        <td>
                            {item.codigo ?
                                <p><Link to={"/dashboard/detalle-paquete/" + item.codigo}>{item.codigo}</Link></p>
                                :
                                <p>pendiente</p>
                            }
                        </td>
                        <td>
                            <p>{item.descripcion}</p>
                        </td>
                        <td>
                            {!media_movil ?
                                <p>{item.price > 0 ? "$" + funciones.formateaPrecio(item.price) : "..."}</p>
                                :
                                <>
                                    <p>
                                        Producto: {item.price > 0 ? "$" + funciones.formateaPrecio(item.price) : "..."}
                                        {(data_user.nivel == 6 || data_user.nivel < 4) && <><br /> domicilio: {item.v_domicilio > 0 ? "$" + funciones.formateaPrecio(item.v_domicilio) : "..."}</>}
                                        {data_user.nivel <= 4 && <><br />mensajero: {item.v_mensajero > 0 ? "$" + funciones.formateaPrecio(item.v_mensajero) : "..."}</>}
                                    </p>
                                    <hr />
                                </>
                            }
                        </td>
                        {!media_movil && <>
                            {(data_user.nivel == 6 || data_user.nivel < 4) && <td>
                                <p>{item.v_domicilio > 0 ? "$" + funciones.formateaPrecio(item.v_domicilio) : "..."}</p>
                            </td>}
                            {data_user.nivel <= 4 && <td>
                                <p>{item.v_mensajero > 0 ? "$" + funciones.formateaPrecio(item.v_mensajero) : "..."}</p>
                            </td>}
                        </>}
                    </tr>
                );
            });

            if (_data.data.length < 8) {
                let _loop = (8 - _data.data.length);

                for (let i = 0; i < _loop; i++) {
                    _list.push(
                        <tr key={"le_" + i}>
                            <td>
                                <p>&nbsp;</p>
                            </td>
                            <td>
                                <p></p>
                            </td>
                            <td>
                                <p></p>
                            </td>
                            {!media_movil && <>
                                {(data_user.nivel == 6 || data_user.nivel < 4) && <td>
                                    <p></p>
                                </td>}
                                {data_user.nivel <= 4 && <td>
                                    <p></p>
                                </td>}
                            </>}
                        </tr>
                    );
                }
            }

            setVTotal(_total);
            setPiezas(_data.data.length);
            setPackages(_list);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getData = async (_codebar) => {
        setShowLoading(true);
        let toSend = _codebar.split("-");

        let _data = await api.current.fetchJson({
            url: "get-orders",
            data: {
                type: "por_codigo",
                consignee: toSend[0],
                consecutive: toSend[1]
            }
        });

        if (_data.response == 1) {
            if (_data.data.length > 0) {
                let _datos = _data.data[0];
                _datos.recogida = JSON.parse(_datos.recogida);

                setData(_datos);
                setFechaCreado(new Date(_datos.date_sistem));
                setFechaRecogida(new Date(_datos.recogida.fecha + "T05:00:00"));

                if (parseInt(_datos.recogida.valor_producto) > 1) {
                    setCollect(true);
                    setCollectPrice(funciones.formatPrice(_datos.recogida.valor_producto).format);
                }
            } else {
                setData();
                setMensaje(`No se encontró ningún paquete asignado al código "${_codebar}"`);
            }
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const handleReload = (_codebar) => {
        setMensaje("Cargando...");
        setShowLoading(true);
        setPackages([]);
        getData(_codebar);
    }

    React.useEffect(() => {
        if (data) {
            getPackagesList();
        }
    }, [data]);

    React.useEffect(() => {
        document.body.style.overflow = "auto";
        if (params.codigo) {
            setTitulo("Orden de recogida " + params.codigo);
            getData(params.codigo);
        } else {
            if (location.state) {
                let _data = location.state.data;
                setTitulo("Orden de recogida " + _data.consecutivo);
                getData(_data.consecutivo);
            } else {
                navigate("/dashboard/ordenes-de-recepcion");
            }
        }
    }, [params.codigo, location.pathname]);

    return (
        <>
            {data ?
                <Menu data={data} navigate={navigate} reload={() => handleReload(data.consecutivo)}>
                    <div className="t_header">

                        <div className="_columna">
                            <h3>Orden de recogida</h3>
                            <h5>Fecha de creación: {fecha_creado.getDate()} de {meses[fecha_creado.getMonth()]} del {fecha_creado.getFullYear()}</h5>
                            {data.recogida.carrier ?
                                <h5>Mensajero: <b>{window.atob(data_user.auth) == data.recogida.carrier ? "( tú )" : ""} {data.carrier_name}</b></h5>
                                :
                                <h5><b>Sin mensajero asignado</b></h5>
                            }
                            <h5 className="estado">Estado: {funciones.getStateSpan(data)}</h5>
                        </div>

                        <div className="_columna">
                            <BarCode code={"OR-" + data.consecutivo} />
                        </div>

                    </div>

                    <h4 style={{ padding: 0 }}>
                        <span>Información de recogida</span>
                        <span>Nota</span>
                    </h4>

                    <div className="_tabla">

                        <div className="columna">
                            <div className="cont_info">

                                <p style={{ marginTop: 6, marginBottom: 8 }}>Fecha de recogida: {media_movil && <br />} <b>{fecha_recogida.getDate()} de {meses[fecha_recogida.getMonth()]} del {fecha_recogida.getFullYear()}</b></p>
                                <p>Cliente bts: {media_movil && <br />} <b>{data.consignee_name}</b></p>
                                {data.consignee_name !== data.pickup_name && <p>Contacto: {media_movil && <br />} <b>{data.recogida.contacto}</b></p>}
                                <p>Direción: {media_movil && <br />} <b>{data.recogida.direccion}</b></p>

                                {collect && <h5 className="_collect" style={{ marginLeft: 12, marginTop: 18 }}>Esta orden tiene un cobro de recolección de ${collectPrice}</h5>}

                            </div>
                        </div>

                        <div className="columna">
                            <div className="cont_info">

                                <p className="notas">{data.nota}</p>

                            </div>
                        </div>

                    </div>

                    {data_user.nivel < 4 && <>
                        <h4 style={{ padding: 0 }}>
                            Información del mensajero
                        </h4>
                        <div className="_tabla">

                            <div className="fila" style={{ borderRight: "1px solid #2e2e2e" }}>

                                <div className="izquierda">
                                    <div className="_th">Nombre</div>
                                    <div className="_th">Teléfono</div>
                                </div>

                                <div className="derecha">
                                    <div className="_th">{data.carrier_name} <span>&nbsp;</span></div>
                                    <div className="_th">{data.carrier_phone} <span>&nbsp;</span></div>
                                </div>

                            </div>

                        </div>
                    </>}

                    <table className="t_resumen">
                        <tbody style={{ width: "110%" }}>
                            <tr>
                                <th>
                                    <p className="_th">Código</p>
                                </th>
                                <th>
                                    <p className="_th">Notas</p>
                                </th>
                                <th>
                                    <p className="_th">{!media_movil ? "V. Producto" : "Valores"}</p>
                                </th>
                                {!media_movil && <>
                                    {(data_user.nivel == 6 || data_user.nivel < 4) && <th>
                                        <p className="_th">V. domicilio</p>
                                    </th>}
                                    {data_user.nivel <= 4 && <th>
                                        <p className="_th">V. mensajero</p>
                                    </th>}
                                </>}
                            </tr>

                            {packages}

                        </tbody>
                    </table>

                    <div className="t_footer">

                        <div className="observaciones">
                            <p>Firma: <span>_____________________________________________________________________________</span></p>
                        </div>

                        <div className="peso">

                            <div className="colunmas" >
                                <div className="_th">Cantidad</div>
                                <p>{piezas}</p>
                            </div>

                            <div className="colunmas" >
                                <div className="_th">Valor total</div>
                                <p>{vTotal > 0 ? "$" + funciones.formateaPrecio(vTotal) : "..."}</p>
                            </div>

                        </div>

                    </div>
                </Menu>
                :
                <div className="main_container">
                    <h2 style={{ textAlign: "center", paddingTop: 64, paddingBottom: 82 }}>{mensaje}</h2>
                </div>
            }
            {showLoading && <Loading />}
        </>
    )
}

export default withRouter(PickUpOrder);