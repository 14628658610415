import * as React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { MediaProvider } from "./Contexts";
import "rc-time-picker/assets/index.css";
import Layout from "../view/Layout";
// import Header from "shared/Header";
import Footer from "shared/Footer";

// import Home from "../view/Home";
import Dashboard from "../view/Dashboard";

import LogIn from "../view/LogIn";
import LogInAdmin from "../view/LogIn/components/LogInAdmin";
import ForgotPassword from "../view/ForgotPassword";

import MyPickUps from "../view/MyPickUps";
import MyNewPickUp from "../view/MyPickUps/components/NewPickUp";
import EditMyPickUp from "../view/MyPickUps/components/EditPickUp";

import PickUpList from "../view/PickUp";
import PickUpHistory from "../view/PickUp/PickUpHistory";
import NewPickUp from "../view/PickUp/components/NewPickUp";
import EditPickUp from "../view/PickUp/components/EditPickUp";
import PickUpOrder from "../view/PickUp/components/PickUpOrder";

import Recolectas from "../view/CommodityList/Recolectas";

import ReceptionList from "../view/Reception";
import NewReception from "../view/Reception/components/NewReception";
import EditReception from "../view/Reception/components/EditReception";
import Reception from "../view/Reception/components/ReceptionOrder";

import CommodityList from "../view/CommodityList";
import MisPaquetes from "../view/CommodityList/MisPaquetes";
import MisRecolectas from "../view/CommodityList/MisRecolectas";
import MisDevolicionesPendientes from "../view/CommodityList/MisDevolicionesPendientes";
import MisDevolicionesEntregadas from "../view/CommodityList/MisDevolicionesEntregadas";
import Detalle from "../view/CommodityList/components/Detalle";
import DetalleAndroid from "../view/CommodityList/components/DetalleAndroid";
import EditPackage from "../view/Reception/components/EditPackage";

import Mensajeros from "../view/Mensajeros";
import Usuarios from "../view/Usuarios";
import Clientes from "../view/Tienda";

import Chat from "../view/Chat";
import VistaChat from "../view/Chat/components/VistaChat";

import MiCaja from "../view/Reports/MiCaja";
import ReportTienda from "../view/Reports/ReportTienda";
import ReportClient from "../view/Reports/ReportClient";
import ReportCarrier from "../view/Reports/ReportCarrier";
import ReportHistory from "../view/Reports/ReportHistory";

import CodeGenerator from "../view/CodeGenerator";

import CajaMensajerosMovimientos from "../view/CajaMensajeros/ListaMovimientos";
import CajaMensajeros from "../view/CajaMensajeros";
import CajaMsjAux from "../view/CajaMensajeros/CajaMsjAux";

import CajaClientes from "../view/CajaClientes";
import CajaCliAux from "../view/CajaClientes/CajaCliAux";
import CajaBts from "../view/CajaBts";

import Validador from "../view/Validador";
import ValidadorAdmin from "../view/Validador/Aministrador";
import ValidacionesPendientes from "../view/Validador/ValidacionesPendientes";

import CajaBodega from "../view/CajaBodega";
import ListaMovimientos from "../view/CajaBodega/ListaMovimientos";

import Informes from "../view/Informes";

// Otros
import Help from "../view/Help";
import NoMatch from 'shared/NoMatch';
import Forbidden from "shared/Forbidden";
import IconTest from "../images/IconTest";
import TerminosCondiciones from "../view/TerminosCondiciones";
import AddClient from "../view/Tienda/components/AddClient";
import EditClient from "../view/Tienda/components/EditClient";

// Tienda
import TiendaUsuarios from "../view/Tienda/Usuarios";
import TiendaPerfil from "../view/Tienda/TiendaPerfil";
import EditPerfil from "../view/Tienda/EditPerfil";
import EditPerfilAdmin from "../view/Tienda/EditPerfilAdmin";
import TiendaAddPackage from "../view/Tienda/components/AddPackage";
import TiendaEditPackage from "../view/Tienda/components/EditPackage";
import PrintLabel from "../view/CommodityList/components/PrintLabel";
import { useMedia } from "hooks";

/* eslint eqeqeq: 0 */

export default function Router() {

    const handleTheme = (_theme) => {
        // Obtener el tema actual
        // const currentTheme = document.documentElement.getAttribute('data-theme');

        // Alternar entre temas
        // const _theme = currentTheme === 'theme-dark' ? 'theme-light' : 'theme-dark';

        // Aplicar el nuevo tema
        document.documentElement.setAttribute('data-theme', _theme);

        // Guardar la preferencia en localStorage
        // localStorage.setItem("theme", _theme);
    }

    // const LandingRoutes = (params) => {
    //     return (
    //         <>
    //             <Header {...params} />
    //             <main className="container__wrap">
    //                 <Outlet />
    //             </main>
    //         </>
    //     );
    // }

    const DashboardRoutes = (params) => {
        const { modalView } = useMedia();
        return (
            <>
                <Layout {...params} />
                <main className="container__wrap">
                    <Outlet />
                </main>
                {modalView}
            </>
        )
    }

    return (
        <MediaProvider>
            <Routes>

                <Route path="/" element={<DashboardRoutes changeTheme={(_theme) => handleTheme(_theme)} />}   >
                    {/* <Route path="/" element={<Home />} /> */}
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/home" element={<Dashboard />} />

                    <Route path="/ayuda" element={<Help />} />
                    <Route path="/icons-in-proyect" element={<IconTest />} />
                    <Route path="/terminos-y-condiciones" element={<TerminosCondiciones />} />

                    <Route path="/forbidden" element={<Forbidden />} />
                    <Route path="*" element={<NoMatch />} />
                </Route>

                <Route path="/dashboard" element={<DashboardRoutes changeTheme={(_theme) => handleTheme(_theme)} />}   >
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/dashboard/informes-cierre" element={<Informes />} />

                    <Route path="/dashboard/caja-mensajeros-aux" element={<CajaMsjAux />} />
                    <Route path="/dashboard/caja-mensajeros" element={<CajaMensajeros />} />
                    <Route path="/dashboard/movimientos-caja-mensajeros" element={<CajaMensajerosMovimientos />} />

                    <Route path="/dashboard/caja-clientes-aux" element={<CajaCliAux />} />
                    <Route path="/dashboard/caja-clientes" element={<CajaClientes />} />
                    <Route path="/dashboard/caja-bodega" element={<CajaBodega />} />
                    <Route path="/dashboard/movimientos-caja-bodega" element={<ListaMovimientos />} />
                    <Route path="/dashboard/caja-bts" element={<CajaBts />} />

                    <Route path="/dashboard/tiendas" element={<Clientes />} />
                    <Route path="/dashboard/tiendas/editar" element={<EditClient />} />
                    <Route path="/dashboard/tiendas/agregar" element={<AddClient />} />
                    <Route path="/dashboard/tienda/usuarios" element={<TiendaUsuarios />} />
                    <Route path="/dashboard/tienda/perfil" element={<TiendaPerfil />} />
                    <Route path="/dashboard/tienda/editar-perfil" element={<EditPerfil />} />
                    <Route path="/dashboard/tienda/editar-perfil-admin" element={<EditPerfilAdmin />} />
                    <Route path="/dashboard/tienda/rotulo" element={<PrintLabel />} />
                    <Route path="/dashboard/tienda/nueva-recogida" element={<MyNewPickUp />} />
                    <Route path="/dashboard/tienda/nuevo-paquete" element={<TiendaAddPackage />} />
                    <Route path="/dashboard/tienda/editar-paquete" element={<TiendaEditPackage />} />
                    <Route path="/dashboard/tienda/reportes" element={<ReportTienda />} />

                    <Route path="/dashboard/mis-recogidas" element={<MyPickUps />} />
                    <Route path="/dashboard/mis-entregas" element={<MisPaquetes />} />
                    <Route path="/dashboard/mis-recolectas" element={<MisRecolectas />} />
                    <Route path="/dashboard/mis-devoluciones-pendientes" element={<MisDevolicionesPendientes />} />
                    <Route path="/dashboard/mis-devoluciones-entregadas" element={<MisDevolicionesEntregadas />} />

                    <Route path="/dashboard/todas-las-recolectas" element={<Recolectas />} />

                    <Route path="/dashboard/ordenes-de-recogida" element={<PickUpList />} />
                    <Route path="/dashboard/historico-de-recogida" element={<PickUpHistory />} />
                    <Route path="/dashboard/ordenes-de-recepcion" element={<ReceptionList />} />

                    <Route exact path="/dashboard/orden-de-recogida" element={<PickUpOrder />} />
                    <Route path="/dashboard/orden-de-recogida/:codigo" element={<PickUpOrder />} />

                    <Route exact path="/dashboard/orden-de-servicio" element={<Reception />} />
                    <Route path="/dashboard/orden-de-servicio/:codigo" element={<Reception />} />

                    <Route path="/dashboard/todos-los-paquetes" element={<CommodityList />} />
                    <Route exact path="/dashboard/detalle-paquete" element={<Detalle />} />
                    <Route path="/dashboard/detalle-paquete/:codebar" element={<Detalle />} />
                    <Route path="/dashboard/android-detalle-paquete/:codebar" element={<DetalleAndroid />} />

                    <Route path="/dashboard/administradores" element={<Usuarios />} />
                    <Route path="/dashboard/coordinadores" element={<Usuarios />} />
                    <Route path="/dashboard/mensajeros" element={<Mensajeros />} />
                    <Route path="/dashboard/recepcionistas" element={<Usuarios />} />
                    <Route path="/dashboard/cajeros" element={<Usuarios />} />

                    <Route path="/dashboard/reporte-mi-caja" element={<MiCaja />} />
                    <Route path="/dashboard/reporte-clientes" element={<ReportClient />} />
                    <Route path="/dashboard/reporte-mensajeros" element={<ReportCarrier />} />
                    <Route path="/dashboard/reporte-historial" element={<ReportHistory />} />

                    <Route path="/dashboard/chat" element={<Chat />} />
                    <Route path="/dashboard/chating" element={<VistaChat />} />

                    <Route path="/dashboard/nueva-orden-de-recogida" element={<NewPickUp />} />
                    <Route path="/dashboard/nueva-orden-de-servicio" element={<NewReception />} />

                    <Route path="/dashboard/editar-mi-orden" element={<EditMyPickUp />} />
                    <Route path="/dashboard/editar-orden-de-recogida" element={<EditPickUp />} />
                    <Route path="/dashboard/editar-orden-de-servicio" element={<EditReception />} />
                    <Route path="/dashboard/editar-paquete" element={<EditPackage />} />

                    <Route path="/dashboard/generar-codigos" element={<CodeGenerator />} />
                    <Route path="/dashboard/administrar-validador" element={<ValidadorAdmin />} />
                    <Route path="/dashboard/validador" element={<Validador />} />
                    <Route path="/dashboard/validaciones-pendientes" element={<ValidacionesPendientes />} />

                    <Route path="/dashboard/ayuda" element={<Help />} />
                    <Route path="/dashboard/forbidden" element={<Forbidden />} />

                    <Route path="*" element={<NoMatch />} />
                </Route>

                <Route
                    path="/sign-in"
                    element={<LogIn changeTheme={(_theme) => handleTheme(_theme)} />} />

                <Route
                    path="/sign-in-admin"
                    element={<LogInAdmin changeTheme={(_theme) => handleTheme(_theme)} />} />

                <Route
                    path="/forgot-password"
                    element={<ForgotPassword />} />

            </Routes>
            <footer>
                <Footer />
                <div id="modal"></div>
            </footer>
        </MediaProvider>
    );
}
