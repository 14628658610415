import * as React from "react";
import ListStatusIcon from "mdi-react/ListStatusIcon";
import AutorenewIcon from "mdi-react/AutorenewIcon";
import { Card, CardBody, Col } from "reactstrap";
import { useNavigate } from "react-router";
import funciones from "services/funciones";
import { Alert } from "shared/Alert";
import { Api } from "services/api";
import { useMedia } from "hooks";
import * as Icon from "images";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const TodosLosPendientes = (params) => {
    const navigate = useNavigate();
    const { setDataUser } = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [previousQuery, setPreviousQuery] = React.useState({ task: "pendientes_todos", offset: 0 });
    const [showLoading, setShowLoading] = React.useState(true);
    const [showLazy, setShowLazy] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);


    const getListLazy = async () => {
        setShowLoading(true);

        let _data = await api.current.fetchJson({
            url: "lista-validador",
            data: {
                task: previousQuery.task,
                offset: dataList.length
            }
        });

        if (_data.response == 1) {
            let _packs = [];

            dataList.map(item => {
                _packs.push(item);
            });

            _data.data.map(item => {
                _packs.push(item);
            });

            setPreviousQuery({ task: previousQuery.task, offset: dataList.length });
            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_packs);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getList = async () => {
        setShowLoading(true);

        let _data = await api.current.fetchJson({
            url: "lista-validador",
            data: {
                task: "pendientes_todos",
                offset: 0
            }
        });

        if (_data.response == 1) {
            setPreviousQuery({ task: "pendientes_todos", offset: 0 });
            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const Tabla = () => {
        return <div className="dashboard__sales-table">
            {dataList.length < 1 ?
                <h4>¡No hay pendientes!</h4>
                :
                <>
                    <table className="table">
                        <tbody>

                            <tr className="table_title">
                                <th>Fecha</th>
                                <th>Código</th>
                                <th style={{ whiteSpace: "nowrap" }}>Mensajero</th>
                                <th style={{ whiteSpace: "nowrap" }}>Validador</th>
                                <th>Valor</th>
                            </tr>

                            {dataList.map((item, index) => {
                                let fecha_creado = new Date(item.fecha);

                                return <tr key={index}>
                                    <td>{`${format(fecha_creado.getDate())}/${meses[fecha_creado.getMonth()]}/${fecha_creado.getFullYear()} ${format(fecha_creado.getHours())}:${format(fecha_creado.getMinutes())}`}</td>
                                    <td>{item.codigo}</td>
                                    <td style={{ whiteSpace: "nowrap" }}>{item.carrier_name}</td>
                                    <td style={{ whiteSpace: "nowrap" }}>{item.validador_name}</td>
                                    <td style={{ whiteSpace: "nowrap" }}>{funciones.formatPrice(item.valor).format}</td>
                                </tr>
                            })}

                            <tr className="_no_margin">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                    {showLoading ?
                        <button className="btn_lazy_load"><Icon.Loading /></button>
                        :
                        <>
                            {showLazy ?
                                <button className="btn_lazy_load" onClick={() => getListLazy()}>Cargar más</button>
                                :
                                <h4 className="h4_lazy_load">¡No hay más pendientes!</h4>
                            }
                        </>
                    }
                </>
            }
        </div>;
    }

    React.useEffect(() => {
        params.onChange(showLoading);
    }, [showLoading]);

    React.useEffect(() => {
        if (params.inLoad) {
            getList();
        }
    }, [params.inLoad]);

    return (
        <div className="content_reportes">
            <Col className="col_table">
                <Card style={{ overflow: "hidden" }}>
                    <CardBody className="dashboard__card-widget">
                        <div className="card__title">
                            <h5 className="bold-text"><ListStatusIcon className="dashboard__money-icon" /> Validaciones Pendientes</h5>
                        </div>

                        <div className="panel__btns">

                            <button
                                type="button"
                                className="panel__btn"
                                aria-label="panel__btn"
                                onClick={() => setShowLoading(true)}>
                                <AutorenewIcon className={showLoading ? "on_refresh" : ""} />
                            </button>

                        </div>

                        <Tabla />
                    </CardBody>
                </Card>
            </Col>
        </div>
    )
}

export default TodosLosPendientes;