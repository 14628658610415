import * as React from "react";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import * as Iconos from "images";
import { useMedia } from "hooks";
import log from "services/log";

/* eslint jsx-a11y/anchor-is-valid: 0 */

const Footer = () => {
    const { data_user } = useMedia();
    const navigate = useNavigate();

    const goHelp = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let _url = window.location.href;
        document.body.style.overflow = "auto";

        if (_url.includes("dashboard")) {
            navigate("/dashboard/ayuda");
        } else {
            navigate("/ayuda");
        }
    }

    React.useEffect(() => {
        log.d("failover",
            "\n primary: " + process.env.REACT_APP_PRIMARY_SERVER?.trim() +
            "\n current: " + data_user.current_server?.trim());
    }, [data_user.current_server]); // eslint-disable-line react-hooks/exhaustive-deps 

    return (
        <div className="footer">

            <div>
                <p>© 2021 - {new Date().getFullYear()} <b>Best Transport Service</b> todos los derechos reservados.</p>
                <p><i>Desarrollado por <strong>Sarguero</strong><br />{process.env.REACT_APP_BUILD_VERSION}</i></p>
            </div>

            <div className="enlaces">
                <h5>Enlaces de interés</h5>
                <NavLink style={({ isActive }) => ({ display: isActive ? "none" : "block" })} to="/terminos-y-condiciones">Términos y condiciones</NavLink>
                <NavLink style={({ isActive }) => ({ display: isActive ? "none" : "block" })} to="/sobre-nosotros">Acerca de nosotros</NavLink>
                <NavLink style={({ isActive }) => ({ display: isActive ? "none" : "block" })} to="#">Contáctanos</NavLink>
            </div>

            <div className="logo_help" onClick={goHelp}>
                <Iconos.DeliveryMan />
                <h5>Ayuda</h5>
            </div>

            {(data_user.version && process.env.REACT_APP_CURRENT_VERSION !== data_user.version) && <div className="update_alert">
                <Iconos.Alert />
                <h5>¡Parece que hay una nueva versión! <br />
                    Actualiza para cambiar de <i>{process.env.REACT_APP_CURRENT_VERSION}</i> a <i>{data_user.version}</i>
                </h5>
            </div>}

            {(data_user.current_server && process.env.REACT_APP_PRIMARY_SERVER?.trim() !== data_user.current_server?.trim()) && <div className="failover_alert">
                <Iconos.Alert />
                <h5>¡El sistema ha entrado en FAILOVER! <br />
                    <i>Verifícalo con el administrador.</i>
                </h5>
            </div>}
        </div>
    )
}

export default Footer;