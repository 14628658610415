import * as React from "react";
import { RequirePermissions } from "shared/AlertMessages";
import EditContainer from "./EditContainerState";
import { TitleSticky } from "shared/Components";
import { useNavigate } from "react-router-dom";
import withRouter from "services/withRouter";
import funciones from "services/funciones";
import Loading from "shared/Loading";
import { Alert } from "shared/Alert";
import { useMedia } from "hooks";
import { Api } from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const EditPackage = ({ location }) => {
    const navigate = useNavigate();
    const data = location.state.data;
    const { setTitulo, setDataUser, clientsList, setClientsList } = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [showLoading, setShowLoading] = React.useState(true);
    const [allPaquages, setAllPaquages] = React.useState([]);
    const [packages, setPackages] = React.useState({});
    const ref_form = React.useRef();


    const getAllList = async () => {
        let _data = await api.current.fetchJson({
            url: "get-users",
            data: {
                roll: "clients",
                list_ids: clientsList.map(item => item.id_user).join(",")
            }
        });
        let _users = clientsList.filter(item => item.id_user != 0);

        if (_data.response == 1) {
            _data.data.forEach(item => {
                item.tarifas_sistema = JSON.parse(item.tarifas_sistema);
                item.tarifas_cliente = JSON.parse(item.tarifas_cliente);
                item.tarifas = item.tarifas_sistema.map(t => {
                    let _city = item.tarifas_cliente.find(c => c.id == t.id);

                    if (_city?.id === t.id) {
                        return _city;
                    } else {
                        return t;
                    }
                });
                item.nivel > "2" && _users.push(item);
            });

            setClientsList(_users);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        return _users;
    }

    const configPackages = async (_data) => {
        let _pack = {};
        let _allPack = [];
        let _lista = await getAllList();

        _data.map((item) => {
            item.estado_real = item.estado;
            item.addressee = item.addressee ?? "";
            item.estado = data.comentario == null ? item.estado : 4;
            item.valorp = item.price;
            item.valord = item.v_domicilio;
            item.valorm = item.v_mensajero;
            item.estado = item.carrier ? 5 : 4;

            _pack = { ..._pack, [item.index]: item };
            _allPack.push(<ContainerEdit key={item.index} pack={item} lista={_lista} />);
        });

        setPackages(_pack);
        setAllPaquages(_allPack);
        setShowLoading(false);
    }

    const updatePackage = (pack, index) => {
        setPackages(p => ({ ...p, [index]: pack }))
    }

    const saveOrder = async () => {
        setShowLoading(true);

        let _data = await api.current.fetchJson({
            url: "save-package",
            data: {
                old_client: data.id_client,
                order: data.orden,
                packages: packages
            }
        });

        if (_data.response == 1) {
            Alert("¡Modificado correctamente!", "ok", () => navigate(-1));
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validateFields = (e) => {
        e.preventDefault();
        let _is_empty = false;
        let _message = "";

        if (typeof packages !== "object" || Object.entries(packages).length < 1) {
            Alert("Debes agregar al menos un paquete!");
            return;
        }

        for (let i in packages) {
            if (parseInt(packages[i]["estado"]) < 1) {
                _is_empty = true;
                _message = `¡Debes seleccionar un estado!`;
                break
            }

            if (packages[i].codigo.length < 1) {
                _is_empty = true;
                _message = `Debes ingresar un "código" del paquete!`;
                break
            }

            let codigo = Object.entries(packages).filter(item => item[1].codigo === packages[i].codigo);

            if (codigo.length > 1) {
                _is_empty = true;
                _message = `¡El código "${codigo[0][1].codigo}" se encuentra repetido en esta orden!`;
                break;
            }

            if (packages[i].tiene_dev) {
                if (packages[i].devolucion.length < 1) {
                    _is_empty = true;
                    _message = `Debes indicar el tipo de devolución`;
                    break;
                }
            }

            if (!packages[i].tiene_dev) {
                packages[i].devolucion = "";
            }

            if (packages[i]["valorp"] == null) {
                _is_empty = true;
                _message = `¡No hay cambios para guardar!`;
                break
            }

            if (packages[i]["valorp"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar el valor del producto!`;
                break
            }

            if (packages[i]["valord"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar el valor del domicilio!`;
                break
            }

            if (packages[i]["valorm"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar el valor del mensajero!`;
                break
            }

            if (packages[i]["attachments"].length < 1) {
                _is_empty = true;
                _message = `Debes ingresar al menos una imagen para el paquete`;
                break
            }
        }

        if (_is_empty) {
            Alert(_message);
            return;
        }

        saveOrder();
    }

    const removePackage = (_index, _id) => {
        // Alert("Para borrar este paque lo tienes que hacer desde la orden " + data.consecutivo, "warning");
        navigate(-1);
    }

    const ContainerEdit = ({ pack, lista }) => {

        return (
            <EditContainer
                item={pack}
                list={lista}
                remove={removePackage}
                update={(p, i) => updatePackage(p, i)} />
        );
    }

    React.useEffect(() => {
        setTitulo("Editar paquete " + data.codigo);
        document.body.style.overflow = "auto";

        if (funciones.validaPermisos("pack_edicion")) {
            if (location.state) {
                configPackages([{ ...data, index: data.codigo }]);
            } else {
                Alert("¡No se pudo cargar la información debido a un mal ingreso!",
                    "warning",
                    () => navigate("/dashboard/todos-los-paquetes"));
                setShowLoading(false);
            }
        }
    }, [data]);

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("pack_edicion")}>

            <form className="pickup" ref={ref_form} onSubmit={e => e.preventDefault()}>
                <TitleSticky>Editar paquete</TitleSticky>

                <div className="pickup_container" style={{ marginBottom: 28 }}>
                    {allPaquages}
                </div>

                <div className="boton_agregar">

                    <button type="button" className="btn btn-common width-static" onClick={validateFields}>Guardar</button>
                    <button type="button" className="btn btn-common width-static" onClick={() => navigate(-1)}>Cancelar</button>

                </div>

                {showLoading && <Loading />}
            </form >
        </RequirePermissions>
    )
}

export default withRouter(EditPackage);