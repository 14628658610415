import * as React from "react";
import { RequirePermissions } from "shared/AlertMessages";
import { Alert, alerta, alertaRemove } from "shared/Alert";
import { TitleSticky } from "shared/Components";
import { uploadImage } from "services/Uploader";
import withRouter from "services/withRouter";
import { DropFile } from "shared/FileUpload";
import { Input } from "shared/Input";
import Loading from "shared/Loading";
import resize from "shared/Resize";
import { Api } from "services/api";
import * as Iconos from "images";
import { useMedia } from "hooks";
import log from "services/log";

/* eslint eqeqeq: 0 */

const EditPerfil = (router) => {
    const { navigate } = router;
    const { data_user, setDataUser, setTitulo } = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [localidad, setLocalidad] = React.useState(data_user.info.company.localidad ?? "");
    const [direccion, setDireccion] = React.useState(data_user.info.company.direccion ?? "");
    const [telefono, setTelefono] = React.useState(data_user.info.company.telefono ?? "");
    const [nombre, setNombre] = React.useState(data_user.info.company.nombre ?? "");
    const [ciudad, setCiudad] = React.useState(data_user.info.company.ciudad ?? "");
    const [redes, setRedes] = React.useState(data_user.info.company.redes ?? []);
    const [showLoading, setShowLoading] = React.useState(false);
    const [logo, setLogo] = React.useState([]);
    const filesRef = React.useRef();
    const formRef = React.useRef();


    const createFile = async (url) => {
        let files = [];

        let response = await fetch(url, {
            mode: 'no-cors',
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        });
        let data = await response.blob();
        let file_name = url.split("?")[0].split("%2F").pop();

        let file = new File([data],
            file_name,
            {
                type: "image/" + file_name.split(".").pop(),
                lastModified: Date.now()
            });
        file = Object.assign(file, {
            preview: url,
            url: url,
            path: file_name,
            nameinserver: file_name,
            isUpdate: true
        });

        files.push(file);
        setLogo(files);
    }

    const saveInfo = async (_company) => {
        let _data = await api.current.fetchJson({
            url: "save-info-tienda",
            data: _company
        });

        if (_data.response == 1) {
            let _user = {
                ...data_user,
                info: {
                    ...data_user.info,
                    company: _company
                }
            };
            localStorage.setItem('usuario', JSON.stringify(_user));
            setDataUser(_user);
            Alert(_data.msg, "ok", () => navigate(-1));
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/sign-in"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const validateFields = (e) => {
        e.preventDefault();
        let has_error = false;

        alertaRemove(formRef.current.tienda);
        alertaRemove(formRef.current.telefono);
        alertaRemove(formRef.current.direccion);
        alertaRemove(formRef.current.localidad);
        alertaRemove(formRef.current.ciudad);
        alertaRemove(filesRef.current);


        if (nombre == null || nombre.length < 1) {
            alerta(formRef.current.tienda, "¡Debes ingresar el nombre de la tienda!");
            formRef.current.tienda.focus();
            window.scrollTo({
                top: 120,
                behavior: 'smooth'
            });
            return;
        }

        if (telefono == null || telefono.length < 1) {
            alerta(formRef.current.telefono, "¡Debes ingresar el teléfono de la tienda!");
            formRef.current.telefono.focus();
            window.scrollTo({
                top: 120,
                behavior: 'smooth'
            });
            return;
        }

        if (direccion.length > 1) {
            if (localidad == null || localidad.length < 1) {
                alerta(formRef.current.localidad, "¡Debes ingresar la localidad de la tienda!");
                formRef.current.localidad.focus();
                window.scrollTo({
                    top: 180,
                    behavior: 'smooth'
                });
                return;
            }

            if (ciudad == null || ciudad.length < 1) {
                alerta(formRef.current.ciudad, "¡Debes ingresar la ciudad de la tienda!");
                formRef.current.ciudad.focus();
                window.scrollTo({
                    top: 180,
                    behavior: 'smooth'
                });
                return;
            }
        }

        if (logo.length > 1) {
            alerta(filesRef.current, "¡Debes agregar sólo un archivo!");
            return;
        }

        for (let index = 0; redes.length > index; index++) {
            let data = redes[index];

            if (data.red == null || data.red.length < 1) {
                alerta(formRef.current["red_" + index], "¡Debes ingresar el nombre!");
                formRef.current["red_" + index].focus();
                has_error = true;
                break;
            }

            if (data.hashtag == null || data.hashtag.length < 1) {
                alerta(formRef.current["hashtag_" + index], "¡Debes ingresar el número o hashtag!");
                formRef.current["hashtag_" + index].focus();
                has_error = true;
                break;
            }
        }

        if (has_error) {
            return;
        }

        setShowLoading(true);

        if (logo.length > 0) {
            const data = new FormData();
            const file = logo[0];

            if (file.type.toString().split('/')[0] !== "image") {
                alerta(filesRef.current, "¡El archivo debe ser una imagen!");
                setShowLoading(false);
                return;
            }

            if (file.isUpdate) {
                saveInfo({
                    ...data_user.info.company,
                    id: data_user.info.company.id,
                    localidad,
                    direccion,
                    telefono,
                    nombre,
                    ciudad,
                    redes,
                    logo: file.url
                });
            } else {
                resize(file, async image => {
                    if (image !== null) {
                        data.append('image', image);
                        data.append('nombre', "STORE_" + nombre.toUpperCase().replaceAll(" ", "_") + "." + file.name.split('.').pop());
                        data.append('destino', "tiendas");
                        log.i("image", image);
                    }

                    await uploadImage(data, data_user, api.current).then(response => {
                        log.i("response", response);

                        saveInfo({
                            ...data_user.info.company,
                            id: data_user.info.company.id,
                            localidad,
                            direccion,
                            telefono,
                            nombre,
                            ciudad,
                            redes,
                            logo: response.url
                        });
                    }).catch(error => {
                        alerta(filesRef.current, error);
                        setShowLoading(false);
                    });
                });
            }
        } else {
            saveInfo({
                ...data_user.info.company,
                id: data_user.info.company.id,
                localidad,
                direccion,
                telefono,
                nombre,
                ciudad,
                redes
            });
        }
    }

    React.useEffect(() => {
        setTitulo("Editar tienda");
        document.body.style.overflow = "auto";
        if (data_user.info.company.logo) {
            createFile(data_user.info.company.logo);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <RequirePermissions navigate={navigate} permission={[6].includes(parseInt(data_user.nivel))}>
            <TitleSticky>Editar tienda</TitleSticky>

            <form className="pickup" style={{ marginTop: "1rem" }} ref={formRef} onSubmit={e => e.preventDefault()}>
                <div className="pickup_container">
                    <div className="new_container">

                        <fieldset className="new_features">
                            <div className="f_row" style={{ maxWidth: 600, paddingTop: 0 }}>

                                <div className="f_row" style={{ width: "100%", marginTop: 0 }}>

                                    <fieldset className="_in_row _no_border">
                                        <legend>Nombre *</legend>
                                        <Input name="tienda" placeholder="Nombre de la tienda"
                                            value={nombre} onChange={(value) => {
                                                setNombre(value);
                                                alertaRemove(formRef.current.tienda);
                                            }} />
                                    </fieldset>

                                    <fieldset className="_in_row _no_border">
                                        <legend>Teléfono *</legend>
                                        <Input name="telefono" placeholder="Número de contacto"
                                            value={telefono} onChange={(value) => {
                                                setTelefono(value);
                                                alertaRemove(formRef.current.telefono);
                                            }} />
                                    </fieldset>

                                </div>

                                <div className="f_row" style={{ width: "100%", marginTop: 0, paddingTop: 0 }}>

                                    <fieldset className="_in_row _no_border">
                                        <legend>Dirección *</legend>
                                        <div className="input">
                                            <input type="text"
                                                name="direccion"
                                                value={direccion}
                                                placeholder="Dirección de la tienda"
                                                onChange={e => {
                                                    setDireccion(e.target.value);
                                                    alertaRemove(formRef.current.direccion);
                                                }} />
                                        </div>
                                    </fieldset>

                                    <fieldset className="_in_row _no_border">
                                        <legend>Localidad *</legend>
                                        <Input name="localidad" placeholder="Localidad de la tienda"
                                            value={localidad} onChange={(value) => {
                                                setLocalidad(value);
                                                alertaRemove(formRef.current.localidad);
                                            }} />
                                    </fieldset>

                                    <fieldset className="_in_row _no_border">
                                        <legend>Ciudad *</legend>
                                        <Input name="ciudad" placeholder="Ciudad de la tienda"
                                            value={ciudad} onChange={(value) => {
                                                setCiudad(value);
                                                alertaRemove(formRef.current.ciudad);
                                            }} />
                                    </fieldset>

                                </div>

                                <div className="f_row" style={{ width: "100%", marginTop: 0, paddingTop: 0, justifyContent: "center" }}>
                                    <fieldset className="features_options _no_border" style={{ width: "100%" }}>
                                        <legend>Logo de la tienda</legend>
                                        <DropFile
                                            ref={filesRef}
                                            fileName={"STORE_" + nombre.toUpperCase().replaceAll(" ", "_")}
                                            attach={logo}
                                            onChange={value => {
                                                setLogo(value);
                                                alertaRemove(filesRef.current);
                                            }} />
                                    </fieldset>
                                </div>

                                <div className="f_row" style={{ width: "100%", marginTop: 0, paddingTop: 0, justifyContent: "center", alignItems: "center" }}>
                                    {redes.map((data, index, array) => {
                                        return <fieldset key={index} className="_redes_sociales">
                                            <legend>Red social</legend>
                                            <h5>
                                                <Iconos.CloseCircle onClick={() => {
                                                    array.splice(index, 1);
                                                    setRedes([...array]);
                                                }} />
                                            </h5>

                                            <label>Nombre</label>
                                            <div className="input">
                                                <select name={"red_" + index} value={data.red} onChange={e => {
                                                    array[index]['red'] = e.target.value;
                                                    setRedes([...array]);
                                                    alertaRemove(formRef.current["red_" + index]);
                                                }}>
                                                    <option value="whatsapp">Whatsapp</option>
                                                    <option value="facebook">Facebook</option>
                                                    <option value="instagram">Instagram</option>
                                                    <option value="youtube">Youtube</option>
                                                    <option value="tiktok">Tik Tok</option>
                                                </select>
                                            </div>

                                            <label>Número o hashtag</label>
                                            <div className="input">
                                                <input type="tel" name={"hashtag_" + index} value={data.hashtag} maxLength={60}
                                                    onChange={e => {
                                                        array[index]['hashtag'] = e.target.value;
                                                        setRedes([...array]);
                                                        alertaRemove(formRef.current["hashtag_" + index]);
                                                    }} />
                                            </div>
                                        </fieldset>
                                    })}

                                    {redes.length < 6 && <h4 className="_button _with_margin" onClick={() => {
                                        setRedes([...redes, { red: "whatsapp", hashtag: "" }]);
                                    }}>Agregar red social <Iconos.Share /></h4>}

                                </div>

                            </div>
                        </fieldset>
                    </div>
                </div>
                <button type="button" className="btn btn-common btn_icon"
                    style={{ display: "block", margin: "1rem auto" }}
                    onClick={validateFields}>Guardar</button>
            </form>

            {showLoading && <Loading />}
        </RequirePermissions>
    )
}

export default withRouter(EditPerfil);