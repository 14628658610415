import * as React from "react";
import DeleteIcon from "mdi-react/DeleteOutlineIcon";
import EditIcon from "mdi-react/EditOutlineIcon";
import ChatIcon from "mdi-react/ChatOutlineIcon";
import HistoryIcon from "mdi-react/HistoryIcon";
import OpenIcon from "mdi-react/OpenInNewIcon";
import { Alert, Confirm } from "shared/Alert";
import funciones from "services/funciones";
import CourierList from "./CourierList";
import Loading from "shared/Loading";
import History from "shared/History";
import { useMedia } from "hooks";
import * as Iconos from "images";
import { Api } from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const MenuRow = (params) => {
    const { navigate, data, blue, children, onSel, reload, current, inRow = false } = params;
    const { media_movil, data_user, setDataUser } = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [clases, setClases] = React.useState(blue == 0 ? "table_row bkg_blue" : "table_row");
    const [_pos, set_pos] = React.useState({ top: "0", left: "0" });
    const [modalView, setModalView] = React.useState(null);
    const [showMenu, setShowMenu] = React.useState(false);
    const [_e, set_e] = React.useState();
    const menuRef = React.useRef();
    const row = React.useRef(null);

    const format = (_num) => {
        return _num < 10 ? "0" + _num : _num;
    }

    const countPackages = async () => {
        let _data = await api.current.getPackages(data.id);

        if (_data.response == 1) {
            return _data.data.length;
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }
        return 0;
    }

    const goChat = (e) => {
        e.preventDefault()
        e.stopPropagation();
        document.body.style.overflow = "auto";
        navigate("/dashboard/chat",
            {
                state: {
                    data: {
                        guest: {
                            id_user: data_user.nivel == 6 ? data.recogida.carrier : data.consignee,
                            nombre: data_user.nivel == 6 ? data.carrier_name : data.consignee_nombre,
                            apellido: data_user.nivel == 6 ? "" : data.consignee_apellido
                        },
                        fromOutside: true
                    }
                }
            });
    }

    const goOpen = (e) => {
        e.preventDefault()
        e.stopPropagation();
        document.body.style.overflow = "auto";
        navigate("/dashboard/orden-de-recogida", { state: { data: data } });
    }

    const goEdit = (e) => {
        e.preventDefault()
        e.stopPropagation();
        document.body.style.overflow = "auto";
        navigate("/dashboard/editar-orden-de-recogida", { state: { data: data } });
    }

    const goDelete = (e) => {
        hiddenMenu(e);

        Confirm(`Seguro de borrar la orden Nº ${data.consecutivo}?`, async (response) => {
            if (response) {
                let _data = await api.current.deleteOrder({
                    id_order: data.id,
                    tipo: "orden de recogida"
                });

                if (_data.response == 1) {
                    document.body.style.overflow = "auto";
                    reload();
                } else if (_data.response === -2) {
                    Alert(_data.msg, "warning", () => {
                        document.body.style.overflow = "auto";
                        navigate("/sign-in");
                    });
                } else {
                    Alert(_data.msg, "warning");
                }
            }
        });

    }

    const editarRecibir = async (e) => {
        e.preventDefault()
        e.stopPropagation();
        let _fecha = new Date();
        document.body.style.overflow = "auto";
        setShowMenu(false);

        navigate("/dashboard/editar-orden-de-servicio",
            {
                state: {
                    data: {
                        ...data,
                        date_received: _fecha.getFullYear() + "-" + format(_fecha.getMonth() + 1) + "-" + format(_fecha.getDate()),
                        comentario: "Se recibe en almacén"
                    }
                }
            });
    }

    const cambiaEstado = async (e, _val) => {
        hiddenMenu(e);
        
        if (_val == 2) {
            setModalView(<Loading />);
            let _packs = await countPackages();

            if (_packs < 1) {
                Alert("¡Debes agregar al menos un paquete!", "warning");
                setModalView(null);
                return;
            }
        }

        Confirm("¿Seguro de cambiar el estado de la orden?", async (response) => {
            if (response) {
                setModalView(<Loading />);
                let _data = await api.current.fetchJson({
                    url: "update-pickup-order-change-state",
                    data: {
                        type: 1,
                        id: data.id,
                        state: _val
                    }
                });

                if (_data.response == 1) {
                    Alert(_data.msg, "ok", () => reload());
                } else if (_data.response === -2) {
                    Alert(_data.msg, "warning", () => {
                        document.body.style.overflow = "auto";
                        navigate("/sign-in");
                    });
                } else {
                    Alert(_data.msg, "warning");
                }

                setModalView(null);
            }
        });
    }

    const onSelect = (e) => {
        e.preventDefault()
        e.stopPropagation();

        onSel(data);
    }

    const stopClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const hiddenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        document.body.style.overflow = "auto";
        setShowMenu(false);
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        set_e(e);
        document.body.style.overflow = "hidden";
        setShowMenu(true);
    }

    const handleModal = (_option, e) => {
        e.preventDefault();
        e.stopPropagation();
        let _window;

        switch (_option) {
            case "history":
                _window = <History
                    data={{
                        ...data,
                        tipo: 1,
                        parent: { ..._pos, width: media_movil ? (document.getElementById("root").offsetWidth - 16) : 550 }
                    }}
                    close={() => setModalView(null)} />;
                break;
            case "assign_pickup":
                _window = <CourierList
                    data={{
                        ...data,
                        parent: { ..._pos, width: 250 }
                    }}
                    close={() => setModalView(null)}
                    reload={reload} />;
                break;
            default:
                _window = null;
                break;
        }

        setShowMenu(false);
        setModalView(_window);
    }

    const BtnMarkAs = () => {
        let _btn = <li className="unable"><Iconos.PackWait color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Cambia a 'por recoger'</span></li>;

        if (data.estado == 1) {
            _btn = <li onClick={(e) => cambiaEstado(e, 2)}><Iconos.PackOk /> <span>Marcar como recogido</span></li>;
        } else if (data.estado == 2) {
            _btn = <li onClick={(e) => cambiaEstado(e, 1)}><Iconos.PackWait /> <span>Cambia a 'por recoger'</span></li>;
        }

        return (_btn);
    }

    const BtnReceive = () => {
        let _btn = <li className="unable"><Iconos.PackReceive color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Recibir en almacén</span></li>;

        if (data.estado == 2) {
            _btn = <li onClick={editarRecibir}><Iconos.PackReceive /> <span>Recibir en almacén</span></li>;
        }

        if (funciones.validaPermisos("pack_recogidas") && funciones.validaPermisos("pack_edicion")) {
            return (_btn);
        } else {
            return <></>;
        }
    }

    const BtnAssign = () => {
        let _btn = <li className="unable"><Iconos.BoxMarker color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Cambiar mensajero</span></li>;

        switch (parseInt(data.estado)) {
            case 0:
            case 1:
                if (data.recogida.carrier == null) {
                    _btn = <li onClick={e => handleModal("assign_pickup", e)}><Iconos.BoxMarker /> <span>Asignar recogida</span></li>;
                } else {
                    _btn = <li onClick={e => handleModal("assign_pickup", e)}><Iconos.BoxMarker /> <span>Cambiar mensajero</span></li>;
                }
                break;
        }

        return (_btn);
    }

    const BtnDelete = () => {
        let _btn = <li className="unable"><DeleteIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Borrar</span></li>;

        switch (parseInt(data.estado)) {
            case 0:
            case 1:
                if ([1, 2, 3, 6].includes(parseInt(data_user.nivel))) {
                    _btn = <li onClick={goDelete}><DeleteIcon /> <span>Borrar</span></li>;
                }
                break;
        }

        return (_btn);
    }

    const BtnChat = () => {
        let _btn = <li className="unable"><ChatIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Abrir chat</span></li>;

        if (data_user.nivel < 4 && data.consignee != null) {
            _btn = <li onClick={goChat}><ChatIcon /> <span>Abrir chat</span></li>;
        }

        if (data_user.nivel == 6 && data.recogida.carrier != null) {
            _btn = <li onClick={goChat}><ChatIcon /> <span>Abrir chat</span></li>;
        }

        return (_btn);
    }

    const BtnEdit = () => {
        let _btn = <li className="unable"><EditIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Editar</span></li>;

        switch (parseInt(data.estado)) {
            case 0:
            case 1:
            case 2:
            case 3:
                if (data_user.nivel == 4 && data.recogida.carrier == window.atob(data_user.auth)) {
                    _btn = <li onClick={goEdit}><Iconos.PackAdd /> <span>Agregar paquetes</span></li>;
                }

                if ((data_user.nivel == 6 && data.consignee == window.atob(data_user.auth)) || funciones.validaPermisos("pack_edicion")) {
                    _btn = <li onClick={goEdit}><EditIcon /> <span>Editar</span></li>;
                }
                break;
        }

        return (_btn);
    }

    const AllButtons = () => {
        return (
            <>
                {showMenu &&
                    <>
                        <div className="back_form _bkg_black" onClick={hiddenMenu} onContextMenu={hiddenMenu}></div>
                        <nav className="menu" style={{ ..._pos, width: media_movil ? 220 : 170 }} ref={menuRef}>
                            <ul>
                                {inRow && <li onClick={goOpen}><OpenIcon /> <span><strong>Abrir</strong></span></li>}
                                <BtnEdit />
                                <BtnChat />

                                <hr />
                                {data_user.nivel == 4 && <BtnMarkAs />}
                                {data_user.nivel < 4 && <BtnAssign />}
                                <BtnReceive />
                                {data_user.nivel < 5 && <hr />}

                                <BtnDelete />

                                {data_user.nivel < 4 && <li onClick={e => handleModal("history", e)}><HistoryIcon /> <span>Historial</span></li>}
                            </ul>
                        </nav>
                    </>}
            </>)
    }

    React.useEffect(() => {
        if (showMenu) {
            set_pos(funciones.getPositonDisplay(_e, menuRef.current));
        }
    }, [showMenu]);

    React.useEffect(() => {
        if (inRow) {
            current === data.id && setClases(c => c + " bkg_select");
        }
    }, [current]);

    return (
        <>
            {inRow ?
                <tr className={clases} ref={row}
                    onDoubleClick={goOpen}
                    onClick={onSelect}
                    onContextMenu={handleContextMenu}>

                    {children}

                    <td className="rowMenu" onClick={stopClick} onDoubleClick={stopClick} onContextMenu={stopClick}>
                        <AllButtons />
                        {modalView}
                    </td>
                </tr>
                :
                <>
                    <div className="main_container" onContextMenu={handleContextMenu}>

                        {children}

                        <AllButtons />
                    </div>
                    {modalView}
                </>
            }
        </>
    )
}

export default MenuRow;