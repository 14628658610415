import * as React from "react";
import CloseIcon from "mdi-react/CloseCircleOutlineIcon";
import BarcodeScanIcon from "mdi-react/BarcodeScanIcon";
import { useNavigate } from "react-router-dom";
import FileUpload from "shared/FileUpload";
import funciones from "services/funciones";
import { InputNum } from "shared/Input";
import { Alert } from "shared/Alert";
import { Api } from "services/api";
import { useMedia } from "hooks";
import * as Icon from "images";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */

const EditContainer = ({ item, update, remove }) => {
    const navigate = useNavigate();
    const { media_movil, setDataUser } = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [codigo, setCodigo] = React.useState("");
    const [valorp, setValorp] = React.useState("");
    const [valord, setValord] = React.useState("");
    const [valorm, setValorm] = React.useState("");
    const [tiene_dev, setTiene_dev] = React.useState(item.tiene_dev || false);
    const [devolucion, setDevolucion] = React.useState(item.devolucion || "");
    const [descripcion, setDescripcion] = React.useState(item.descripcion || "");
    const [attachments, setAttachments] = React.useState(item.attachments);
    const [filesAttached, setFilesAttached] = React.useState([]);
    const [recibiendo, setRecibiendo] = React.useState(false);
    const [verificando, setVerificando] = React.useState(false);
    const isMounted = React.useRef(true);
    const [devuelve, setDevuelve] = React.useState(false);
    const [kodv, setKodv] = React.useState(item.devolucion || "");


    const createFile = async (url) => {
        if (isMounted.current) {
            let files = [];
            let only_urls = url.split(",");

            only_urls.forEach(async (_url, index) => {
                _url = _url.split("|")[1];

                let response = await fetch(_url, {
                    mode: 'no-cors',
                    headers: {
                        'Access-Control-Allow-Origin': '*'
                    }
                });
                let data = await response.blob();
                let file_name = _url.split("?")[0].split("%2F").pop();

                let file = new File([data],
                    file_name,
                    {
                        type: "image/" + file_name.split(".").pop(),
                        lastModified: Date.now()
                    });
                file = Object.assign(file, {
                    preview: _url,
                    url: _url,
                    path: file_name,
                    nameinserver: file_name,
                    isUpdate: true
                });

                files.push(file);

                if (files.length == only_urls.length) {
                    setFilesAttached(files);
                }
            });
        }
    }

    const validaCodigo = async (_codigo) => {
        if (_codigo.length < 1) {
            return;
        }

        setVerificando(true);

        let _data = await api.current.fetchJson({
            url: "verify-code",
            data: {
                codigo: _codigo
            }
        });

        if (_data.response == 1) {
            if (parseInt(_data.cantidad) < 1) {
                updatePackage("codigo", _codigo);
            } else {
                // updatePackage("codigo", "");
                Alert(`El código "${_codigo}" ya esta asignado a otro paquete`, "warning");
            }
        }

        setVerificando(false);
    }

    const handleScanner = (e) => {
        e.preventDefault();

        if (typeof android !== "undefined") {

            // eslint-disable-next-line no-undef
            scanner.response = async (_codigo) => {
                updatePackage("codigo", _codigo);
                validaCodigo(_codigo);
            };

            // eslint-disable-next-line no-undef
            scanner.error = (format) => Alert(`Error de lectura: \nEl formato del código es "${format}" y no es compatible con esta función.`, "warning");

            // eslint-disable-next-line no-undef
            android.scannerQR();
        }
    }

    const handlerBlur = (e) => {
        // setTimeout(() => {
        validaCodigo(codigo);
        // }, 2000);
    }

    const updatePackage = (param, value) => {

        let _pack = {
            ...item,
            "codigo": param === "codigo" ? value : codigo,
            "valorp": param === "valorp" ? value.replaceAll(".", "") : valorp.replaceAll(".", ""),
            "valord": param === "valord" ? value.replaceAll(".", "") : valord.replaceAll(".", ""),
            "valorm": param === "valorm" ? value.replaceAll(".", "") : valorm.replaceAll(".", ""),

            "tiene_dev": param === "tiene_dev" ? value : tiene_dev,
            "devolucion": param === "devolucion" ? value : devolucion,

            "descripcion": param === "descripcion" ? value : descripcion,
            "attachments": param === "attachments" ? value : attachments,
        };

        param === "codigo" && setCodigo(value);
        param === "valorp" && setValorp(value);
        param === "valord" && setValord(value);
        param === "valorm" && setValorm(value);
        param === "tiene_dev" && setTiene_dev(value);
        param === "devolucion" && setDevolucion(value);
        param === "descripcion" && setDescripcion(value);
        param === "attachments" && setAttachments(value);

        update(_pack, item.index);
    }

    const RadioGoup = () => {

        const handleDevol = (_val) => {
            setDevuelve(_val);
            updatePackage("tiene_dev", _val);
        }

        const handleSelect = (e) => {
            let _val = e.target.value;

            updatePackage("devolucion", _val);
            setKodv(_val);
        }

        return <>
            {devuelve ?
                <fieldset className="_radio_group">
                    <legend>Tipo de devolución</legend>
                    <CloseIcon className="_close" onClick={() => handleDevol(false)} />

                    <label onClick={() => handleSelect({ target: { value: "cambio" } })}>
                        <input type="radio" name={"devolucion_" + item.index} value="cambio"
                            checked={kodv === "cambio"} onChange={handleSelect} />
                        Cambio
                    </label>

                    <label onClick={() => handleSelect({ target: { value: "sobrante" } })}>
                        <input type="radio" name={"devolucion_" + item.index} value="sobrante"
                            checked={kodv === "sobrante"} onChange={handleSelect} />
                        Sobrante
                    </label>
                </fieldset>
                :
                <div className="_radio_group">
                    <label>
                        <input type="checkbox"
                            defaultChecked={false}
                            onChange={e => handleDevol(e.target.checked)} />

                        Agregar devolución
                    </label>
                </div>
            }
        </>;
    }

    const BtnScaner = () => {
        let _btn = <Icon.Loading />;

        if (codigo.length >= 1) {
            if (verificando) {
                _btn = <Icon.Loading />;
            } else {
                _btn = <CloseIcon onClick={() => updatePackage("codigo", "")} />;
            }
        } else {
            _btn = typeof android !== "undefined" ? <BarcodeScanIcon className="scan_icon" onClick={handleScanner} /> : null;
        }

        return _btn;
    }

    React.useEffect(() => {
        isMounted.current = true;

        setCodigo(item.codigo);
        setValorp(funciones.formatPrice(item.price).format);
        setValord(funciones.formatPrice(item.v_domicilio).format);
        setValorm(funciones.formatPrice(item.v_mensajero).format);
        setDescripcion(item.descripcion || "");
        setAttachments(item.attachments);
        createFile(item.attachments);
        setRecibiendo(item.recibiendo || false);

        if (item.devolucion != null) {
            setDevuelve(true);
            setTiene_dev(true);
            setDevolucion(item.devolucion);
        }

        return () => isMounted.current = false;
    }, [item]);

    return (
        <div className="new_container">

            <fieldset className="new_features">
                <legend>Paquete {item.index}</legend>
                <h5>
                    <CloseIcon onClick={() => remove(item.index, item.id)} />
                    {funciones.getStateSpan(item)}
                </h5>

                <div className="f_row" style={{ maxWidth: 600 }}>

                    <div className="f_row" style={{ alignItems: "self-start", width: "100%", marginTop: 0 }}>

                        <fieldset className="_in_row _no_border">
                            <legend>Código *</legend>
                            <div className="input" onBlur={handlerBlur}>
                                <input
                                    type="text"
                                    name="codigo"
                                    value={codigo}
                                    placeholder="Ingresa el código"
                                    onChange={({ target: { value } }) => updatePackage("codigo", value)} />
                                <BtnScaner />
                            </div>
                        </fieldset>

                    </div>

                    {(codigo.length >= 1 || recibiendo) && <>

                        <RadioGoup />

                        <div className="f_row" style={{ alignItems: "self-start", marginTop: 0, paddingTop: 0 }}>

                            <fieldset className="_in_row _no_border">
                                <legend>Valor producto *</legend>
                                <InputNum name="valorp" placeholder="Ingresa el precio"
                                    value={valorp} onChange={(value) => updatePackage("valorp", value)} />
                            </fieldset>

                            <fieldset className="_in_row _no_border">
                                <legend>Valor domicilio *</legend>
                                <InputNum name="valord" placeholder="Ingresa el precio"
                                    value={valord} onChange={(value) => updatePackage("valord", value)} />
                            </fieldset>

                            <fieldset className="_in_row _no_border">
                                <legend>Valor mensajero *</legend>
                                <InputNum name="valorm" placeholder="Ingresa el precio"
                                    value={valorm} onChange={(value) => updatePackage("valorm", value)} />
                            </fieldset>

                        </div>

                        <div className="f_row" style={{ width: "100%", alignItems: "self-start", justifyContent: "center", marginTop: 0, paddingTop: 0 }}>

                            <div className="features_options _static" style={{ marginLeft: 10, marginRight: 10 }}>
                                <legend>Descripción</legend>
                                <textarea name="descripcion" rows={media_movil ? "2" : "5"} value={descripcion} placeholder="Ingresa una Descripción"
                                    onChange={({ target: { value } }) => updatePackage("descripcion", value)} style={{ marginTop: 6, paddingTop: 12, paddingBottom: 16 }} />
                            </div>

                            <div className="features_options _static" style={{ marginLeft: media_movil ? 10 : 0 }}>
                                <label>Adjuntar archivos</label>
                                <FileUpload
                                    codigo={codigo}
                                    attach={filesAttached}
                                    onChange={(value) => updatePackage("attachments", value)} />
                            </div>
                        </div>
                    </>}

                </div>

            </fieldset>

        </div>
    );
}

export default EditContainer;