import * as React from "react";
import { RequirePermissions } from "shared/AlertMessages";
import AutorenewIcon from "mdi-react/AutorenewIcon";
import Movimientos from "./components/Movimientos";
import Pendientes from "./components/Pendientes";
import { TitleSticky } from "shared/Components";
import { useNavigate } from "react-router-dom";
import { Container, Row } from "reactstrap";
import funciones from "services/funciones";
import Entrada from "./components/Entrada";
import Salida from "./components/Salida";
import { Alert } from "shared/Alert";
import * as Iconos from "images";
import { useMedia } from "hooks";
import { Api } from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-extend-native: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const CajaBts = () => {
    const navigate = useNavigate();
    const { setTitulo, setDataUser } = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [valores, setValores] = React.useState({ transferencias: 0, efectivo: 0 });
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [showLoading, setShowLoading] = React.useState(true);
    const [showModal, setShowModal] = React.useState(false);
    const [modalView, setModalView] = React.useState(null);

    const handleModal = (e, _option) => {
        e.preventDefault();
        e.stopPropagation();
        let _window;

        switch (_option) {
            case "entrada":
                _window = <Entrada
                    parent={{ ...e.target.getBoundingClientRect().toJSON(), width: 230 }}
                    close={setShowModal}
                    reload={getInfo} />;
                break;
            case "salida":
                _window = <Salida
                    parent={{ ...e.target.getBoundingClientRect().toJSON(), width: 230 }}
                    close={setShowModal}
                    reload={getInfo} />;
                break;
            default:
                _window = null;
                break;
        }

        setModalView(_window);
        setShowModal(true);
    }

    const getInfo = async () => {
        setShowLoading(true);
        /**
         * caja
         *
         * 1 caja mensajeros
         * 2 caja clientes
         * 3 caja bts
         * 4 caja bodega
         * 5 caja auxiliar
        */
        let _data = await api.current.fetchJson({
            url: "info-caja",
            data: {
                task: "saldo_caja_todo",
                caja: 3
            }
        });

        if (_data.response == 1) {
            let _list = { transferencias: 0, efectivo: 0 };

            _data.data.map(item => {
                if (item.metodo == 1) {
                    _list.efectivo = item.monto;
                }
                if (item.metodo == 2) {
                    _list.transferencias = item.monto;
                }
            });

            setValores(_list);
            setShowLoading(false);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }
    }

    React.useEffect(() => {
        setTitulo("Caja BTS");
        document.body.style.overflow = "auto";
        if (funciones.validaPermisos("caja_bts") && ![6, 7].includes(parseInt(data_user.nivel))) {
            getInfo();
        }
    }, []);

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("caja_bts") && ![6, 7].includes(parseInt(data_user.nivel))}>

            <TitleSticky className="_in_page">Caja BTS</TitleSticky>

            <Container className="dashboard _caja">
                <Row className="_en_caja">

                    <div className="panel__btns _en_caja">

                        <button
                            className="panel__btn"
                            type="button"
                            onClick={getInfo}>
                            <AutorenewIcon className={showLoading ? "on_refresh" : ""} />
                        </button>

                    </div>

                    <h4 className="_precio"><span>Disponible</span><br />
                        <span>Efectivo</span> ${funciones.formatPrice(valores.efectivo).format}
                        <br />
                        <span>Transferencias</span> ${funciones.formatPrice(valores.transferencias).format}
                    </h4>

                    <div className="cont_btns_caja">
                        {funciones.validaPermisos("caja_bts_retiro") && <button className="cash-button" onClick={e => handleModal(e, "salida")}><Iconos.CashOut />Retiro</button>}
                        {funciones.validaPermisos("caja_bts_entrada") && <button className="cash-button" onClick={e => handleModal(e, "entrada")}><Iconos.CashIn />Entrada</button>}
                    </div>
                </Row>

                <Row>
                    <Pendientes
                        parent={{
                            inload: showLoading,
                            update: () => getInfo()
                        }}
                        tipo_caja="3" />

                    <Movimientos
                        parent={{
                            inload: showLoading,
                            update: () => getInfo()
                        }} />
                </Row>

                {showModal && modalView}

            </Container>
        </RequirePermissions>
    )
}

export default CajaBts;
