import * as React from "react";
import Editcon from "mdi-react/PencilBoxMultipleOutlineIcon";
import ChartTimelineIcon from "mdi-react/ChartTimelineIcon";
import AutorenewIcon from "mdi-react/AutorenewIcon";
import FilerIcon from "mdi-react/FilterMenuIcon";
import { Card, CardBody, Col } from "reactstrap";
import FilterConcept from "./FilterConcept";
import funciones from "services/funciones";
import { useNavigate } from "react-router";
import FilterDate from "./FilterDate";
import { Alert } from "shared/Alert";
import EditAbono from "./EditAbono";
import { Api } from "services/api";
import { useMedia } from "hooks";
import * as Icon from "images";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const format = (_num) => {
    return _num < 10 ? "0" + _num : _num;
}

const Movimientos = ({ parent, tipo_caja }) => {
    const navigate = useNavigate();
    const { data_user, setDataUser } = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [previousQuery, setPreviousQuery] = React.useState({ task: "movimientos_cli", offset: 0 });
    const [showLoading, setShowLoading] = React.useState(true);
    const [showModal, setShowModal] = React.useState(false);
    const [modalView, setModalView] = React.useState(null);
    const [showLazy, setShowLazy] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);


    const handlerRefresh = (e) => {
        e.preventDefault();
        getList();
        parent.update();
    }

    const handleModal = (e, _option, _data) => {
        e.preventDefault();
        e.stopPropagation();
        let _window;

        switch (_option) {
            case "abono":
                _window = <EditAbono
                    parent={{ ...e.target.getBoundingClientRect().toJSON(), width: 230 }}
                    data={_data}
                    task="update_abono"
                    close={setShowModal}
                    reload={reloadList} />;
                break;
            case "fil_fecha":
                _window = <FilterDate
                    parent={{ ...e.target.getBoundingClientRect().toJSON(), width: 230 }}
                    task="fil_fecha"
                    close={setShowModal}
                    call={filterList} />;
                break;
            case "fil_concepto":
                _window = <FilterConcept
                    parent={{ ...e.target.getBoundingClientRect().toJSON(), width: 230 }}
                    task="fil_concepto"
                    close={setShowModal}
                    call={filterList} />;
                break;
            default:
                _window = null;
                break;
        }

        setModalView(_window);
        setShowModal(true);
    }

    const filterList = async (_criterio) => {
        setShowLoading(true);

        let _data = await api.current.fetchJson({
            url: "info-caja",
            data: {
                task: _criterio.task,
                criterio: _criterio.criterio,
                caja: tipo_caja,
                offset: 0
            }
        });

        if (_data.response == 1) {
            if (_data.data.length < 1) {
                Alert(`No se encontraron resultados para "${_criterio.criterio}" intentalo de nuevo.`, "warning");
            } else {
                setPreviousQuery({ task: _criterio.task, offset: 0 });
                setShowLazy(_data.data.length < 50 ? false : true);
                setDataList(_data.data);
            }
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowModal(false);
        setShowLoading(false);
    }

    const reloadList = async () => {
        setShowLoading(true);
        parent.update();

        let _data = await api.current.fetchJson({
            url: "info-caja",
            data: {
                task: previousQuery.task,
                caja: tipo_caja,
                limit: dataList.length,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getOnly = async (e) => {
        e.preventDefault();
        setShowLoading(true);

        let _data = await api.current.fetchJson({
            url: "info-caja",
            data: {
                task: "abonos",
                caja: tipo_caja,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setPreviousQuery({ task: "abonos", offset: 0 });
            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getListLazy = async () => {
        setShowLoading(true);

        let _data = await api.current.fetchJson({
            url: "info-caja",
            data: {
                task: previousQuery.task,
                caja: tipo_caja,
                offset: dataList.length
            }
        });

        if (_data.response == 1) {
            let _packs = [];

            dataList.map(item => {
                _packs.push(item);
            });

            _data.data.map(item => {
                _packs.push(item);
            });

            setPreviousQuery({ task: previousQuery.task, offset: dataList.length });
            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_packs);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const getList = async () => {
        setShowLoading(true);

        let _data = await api.current.fetchJson({
            url: "info-caja",
            data: {
                task: "movimientos_cli",
                caja: tipo_caja,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setPreviousQuery({ task: "movimientos_cli", offset: 0 });
            setShowLazy(_data.data.length < 50 ? false : true);
            setDataList(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const History = () => {
        return <>
            <div className="dashboard__sales-table">
                {dataList.length < 1 ?
                    <h4>No hay movimientos en esta caja</h4>
                    :
                    <table className="table">
                        <tbody>

                            <tr className="table_title">
                                <th><FilerIcon className="to_filter" onClick={e => handleModal(e, "fil_fecha")} />Fecha</th>
                                <th>Tipo</th>
                                <th>Monto</th>
                                <th><FilerIcon className="to_filter" onClick={e => handleModal(e, "fil_concepto")} />Concepto</th>
                                <th style={{ whiteSpace: "nowrap" }}>Quien registró</th>
                            </tr>

                            {dataList.map((item, index) => {
                                let fecha_creado = new Date(item.fecha);

                                return <tr key={index}>
                                    <td>{format(fecha_creado.getDate())}/{meses[fecha_creado.getMonth()]}/{fecha_creado.getFullYear()} {format(fecha_creado.getHours())}:{format(fecha_creado.getMinutes())}</td>
                                    <td>{item.metodo == 1 ? "efectivo" : "transferencia"}</td>
                                    <td style={{ textAlign: "right" }} className="con_icon">
                                        {([13, 14, 15].includes(parseInt(item.movimiento)) && data_user.nivel < 3) && <Editcon onClick={e => handleModal(e, "abono", item)} />}
                                        {funciones.formatPrice(item.monto).format}
                                    </td>
                                    <td style={{ whiteSpace: "pre" }}>{item.concepto}</td>
                                    <td style={{ whiteSpace: "pre" }}>{item.gestor_name}</td>
                                </tr>
                            })}

                            <tr className="_no_margin">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                }
            </div>
            {showLoading ?
                <button className="btn_lazy_load"><Icon.Loading /></button>
                :
                <>
                    {showLazy ?
                        <button className="btn_lazy_load" onClick={() => getListLazy()}>Cargar más</button>
                        :
                        <h4 className="h4_lazy_load">¡No hay más movimientos!</h4>
                    }
                </>
            }
        </>;
    }

    React.useEffect(() => {
        if (parent.inload) {
            getList();
        }
    }, [parent]);

    return (
        <div className="content_reportes">
            <Col className="col_table">
                <Card style={{ overflow: "hidden" }}>
                    <CardBody className="dashboard__card-widget">
                        <div className="card__title">
                            <h5 className="bold-text"><ChartTimelineIcon className="dashboard__money-icon" /> Movimientos</h5>

                            {showLoading ?
                                <button className="cash-button"><Icon.Loading /></button>
                                :
                                <button className="cash-button" onClick={getOnly}><Icon.CashMultiple />Ver solo abonos</button>
                            }
                        </div>

                        <div className="panel__btns">

                            <button
                                className="panel__btn"
                                aria-label="panel__btn"
                                type="button"
                                onClick={handlerRefresh}>
                                <AutorenewIcon className={showLoading ? "on_refresh" : ""} />
                            </button>

                        </div>

                        <History />
                    </CardBody>
                </Card>
            </Col>

            {showModal && modalView}
        </div>
    )
}

export default Movimientos;