import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';

const SidebarLink = ({ title = "", route = '/', state, icon = <></>, newLink = false, onClick = () => { } }) => (
    <NavLink
        to={route} end
        state={state}
        onClick={onClick}
        className={({ isActive }) => isActive ? "sidebar__link-active" : ""}>
        <li className="sidebar__link">
            {icon ?? ''}
            <p className="sidebar__link-title">
                {title}
                {newLink ? <Badge className="sidebar__link-badge"><span>New</span></Badge> : ''}
            </p>
        </li>
    </NavLink>
);

SidebarLink.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.element,
    newLink: PropTypes.bool,
    route: PropTypes.any,
    onClick: PropTypes.func,
};

export default SidebarLink;
