import * as React from "react";
import InformeMensajeros from "./components/InformeMensajeros";
import InformePorCobrar from "./components/InformePorCobrar";
import InformeClientes from "./components/InformeClientes";
import InformePorPagar from "./components/InformePorPagar";
import InformeEnBodega from "./components/InformeEnBodega";
import { RequirePermissions } from "shared/AlertMessages";
import AutorenewIcon from "mdi-react/AutorenewIcon";
import { TitleSticky } from "shared/Components";
import { useNavigate } from "react-router-dom";
import { Alert, Confirm } from "shared/Alert";
import { Container, Row } from "reactstrap";
import funciones from "services/funciones";
import Loading from "shared/Loading";
import { Api } from "services/api";
import * as Iconos from "images";
import { useMedia } from "hooks";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-extend-native: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Informes = () => {
    const navigate = useNavigate();
    const { setTitulo, setDataUser } = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [valores, setValores] = React.useState({ pagos: 0, cobros: 0, diferencia: 0, caja_cli: 0, caja_bdg: 0, transfer_sin_corte: 0 });
    const data_user = JSON.parse(localStorage.getItem("usuario")) ?? { nivel: 7 };
    const [showLoading, setShowLoading] = React.useState(true);
    const [showModal, setShowModal] = React.useState(false);
    const [modalView, setModalView] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [resumCobro, setResumCobro] = React.useState([]);
    const [porCobrar, setPorCobrar] = React.useState([]);
    const [resumPago, setResumPago] = React.useState([]);
    const [enBodega, setEnBodega] = React.useState([]);
    const [porPagar, setPorPagar] = React.useState([]);


    const handleModal = (e, _option) => {
        e.preventDefault();
        e.stopPropagation();
        let _window;

        switch (_option) {
            case "informe_cli":
                _window = <InformeClientes
                    parent={{ ...e.target.getBoundingClientRect().toJSON(), width: 220 }}
                    close={setShowModal} />;
                break;
            case "informe_msj":
                _window = <InformeMensajeros
                    parent={{ ...e.target.getBoundingClientRect().toJSON(), width: 230 }}
                    close={setShowModal} />;
                break;
            default:
                _window = null;
                break;
        }

        setModalView(_window);
        setShowModal(true);
    }

    const guardaCierre = async () => {
        setLoading(true);

        let _data = await api.current.fetchJson({
            url: "cerrar-caja",
            data: {
                task: "cierre_total",
                valores: JSON.stringify(valores)
            }
        });

        if (_data.response == 1) {
            Alert(_data.msg, "ok");
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setLoading(false);
    }

    const getInfo = async () => {
        setShowLoading(true);
        /** Cajas
         *
         * 1 caja mensajeros
         * 2 caja clientes
         * 3 caja bts
         * 4 caja bodega
         * 5 caja auxiliar
        */
        let _data = await api.current.fetchJson({
            url: "get-saldos",
            data: {
                caja_cli: true
            }
        });

        if (_data.response == 1) {
            let _resum_pago = [];
            let _resum_cobro = [];
            let _clientes_por_cobrar = [];
            let _clientes_por_pagar = [];
            let _por_cobrar = [];
            let _por_pagar = [];
            let _en_bodega = [];
            let _valor_caja_cli = 0;
            let _valor_caja_bdg = 0;
            let _valor_trans_sin_corte = 0;

            _data.caja_cli.map(item => {
                if (item.metodo == 3) {
                    _valor_trans_sin_corte += parseInt(item.monto || 0);
                } else {
                    _valor_caja_cli += parseInt(item.monto || 0);
                }
            });

            _data.caja_bdg.map(item => {
                _valor_caja_bdg += parseInt(item.monto || 0);
            });

            _data.data.map(item => {
                let _info = JSON.parse(item.info);
                let _resum = JSON.parse(_info.resumen);

                if (_info.saldo == "por_pagar") {
                    _info.paquete.map(i => {
                        _por_pagar.push(i);
                    });

                    _clientes_por_pagar.push(item.id_client);
                    _resum_pago.push(_resum);
                }

                if (_info.saldo == "por_cobrar") {
                    _info.paquete.map(i => {
                        _por_cobrar.push(i);
                    });

                    _clientes_por_cobrar.push(item.id_client);
                    _resum_cobro.push(_resum);
                }
            });

            _data.recogidas.map(item => {
                let _inf_order = JSON.parse(item.info);
                _inf_order.recogida.valor_producto = parseInt(_inf_order.recogida.valor_producto || 0);
                _inf_order.recogida.valor_producto = _inf_order.recogida.valor_producto > 0 ? -_inf_order.recogida.valor_producto : _inf_order.recogida.valor_producto;

                let _order = {
                    estado: 100,
                    all_states: "100",
                    consignee_name: item.consignee_name,
                    codigo: "OR-" + item.consignee + "-" + item.consecutive,
                    carrier_name: item.carrier_name,
                    carrier: _inf_order.recogida.carrier,
                    price: _inf_order.recogida.valor_producto,
                    v_mensajero: parseInt(_inf_order.recogida.valor_mensajero || 0),
                    v_domicilio: parseInt(_inf_order.recogida.valor_domicilio || 0),
                    subtotal_mensajero: (_inf_order.recogida.valor_producto + -parseInt(_inf_order.recogida.valor_mensajero || 0)),
                    subtotal: (_inf_order.recogida.valor_producto + -parseInt(_inf_order.recogida.valor_domicilio || 0)),
                    efectivo: _inf_order.recogida.valor_producto,
                    bold: 0,
                };

                if (_clientes_por_pagar.includes(item.consignee)) {
                    _por_pagar.push(_order);
                }

                if (_clientes_por_cobrar.includes(item.consignee)) {
                    _por_cobrar.push(_order);
                }
            });

            _data.en_bodega.map(item => {
                _en_bodega.push(item);
            });

            setResumCobro(_resum_cobro);
            setPorCobrar(_por_cobrar);

            setResumPago(_resum_pago);
            setPorPagar(_por_pagar);

            setEnBodega(_en_bodega);

            setValores(p => ({
                ...p,
                caja_bdg: _valor_caja_bdg,
                caja_cli: _valor_caja_cli,
                transfer_sin_corte: _valor_trans_sin_corte,
                diferencia: (p.pagos - ((p.caja_cli + p.caja_bdg) - p.cobros))
            }));
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    const handleClose = (e) => {
        e.preventDefault();
        e.stopPropagation();

        Confirm("¿Seguro de continuar con el cierre?", (response) => {
            if (response) {
                guardaCierre();
            }
        });
    }

    React.useEffect(() => {
        setTitulo("Informes y cierre");
        document.body.style.overflow = "auto";
        getInfo();
    }, []);

    return (
        <RequirePermissions navigate={navigate}
            permission={funciones.validaPermisos("descargar_informe") && [1, 2].includes(parseInt(data_user.nivel))}>

            <TitleSticky className="_in_page">Informes y cierre</TitleSticky>

            <Container className="dashboard _caja">
                <Row className="_en_caja">

                    <div className="panel__btns _en_caja">

                        <button
                            className="panel__btn"
                            type="button"
                            onClick={() => getInfo()}>
                            <AutorenewIcon className={showLoading ? "on_refresh" : ""} />
                        </button>

                    </div>

                    <h4 className="_precio">
                        ${funciones.formatPrice(valores.caja_cli).format} <span className="fija">En caja de clientes</span>
                        <br />
                        ${funciones.formatPrice(valores.transfer_sin_corte).format} <span className="fija">Transferencias<br /><i>(solo sin corte)</i></span>
                        <br />
                        ${funciones.formatPrice(valores.caja_bdg).format} <span className="fija">En caja de bodega <br /><i>(solo abonos de clientes)</i></span>
                        <br />
                        ${funciones.formatPrice(valores.cobros).format} <span className="fija">Por cobrar</span>
                        <br />
                        ${funciones.formatPrice(valores.pagos).format} <span className="fija">Por pagar</span>
                        <br />
                        ${funciones.formatPrice(valores.diferencia).format} <span className="fija">Diferencia</span>
                    </h4>

                    <div className="cont_btns_caja">
                        <button className="cash-button" onClick={e => handleModal(e, "informe_cli")}><Iconos.ExcelDownload />Informe clientes</button>
                        <button className="cash-button" onClick={e => handleModal(e, "informe_msj")}><Iconos.ExcelDownload />Informe mensajeros</button>

                        <InformeEnBodega data={{
                            packs: enBodega,
                            resumen: resumPago,
                            inload: showLoading,
                        }} />

                        <InformePorPagar data={{
                            packs: porPagar,
                            resumen: resumPago,
                            inload: showLoading,
                            update: (saldo) => setValores(p => ({
                                ...p,
                                pagos: saldo,
                                diferencia: (saldo - ((p.caja_cli + p.caja_bdg) - p.cobros))
                            }))
                        }} />

                        <InformePorCobrar data={{
                            packs: porCobrar,
                            resumen: resumCobro,
                            inload: showLoading,
                            update: (saldo) => setValores(p => ({
                                ...p,
                                cobros: saldo,
                                diferencia: (p.pagos - ((p.caja_cli + p.caja_bdg) - saldo))
                            }))
                        }} />

                        <button className="cash-button" onClick={handleClose}><Iconos.CajaClose />Cierre del día</button>
                    </div>
                </Row>

                {showModal && modalView}
                {loading && <Loading />}
            </Container>
        </RequirePermissions>
    )
}

export default Informes;
