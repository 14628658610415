import * as React from "react";
import SwapVerticalIcon from "mdi-react/SwapVerticalIcon";
import { RequirePermissions } from "shared/AlertMessages";
import PlugIcon from "mdi-react/AccountPlusIcon";
import { TitleSticky } from "shared/Components";
import { NewAgent } from "./components/Agent";
import withRouter from "services/withRouter";
import { SearchUsers } from "shared/Input";
import MenuRow from "./components/MenuRow";
import funciones from "services/funciones";
import Loading from "shared/Loading";
import { Alert } from "shared/Alert";
import { Api } from "services/api";
import { useMedia } from "hooks";
import * as Icon from "images";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Usuarios = (router) => {
    const { navigate, location } = router;
    const { media_movil, data_user, setTitulo, currentQuery, setCurrentQuery, setDataUser } = useMedia();
    const api = React.useRef(new Api({ navigate, setDataUser }));
    const [loadResult, setLoadResult] = React.useState("Cargando usuarios...");
    const [selected, setSelected] = React.useState({ id_user: 0 });
    const [showLoading, setShowLoading] = React.useState(true);
    const [permission, setPermission] = React.useState(false);
    const [modalView, setModalView] = React.useState(null);
    const [showLazy, setShowLazy] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);
    const [asc, setAsc] = React.useState(false);
    const [rol, setRoll] = React.useState(7);
    const [task, setTask] = React.useState();


    /**
     * Niveles de los usuarios 
     * 
     * 1 Super Administrador
     * 2 Administrador
     * 3 Coordinador de zona
     * 4 Mensajero
     * 5 Recepcionista
     * 6 Cliente bts
     * 7 Usuario final
     * 8 Cajero
     * 
     */

    const changeTask = (_rol) => {
        let toReturn;

        switch (parseInt(_rol)) {
            case 1:
                toReturn = "super administradores";
                break;
            case 2:
                toReturn = "administradores";
                break;
            case 3:
                toReturn = "coordinadores de zona";
                break;
            case 4:
                toReturn = "mensajeros";
                break;
            case 5:
                toReturn = "recepcionistas";
                break;
            case 6:
                toReturn = "clientes bts";
                break;
            case 8:
                toReturn = "cajeros";
                break;
            case 7:
            default:
                toReturn = "usuarios finales";
                break;
        }

        setTask(toReturn);
        return toReturn;
    }

    const Row = ({ data, blue }) => {

        return (
            <MenuRow data={{ ...data, rol: rol }} blue={blue}
                navigate={navigate}
                current={selected.id_user}
                onSel={setSelected}
                reload={() => getList(currentQuery.users, task)}>

                <td>{data.nombre} {data.apellido}</td>
                <td>{data.correo}</td>
                <td>{data.telefono}</td>
            </MenuRow>
        );
    }

    const AllRow = () => {
        const handleModal = (e) => {
            let _window = <NewAgent
                data={{ rol: rol }}
                parent={{
                    ...e.target.getBoundingClientRect().toJSON(),
                    width: media_movil ? (document.getElementById("root").offsetWidth - 16) : 500
                }}
                close={() => setModalView(null)}
                reload={() => getList(currentQuery.users, task)} />;

            setModalView(_window);
        }

        let _data = dataList.map((item, index) => {
            return <Row key={item.id_user} blue={index % 2} data={item} />;
        });

        if (_data.length < 1) {
            return <>
                <h4 className="_titulo">{loadResult}</h4>
                <h4 className="_button" onClick={handleModal}>Crear Nuevo {task}<PlugIcon /></h4>

                {modalView}
            </>;
        } else {
            return <>
                <div style={{ overflowX: "scroll" }}>
                    <table className="table">
                        <tbody>

                            <tr className="table_title">
                                <th><SwapVerticalIcon onClick={() => sortBy("nombre")} />Nombre y apellido</th>
                                <th>Correo</th>
                                <th>Teléfono</th>
                            </tr>

                            {_data}
                        </tbody>
                    </table>
                </div>

                {showLoading ?
                    <button className="btn_lazy_load"><Icon.Loading /></button>
                    :
                    <>
                        {showLazy ?
                            <button className="btn_lazy_load" onClick={() => getListLazy()}>Cargar más</button>
                            :
                            <h4 className="h4_lazy_load">{loadResult}</h4>
                        }

                        <h4 className="_button" onClick={handleModal}>Crear Nuevo {task}<PlugIcon /></h4>
                        {modalView}
                    </>
                }
            </>;
        }
    }

    const sortBy = (_key) => {

        let mapped = dataList.map((item, i) => {
            return { index: i, value: item[_key].toLowerCase() };
        });

        mapped.sort(function (a, b) {
            if (asc) {
                if (a.value > b.value) {
                    return 1;
                }

                if (a.value < b.value) {
                    return -1;
                }
            } else {
                if (a.value > b.value) {
                    return -1;
                }

                if (a.value < b.value) {
                    return 1;
                }
            }

            return 0;
        });

        let _new = mapped.map(item => {
            return dataList[item.index];
        });

        setAsc(!asc);
        setDataList(_new);
    }

    const getListLazy = async () => {
        setShowLoading(true);

        let _data = await api.current.fetchJson({
            url: "get-users",
            data: {
                roll: currentQuery.users.task,
                nivel: currentQuery.users.rol,
                criterio: currentQuery.users.criterio,
                limit: 20,
                offset: dataList.length
            }
        });

        if (_data.response == 1) {
            let _packs = [];

            dataList.map(item => {
                _packs.push(item);
            });

            _data.data.map(item => {
                _packs.push(item);
            });

            setDataList(_packs);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay " + task + "!" : "¡No hay más " + task + "!");
        setShowLoading(false);
    }

    const handleSearch = async (_val) => {
        setShowLoading(true);
        _val = _val.toLowerCase();
        setLoadResult("Buscando " + task + " con '" + _val + "'!");

        setCurrentQuery(v => ({ ...v, users: { task: "search", criterio: _val, rol } }));
        setDataList([]);
        let _data = await api.current.fetchJson({
            url: "get-users",
            data: {
                roll: "search",
                nivel: rol,
                criterio: _val,
                limit: 20,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay " + task + " con '" + _val + "'!" : "¡No hay más " + task + " con '" + _val + "'!");
        setShowLoading(false);
    }

    const getList = async (_query, _task) => {
        setShowLoading(true);
        setDataList([]);
        let _data = await api.current.fetchJson({
            url: "get-users",
            data: {
                roll: _query.task,
                nivel: _query.rol,
                criterio: _query.criterio,
                limit: 20,
                offset: 0
            }
        });

        if (_data.response == 1) {
            setDataList(_data.data);
            setShowLazy(_data.data.length < 20 ? false : true);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => {
                document.body.style.overflow = "auto";
                navigate("/sign-in");
            });
        } else {
            Alert(_data.msg, "warning");
        }

        setLoadResult((!_data?.data || _data?.data.length < 1) ? "¡No hay " + _task + "!" : "¡No hay más " + _task + "!");
        setShowLoading(false);
    }

    React.useEffect(() => {
        document.body.style.overflow = "auto";

        if (location.state && location.state.rol) {
            let _rol = location.state.rol;
            let _task = changeTask(_rol);
            let _permission = false;


            switch (_rol) {
                case 1:
                    // 1 super admin
                    _permission = funciones.validaPermisos("user_admin") && (data_user.auth && window.atob(data_user.auth) === "1");
                    break;
                case 2:
                    // 2 Administrador
                    _permission = funciones.validaPermisos("user_admin") && [1, 2].includes(parseInt(data_user.nivel));
                    break;
                case 3:
                    // 3 Coordinador de zona
                    _permission = funciones.validaPermisos("user_coordi") && [1, 2].includes(parseInt(data_user.nivel));
                    break;
                case 4:
                    // 4 Mensajero
                    _permission = funciones.validaPermisos("user_mensajero") && [1, 2].includes(parseInt(data_user.nivel));
                    break;
                case 5:
                    //  5 Recepcionista
                    _permission = funciones.validaPermisos("user_recepcionista") && [1, 2].includes(parseInt(data_user.nivel));
                    break;
                case 6:
                    // 6 Cliente bts
                    _permission = funciones.validaPermisos("user_cliente") && [1, 2].includes(parseInt(data_user.nivel));
                    break;
                case 7:
                    // 7 Usuario final
                    _permission = funciones.validaPermisos("user") && [1, 2].includes(parseInt(data_user.nivel));
                    break;
                case 8:
                    // 8 Cajero
                    _permission = funciones.validaPermisos("user_cajero") && [1, 2].includes(parseInt(data_user.nivel));
                    break;
            }

            if (_permission) {
                setPermission(_permission);
                setRoll(_rol);
                setTitulo("Lista de " + _task);
                setLoadResult("Cargando " + _task + "...");
                if (currentQuery.users.rol !== _rol) {
                    setCurrentQuery(v => ({ ...v, users: { task: "users", criterio: "", rol: _rol } }));
                    getList({ task: "users", criterio: "", rol: _rol }, _task);
                } else {
                    getList(currentQuery.users, _task);
                }
            }
        } else {
            Alert("No se pasaron parámetros o la pagina se cargo de forma incorrecta",
                "warning",
                () => navigate(-1));
        }

    }, [location.state]);

    return (
        <RequirePermissions navigate={navigate}
            permission={permission}>

            <div className="cont_principal">
                <TitleSticky className="_in_filters">Lista de {task}</TitleSticky>
                <div className="cont_search_bar">
                    <div className="search_bar">
                        <SearchUsers type="text" placeholder="Buscar por nombre"
                            value={currentQuery.users.criterio}
                            onChange={handleSearch}
                            onClean={() => {
                                setCurrentQuery(v => ({ ...v, users: { task: "users", criterio: "", rol } }));
                                getList({ task: "users", criterio: "", rol }, task);
                            }}
                        />
                    </div>
                </div>

                <AllRow />
            </div>


            {showLoading && <Loading />}
        </RequirePermissions>
    )
}

export default withRouter(Usuarios);
