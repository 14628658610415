import CryptoJS from "crypto-js";
import log from "services/log";

/* eslint eqeqeq: 0 */
/* eslint import/no-anonymous-default-export: 0 */

export class Api {

    constructor(obj) {
        this.hooks = obj;
    }

    /**
     * Genera la firma de autenticación
     * const authorization = 'Digest auth="YWRtaW4=", time="1620369347", response="8531882e866fafe922b2d8636ff8a911"';
     * @return: 
     */
    getAuth(type = "normal") {
        let _token = "mZhndVeRrhLoNc6qaQNy0BldbBYtIzQa69+tD9/kdMN/aCjjMZgv6yhfwfPXrVN/elk8qOYA17/0BsiAFtjoBVUwzewccWKH9ekdGE+yteIOtPr9gvYDJKZL1Adkiia4";
        let _guest = "aW52aXRhZG8=";
        let _data = JSON.parse(localStorage.getItem('usuario'));
        let _auth = _data == null ? window.btoa(0) : _data.auth;
        let current_time = new Date().getTime() / 1000;
        let limit_time = _data == null ? 0 : _data.time;
        let _time = ((limit_time - current_time) / 60).toFixed(0);

        if (_time > 60) {
            _time = (_time / 60).toFixed(0);
            if (_time > 24) {
                log.i("Tiempo restante: " + (_time / 24).toFixed(1) + " días");
            } else {
                log.i("Tiempo restante: " + _time + " horas");
            }
        } else {
            log.i("tiempo restante: " + _time + " minutos");
        }

        if (type === "invitado") {
            return `Digest auth="${_guest}", time="${current_time.toFixed(0)}", alive="${typeof android !== "undefined" ? "android" : "1"}", nonce="${Math.floor(Math.random() * 100)}", token="${_token}", validate="${CryptoJS.MD5(_guest + ":" + current_time.toFixed(0) + ":" + _token).toString()}"`;
        } else if (typeof android !== "undefined") {
            return `Digest auth="${_auth}", time="${_data.time}", alive="android", nonce=${_data.nonce}, token="${_data.token}", validate="${_data.validate}"`;
        } else {

            if ((limit_time - current_time) <= 0) {
                localStorage.removeItem('usuario');
                log.e("Se borró la sesión con '" + (limit_time - current_time).toFixed(0) + "' segundos");
                return `Digest auth="${_guest}", time="${current_time.toFixed(0)}", nonce="${Math.floor(Math.random() * 100)}", token="${_token}", validate="${CryptoJS.MD5(_guest + ":" + current_time.toFixed(0) + ":" + _token).toString()}"`;
            } else {
                return `Digest auth="${_auth}", time="${_data.time}", nonce=${_data.nonce}, token="${_data.token}", validate="${_data.validate}"`;
            }
        }

    }

    /**
     * Verifica el estado de la respuesta en la consulta 
     * @param response: La respuesta generada en la consulta 
     */
    verificaEstado(response) {
        switch (response.status) {
            case 200:
            case 210:
                //log.d('Conexión corecta', response.status);
                break;
            case 401:
            case 403:
            case 422:
                log.e('Error', response.status);
                break;
            case 404:
                throw new Error('404 - No se encuentra el recurso!');
            case 500:
            case 501:
            case 502:
                log.e('Error', response.status);
                // throw new Error('500 - Ocurrio un error en la sintaxis!');
                break;
            case 503:
                throw new Error('503 - Servicio no disponible!');
            default:
                throw new Error(`(${response.status}) es un código inesperado, puedes reportarlo al correo "desarrollo@sarguero.com" para revisarlo más a fondo`);
        }
    }

    async getServer() {
        let datos;

        await fetch(process.env.REACT_APP_SERVER + "/.currentServer")
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error al obtener el archivo');
                }
                return response.text();
            })
            .then(data => {
                datos = data;
            })
            .catch(error => {
                log.e('Error:', error);
                datos = process.env.REACT_APP_WEB_API;
            });

        return datos;
    }

    /**
     * Hace una consulta al servidor 
     * @param {JSON} params: json con { `url`: string, `data`: json }
     * @return {Promise<JSON>}: `json` con los datos solicitados 
     */
    async fetchJson(params) {
        let web_api = await this.getServer();
        let datos;

        await fetch(web_api + "/" + params.url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(params.data)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                log.e('Ocurrio un error', error);
                datos = { "response": -1, "msg": error.toString() };
            });

        if (datos.response == 1 && datos?.auth?.token) {
            try {
                await this.updateSession(datos, web_api);
            } catch (error) {
                log.e("Error al guardar la sesión en el localStorage:", error);
            }
        }

        return datos;
    }

    /**
     * Envia datos al servidor 
     * @param {FormData} data: form-data 
     * @param {string} url: string
     * @return {Promise<JSON>}: json con la respusta
     */
    async fetchFormData(data, url) {
        let web_api = await this.getServer();
        let datos;

        await fetch(web_api + "/" + url, {
            method: 'POST',
            headers: {
                'Authorization': this.getAuth()
            },
            body: data,
            progress: (e) => { log.w(`Progress: ${e.loaded / e.total}%`) }
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                log.e('1-Ocurrio un error', error);
                datos = { "response": -1, "msg": error.toString() };
            });

        if (datos.response == 1 && datos?.auth?.token) {
            try {
                await this.updateSession(datos, web_api);
            } catch (error) {
                log.e("Error al guardar la sesión en el localStorage:", error);
            }
        }

        return datos;
    }


    /**
     * Obtiene listas 
     * @param params: Los parametros en formato json
     * @return: json con los datos de la lista
     */
    async getPickUpList(params) {
        let web_api = await this.getServer();
        let datos;

        await fetch(`${web_api}/pickup-list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(params)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        if (datos.response == 1 && datos?.auth?.token) {
            try {
                await this.updateSession(datos, web_api);
            } catch (error) {
                log.e("Error al guardar la sesión en el localStorage:", error);
            }
        }

        return datos;
    }

    async getReceptionList(params) {
        let web_api = await this.getServer();
        let datos;

        await fetch(`${web_api}/reception-list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(params)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        if (datos.response == 1 && datos?.auth?.token) {
            try {
                await this.updateSession(datos, web_api);
            } catch (error) {
                log.e("Error al guardar la sesión en el localStorage:", error);
            }
        }

        return datos;
    }

    async getHistory(params) {
        let web_api = await this.getServer();
        let datos;

        await fetch(`${web_api}/get-history`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(params)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        if (datos.response == 1 && datos?.auth?.token) {
            try {
                await this.updateSession(datos, web_api);
            } catch (error) {
                log.e("Error al guardar la sesión en el localStorage:", error);
            }
        }

        return datos;
    }

    /**
     * Otiene los paquetes asociados a la orden
     * @param id: id de la orden
     * @return: json con la respuesta 
     */
    async getPackages(id) {
        let web_api = await this.getServer();
        let datos;

        await fetch(`${web_api}/get-packages`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify({
                type: "",
                id: id,
            })
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        if (datos.response == 1 && datos?.auth?.token) {
            try {
                await this.updateSession(datos, web_api);
            } catch (error) {
                log.e("Error al guardar la sesión en el localStorage:", error);
            }
        }

        return datos;
    }


    /**
     * Guardar ordenes 
     * @param info: La información de la orden en formato json
     * @return: json con la respuesta 
     */
    async savePickUpOrder(info) {
        let web_api = await this.getServer();
        let datos;

        await fetch(`${web_api}/new-pickup-order`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        if (datos.response == 1 && datos?.auth?.token) {
            try {
                await this.updateSession(datos, web_api);
            } catch (error) {
                log.e("Error al guardar la sesión en el localStorage:", error);
            }
        }

        return datos;
    }

    async saveReceptionOrder(info) {
        let web_api = await this.getServer();
        let datos;

        await fetch(`${web_api}/new-reception-order`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        if (datos.response == 1 && datos?.auth?.token) {
            try {
                await this.updateSession(datos, web_api);
            } catch (error) {
                log.e("Error al guardar la sesión en el localStorage:", error);
            }
        }

        return datos;
    }

    async saveAssignPackage(info) {
        let web_api = await this.getServer();
        let datos;

        await fetch(`${web_api}/save-assign-package`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        if (datos.response == 1 && datos?.auth?.token) {
            try {
                await this.updateSession(datos, web_api);
            } catch (error) {
                log.e("Error al guardar la sesión en el localStorage:", error);
            }
        }

        return datos;
    }

    async saveAssignPackages(info) {
        let web_api = await this.getServer();
        let datos;

        await fetch(`${web_api}/save-assign-packages`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        if (datos.response == 1 && datos?.auth?.token) {
            try {
                await this.updateSession(datos, web_api);
            } catch (error) {
                log.e("Error al guardar la sesión en el localStorage:", error);
            }
        }

        return datos;
    }

    async saveAssignOrder(info) {
        let web_api = await this.getServer();
        let datos;

        await fetch(`${web_api}/save-assign-order`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        if (datos.response == 1 && datos?.auth?.token) {
            try {
                await this.updateSession(datos, web_api);
            } catch (error) {
                log.e("Error al guardar la sesión en el localStorage:", error);
            }
        }

        return datos;
    }

    async saveAssignOrders(info) {
        let web_api = await this.getServer();
        let datos;

        await fetch(`${web_api}/save-assign-orders`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        if (datos.response == 1 && datos?.auth?.token) {
            try {
                await this.updateSession(datos, web_api);
            } catch (error) {
                log.e("Error al guardar la sesión en el localStorage:", error);
            }
        }

        return datos;
    }


    /**
     * Editar ordenes 
     * @param info: La información de la orden en formato json
     * @return: json con la respuesta 
     */
    async editPickUpOrder(info) {
        let web_api = await this.getServer();
        let datos;

        await fetch(`${web_api}/update-pickup-order`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        if (datos.response == 1 && datos?.auth?.token) {
            try {
                await this.updateSession(datos, web_api);
            } catch (error) {
                log.e("Error al guardar la sesión en el localStorage:", error);
            }
        }

        return datos;
    }

    async editReceptionOrder(info) {
        let web_api = await this.getServer();
        let datos;

        await fetch(`${web_api}/update-reception-order`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        if (datos.response == 1 && datos?.auth?.token) {
            try {
                await this.updateSession(datos, web_api);
            } catch (error) {
                log.e("Error al guardar la sesión en el localStorage:", error);
            }
        }

        return datos;
    }


    /**
    * Borrar ordenes
    * @param info: La información de la orden en formato json
    * @return: json con la respuesta 
    */
    async deleteOrder(info) {
        let web_api = await this.getServer();
        let datos;

        await fetch(`${web_api}/delete-order`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        if (datos.response == 1 && datos?.auth?.token) {
            try {
                await this.updateSession(datos, web_api);
            } catch (error) {
                log.e("Error al guardar la sesión en el localStorage:", error);
            }
        }

        return datos;
    }

    async deletePackage(info) {
        let web_api = await this.getServer();
        let datos;

        await fetch(`${web_api}/delete-package`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        if (datos.response == 1 && datos?.auth?.token) {
            try {
                await this.updateSession(datos, web_api);
            } catch (error) {
                log.e("Error al guardar la sesión en el localStorage:", error);
            }
        }

        return datos;
    }

    async deleteUser(info) {
        let web_api = await this.getServer();
        let datos;

        await fetch(`${web_api}/delete-user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        if (datos.response == 1 && datos?.auth?.token) {
            try {
                await this.updateSession(datos, web_api);
            } catch (error) {
                log.e("Error al guardar la sesión en el localStorage:", error);
            }
        }

        return datos;
    }

    /**
     * Verifica el correo y la clave en la base de datos 
     * @param {String} email: el correo del usuario en texto plano
     * @param {String} pass: La clave del usuario en texto plano
     * @return {Promise<JSON>}: Json con los datos del usuario 
     */
    async signIn(email, pass) {
        let web_api = await this.getServer();
        let clave = CryptoJS.SHA256(pass);
        let datos;
        let token = "";

        if (typeof android !== "undefined") {
            // eslint-disable-next-line no-undef
            token = await android.getToken();

            log.d("token: " + token);
        }

        await fetch(`${web_api}/sign-in`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.getAuth("invitado")
            },
            body: JSON.stringify({
                email: email.trim(),
                pass: clave.toString(),
                token: token
            })
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }

    async signInAdmin(email, clave) {
        let web_api = await this.getServer();
        let datos;

        await fetch(`${web_api}/sign-in`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.getAuth("invitado")
            },
            body: JSON.stringify({
                email: email,
                pass: clave,
                token: ""
            })
        })
            .then(response => {
                this.verificaEstado(response);
                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        return datos;
    }

    /**
 * Establece una nueva contraceña y envia un correo 
 * @param email: El correo al cual se enviara la nueva clave 
 * @return Json: {response, msg} el estado y la respuesta
 */
    async resetPassword(email) {
        let web_api = await this.getServer();
        let datos;

        await fetch(`${web_api}/reset-pass`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify({
                email: email.trim()
            })
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        // if (datos.response == 1 && datos?.auth?.token) {
        //     let _resp = await this.sendMail(1, email, clave);
        //     return _resp.response == -1 ? _resp : datos;
        // } else {
        //     return datos;
        // }
        return datos;
    }

    async getSession() {
        let web_api = await this.getServer();
        let datos;

        await fetch(`${web_api}/valida-session`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.getAuth()
            }
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        if (datos.response == 1 && datos?.auth?.token) {
            try {
                datos.usuario = await this.updateSession(datos, web_api);
            } catch (error) {
                log.e("Error al guardar la sesión en el localStorage:", error);
            }
        }

        return datos;
    }

    /**
     * Guarda una nueva agencia
     * @param info: La información de la agencia en formato json
     * @return: json con la respuesta 
     */
    async saveAgent(info) {
        let web_api = await this.getServer();
        let datos;

        await fetch(`${web_api}/save-agent`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(info)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                datos = { "response": -1, "msg": error.toString() };
            });

        if (datos.response == 1 && datos?.auth?.token) {
            try {
                await this.updateSession(datos, web_api);
            } catch (error) {
                log.e("Error al guardar la sesión en el localStorage:", error);
            }
        }

        return datos;
    }

    async updateSession(datos, current_server) {

        return new Promise((resolve, reject) => {
            try {
                let _data = JSON.parse(localStorage.getItem('usuario'));
                let _user;

                if (_data == null) {
                    this.hooks?.navigate && this.hooks?.navigate("/sign-in");
                } else {
                    _user = {
                        ..._data,
                        time: datos?.auth?.time,
                        nonce: datos?.auth?.nonce,
                        token: datos?.auth?.token,
                        version: datos?.auth?.version,
                        current_server: current_server,
                        validate: datos?.auth?.validate
                    };

                    localStorage.setItem('usuario', JSON.stringify(_user));
                    this.hooks?.setDataUser && this.hooks?.setDataUser(_user);
                }

                resolve(_user);
            } catch (error) {
                reject(error);
            }
        });
    }
}

export default new Api();